import Skeleton from "react-loading-skeleton";
import { ClientProjectSelect } from "../../../components/forms/selects";
import { useClientContext } from "../../../hooks/ClientContext";
import { useLazyFetchMessagesByThreadIdQuery } from "../../../services/messages";
import { useClientMessaging } from "../hooks/ClientMessagingContext";
import useThread from "../hooks/useThread";
import MessageList from "./MessageList";

const ThreadMessagesListWrapper = () => {

    const { id: masterAccountId } = useClientContext();
    const { threadId } = useClientMessaging();
    const { data, isFetching, patchThread } = useThread(threadId);
    const { projectId } = data || {};

    return <>
        <span className="d-flex px-3 pb-3">
            {isFetching
                ? <Skeleton />
                : <ClientProjectSelect
                    label="Assign to Project"
                    className="flex-fill"
                    masterAccountId={masterAccountId}
                    value={projectId}
                    setValue={(selection) => patchThread("projectId", selection?.value || null)}
                    isClearable
                />}
        </span>
        <MessageList
            fetchHook={useLazyFetchMessagesByThreadIdQuery}
            queryParams={{ masterAccountId, threadId }}
        />

    </>;
};

export default ThreadMessagesListWrapper;