import { useEffect, useState } from "react";
import { useLazySearchApplicationsQuery, useSearchApplicationsQuery } from "../../../services/headofficeadmin";
import { useFilterContext } from "../../../hooks/FilterContext";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";

const useApplications = (initSort = "createdDate+DESC", initLimit=50) => {

    const [sort, setSort] = useState(initSort);
    const [limit, setLimit] = useState(initLimit);
    const [sortProperty, direction] = sort.split("+");

    const { filter, isReady } = useFilterContext();
    const [searchTrigger, { data, isLoading, isFetching, isError, error, isUninitialized }] = useLazySearchApplicationsQuery();
    const { pagination, results } = data || { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [] };
    const { page, totalCount, totalPages } = pagination;
    const isNextPageLoading = isFetching === true || isLoading === true;
    const hasMore = totalCount > 0 && page < totalPages;

    const [isClearing, setIsClearing] = useState(false);

    const clear = () => {
         // safety check to make sure we don't reload when already loading!
         if (isNextPageLoading || !isReady) {
            return;
        }
        
        setIsClearing(true);
        searchTrigger({ page: 1, limit, sort, ...filter }).finally(() => setIsClearing(false));
    };

    const isRowLoaded = (index) => !hasMore || index < results.length;


    const loadMore = () => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        searchTrigger({ page: page + 1, limit, sort, ...filter });
    };

    const onClickSort = (property) => {
        // we want the sort to initially be descending ??
        let newDirection = "";

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    };

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        clear();
    };

    useEffect(clear, [filter, sort, isReady]);

    const sortableHeaderRenderer = ({ label, property, variant, key }) => {
        return <StandardTableSortableHeader
            key={key}
            onClick={() => onClickSort(property)}
            active={property === sortProperty}
            direction={direction}
        >
            {label}
        </StandardTableSortableHeader>;
    };

    const columns = [
        {
            label: "Status",
            property: 'status',
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Status Date",
            property: 'applicationStatusDate',
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Comment",
            property: "comment",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Designation",
            property: "designationName",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Provider",
            property: "providerName",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Amount",
            property: "amount",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Description",
            property: "description",
            headerRenderer: sortableHeaderRenderer
        },
        {
            label: "Reference",
            property: "createdDate",
            headerRenderer: sortableHeaderRenderer
        }
    ];

    return {
        results,
        sort,
        totalCount,
        columns,
        isClearing,
        isError,
        error,
        isNextPageLoading,
        clear,
        isRowLoaded,
        loadMore,
        sortableHeaderRenderer,
        reload,
    };
};

export default useApplications;