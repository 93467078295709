import { useFetchStandardPortfoliosQuery } from "../../../services/assets";
import FormSelect from "../FormSelect";

const StandardPortfolioSelect = ({ options, ...rest }) => {
    const { data, isFetching } = useFetchStandardPortfoliosQuery({ fetchType: "list" });

    return <FormSelect
        options={data}
        isLoadingOptions={isFetching}
        {...rest}
    />
}

export default StandardPortfolioSelect;