import React from "react";
import Button from "./buttons";
import ProfileIcon from "./ProfileIcon";
import { Modal } from "react-bootstrap";
import { useTheme } from "styled-components";
import { confirmAlert } from "react-confirm-alert";
import { useActiveUsers } from "../hooks/ActiveUsersContext";
import { ThemedIcon } from "./utilities";

const ProfileDisplay = ({ userId, isClickable = true, isUnderlined = false, reverseIcon = false }) => {
    const theme = useTheme();
    const { data: { results: users } } = useActiveUsers();
    const user = users && users.length > 0 ? users.find(el => el.id == userId) : null;
    
    const onProfileClicked = () => {
        if (!isClickable) {
            return;
        }

        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: (props) => {
                return <ProfileDisplayModal 
                    user={user} 
                    theme={theme}
                    {...props} 
                />
            }
        })
    };

    if (!user) return null;

    const { username, displayName, isOnline } = user;
    return (
        <div className="d-flex align-items-center">
            <div className={`d-flex ${reverseIcon ? 'order-2' : 'order-1'} align-items-center justify-center`}>
                <ProfileIcon user={user} isOnline={isOnline} showOnlineStatusIndicator={false} width={16} height={16} />
            </div>
            <p className={`mx-2 my-0 lh-1 ${reverseIcon ? 'order-1' : 'order-2'} ${isUnderlined ? 'text-decoration-underline' : ''} ${isClickable ? 'has-pointer' : ''}`} onClick={onProfileClicked}>
                <span>{displayName ?? username}</span>
            </p>
        </div>
    );
};

export const ProfileDisplayModal = ({ user, theme = {}, onClose, ...props }) => {
    
    if (!user) return null;

    const { emailAddress, isOnline, location, type, username, displayName } = user;
    return (
        <Modal centered backdrop="static" show={true}>
            <Modal.Body className="p-4">
                <div className="d-flex">
                    <div className="d-flex flex-shrink-0 p-2 justify-content-center">
                        <ProfileIcon user={user} isOnline={isOnline} showOnlineStatusIndicator={false} width={64} height={64} />
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <p className="mb-2 fs-5">
                            <span>{displayName ?? username}</span>
                        </p>
                        <p className="mb-3">{type}</p>
                        <div className="d-flex flex-wrap fs-smaller">
                            <div className="me-2">
                                <ThemedIcon icon="fa-circle" theme={theme} variant={isOnline ? 'success' : 'muted'} className="me-1" />
                                {isOnline && (<span>Online now</span>)}
                                {!isOnline && (<span>Offline</span>)}
                            </div>
                            <div className="me-2">
                                <ThemedIcon icon="fa-location-dot" className="me-1" />
                                <span>{location}</span>
                            </div>
                            <div>
                                <ThemedIcon icon="fa-at" className="me-1" />
                                <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" theme={theme} onClick={onClose}>Message</Button>
                <Button variant="light" theme={theme} onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProfileDisplay;