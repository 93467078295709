import React from "react";
import TransactionFeeModal from "../../administrationfees/components/TransactionFeeModal";
import useCreateFeeTransaction from "../hooks/useCreateFeeTransaction";
import { useAdministrationFeesProvider } from "../../../hooks/AdministrationFeesContext";


const CreateTransactionFeeModalWrapper = ({ group, show, handleClose }) => {

    const {currentSearchType} = useAdministrationFeesProvider();

    const {
        defaultAllocations,
        isLoadingDefaultAllocations,
        isDefaultAllocationsError,
        defaultAllocationsError,
        feeTransactionDefaultValues,
        feeTransactionAllocationDefaultValues,
        transactionTypes,
        isLoadingTransactionTypes,
        createFeeTransaction,
        retryFetchDefaultAllocations
    } = useCreateFeeTransaction(group, currentSearchType);

    return <TransactionFeeModal
        group={group}
        show={show}
        handleClose={handleClose}
        onSubmitFn={createFeeTransaction}
        defaultAllocationsError={defaultAllocationsError}
        isDefaultAllocationsError={isDefaultAllocationsError}
        feeTransactionDefaultValues={feeTransactionDefaultValues}
        feeTransactionAllocationDefaultValues={feeTransactionAllocationDefaultValues}
        transactionTypes={transactionTypes}
        isLoadingTransactionTypes={isLoadingTransactionTypes}
        isLoadingDefaultAllocations={isLoadingDefaultAllocations}
        retryFetchDefaultAllocations={retryFetchDefaultAllocations}
    />;
};

export default CreateTransactionFeeModalWrapper;