import { useMemo } from "react";
import { useFetchFeeTransactionTypesQuery, useGetFeeTransactionByIdQuery, useUpdateFeeTransactionMutation } from "../../../services/headofficeadmin";
import { useFilterContext } from "../../../hooks/FilterContext";

const useEditFeeTransaction = (feeTransactionId, currentSearchType) => {
    const { filter } = useFilterContext();
    const { data, isLoading, isError, error, refetch } = useGetFeeTransactionByIdQuery({ feeTransactionId });
    const { data: transactionTypes, isLoading: isLoadingTransactionTypes } = useFetchFeeTransactionTypesQuery();
    const [updateTrigger] = useUpdateFeeTransactionMutation();

    const feeTransactionDefaultValues = useMemo(() => data
        ? ({
            ...data,
            feeTransactionType: transactionTypes.find(x => x.typeId === data?.feeTransactionTypeId)
        })
        : ({
            groupId: null,
            feeDate: new Date(),
            feeTransactionTypeId: null,
            feeTransactionType: {},
            totalFeeAmount: 0,
            totalVatAmount: 0,
            feeReference: null,
            comment: null,
            allocations: []
        }), [data]);

    //console.log(feeTransactionDefaultValues);
    //console.log('dataFromUse',data);

    const feeTransactionAllocationDefaultValues = useMemo(() => ({
        personType: null,
        attributeToId: null,
        allocationPercentage: 0.0
    }), []);

    const retryGetFeeTransaction = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    };

    const updateFeeTransaction = (request) => {
        return new Promise((resolve, reject) => {
            return updateTrigger({ feeTransactionId, currentSearchType, request, filter, previousValues: data })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return {
        feeTransactionDefaultValues,
        feeTransactionAllocationDefaultValues,
        isLoading,
        isError,
        error,
        transactionTypes,
        isLoadingTransactionTypes,
        retryGetFeeTransaction,
        updateFeeTransaction
    };
};

export default useEditFeeTransaction;