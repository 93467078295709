import { NumericFormat } from "react-number-format";
import { ThemedSpan } from "../../../components/utilities";

const FeeTotalsHeader = ({ feeTotals }) => {
    
    return <div className="row mb-4">
        <ThemedSpan className="col-auto me-3">
            Total Fees: <NumericFormat
                value={feeTotals.totalFees}
                thousandSeparator={true}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                prefix={"£"}
                style={{ paddingRight: "14px" }}
            />
        </ThemedSpan>
        <ThemedSpan className="col-auto mx-3">
            Total VAT: <NumericFormat
                value={feeTotals.totalVat}
                thousandSeparator={true}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                prefix={"£"}
            />
        </ThemedSpan>
        {/*<ThemedSpan className="col-auto mx-3">
            Outstanding Debt: <NumericFormat
                value={feeTotals.outstandingDebt}
                thousandSeparator={true}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                prefix={"£"}
            />
        </ThemedSpan>*/}
    </div>;


};

export default FeeTotalsHeader;