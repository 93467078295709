import { DateDisplay } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";

const UserCalendarMonthEvent = ({ event, ...props }) => {

    const {
        id,
        start,
        end,
        title,
        isAllDay,
        masterAccountId,
        masterAccountName
    } = event || {};

    return <div className="d-flex justify-content-between">
        {masterAccountId && <ThemedSpan className="text-truncate" variant="light">{masterAccountName}</ThemedSpan>}
        {!masterAccountId && <ThemedSpan className="text-truncate" variant="light">{title}</ThemedSpan>}
        {!isAllDay && <ThemedSpan className="ms-1" variant="light">
            <DateDisplay format="h:mma">{start}</DateDisplay><span className="d-none d-xxl-inline">-<DateDisplay format="h:mma">{end}</DateDisplay></span>
        </ThemedSpan>}
        {isAllDay && <ThemedSpan variant="light">All Day</ThemedSpan>}
    </div>;
};

export default UserCalendarMonthEvent;