import _ from "lodash";
import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import DateDisplay from "../../DateDisplay";
import FormGroup from "../FormGroup";
import FormInputGroup from "../FormInputGroup";
import FormLabel from "../FormLabel";
import InputErrorMessage from "../InputErrorMessage";
import Select, { components } from "react-select";
import { CalculateClassName, CalculateSelectStyles } from "../FormSelect";
import { useClientProjectContext } from "../../../hooks/ClientProjectContext";

const ProjectDetailsRow = styled.div`
    font-size: 0.875em;
    color: ${(props) => props.theme.muted};
`;

export const ClientProjectSelectOption = (props) => {
    const { data: { data } } = props;

    // return just the label when we don't have data applied
    if (!data) {
        return (
            <components.Option {...props}>
                <span>{props.label}</span>
            </components.Option>
        )
    }

    const { description, statusDescription, targetDate } = data;
    return (
        <components.Option {...props}>
            <span>{description}</span>
            <ProjectDetailsRow className="d-flex justify-content-between">
                <div><span>Status:</span> {statusDescription}</div>
                <div><span>Target:</span> <DateDisplay>{targetDate}</DateDisplay></div>
            </ProjectDetailsRow>
        </components.Option>
    )
};

export const ClientProjectSelectSingleValue = (props) => {
    const { data: { data, label } } = props;
    if (!data) {
        return (
            <components.SingleValue {...props}><span>{label}</span></components.SingleValue>
        )
    }

    const { description } = data;
    return (
        <components.SingleValue {...props}><span>{description}</span></components.SingleValue>
    )
};

export const ClientProjectSelectComponents = {
    Option: ClientProjectSelectOption,
    SingleValue: ClientProjectSelectSingleValue
};

const ClientProjectSelect = React.forwardRef(({ 
    className = "",
    components={ ...ClientProjectSelectComponents },
    errorMessage = null,
    horizontal = false,
    id,
    isMulti = false,
    label,
    setValue = () => console.warn('set value method has not been assigned.'),
    value,
    ...rest 
}, ref) => {
    const defaultId = useId();
    const componentId = id || defaultId;
    const defaultRef = useRef();
    const componentRef = ref || defaultRef;
    const [error, setError] = useState(null);
    const { data, hasMore, isFetching, isLoading, nextPage } = useClientProjectContext();

    // NOTE: use the standard FormSelect calculation method instead of copy & paste the code...
    const currentClassName = useMemo(() => CalculateClassName(className, false, error, 'flex-fill'), [ className, error ]);
    const currentStyles = useMemo(() => CalculateSelectStyles(error), [ error ]);

    const getDisplayValue = (selectedValue, data, isMulti) => {
        if (!value || !data || !Array.isArray(data)) {
            return null;
        }

        if (isMulti === true) {
            let selectedValues = Array.isArray(selectedValue) ? selectedValue : [selectedValue];
            return data.filter(({ value }) => selectedValues.includes(value));
        }

        return data.find(({ value }) => value === selectedValue) ?? null;
    }

    const handleOnMenuScrollToBottom = (_) => {
        if (hasMore === false) {
            return;
        }
        nextPage();
    };

    useEffect(() => {
        if (errorMessage) {
            setError(_ => errorMessage);
        }
        else {
            setError(_ => null);
        }
    }, [errorMessage]);

    const displayValue = useMemo(() => getDisplayValue(value, data, isMulti), [ value, data, isMulti ]);
    return <FormGroup className={className} horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormInputGroup className={"has-error"} horizontal={horizontal} hasLabel={label ? true : false}>
            <Select
                id={componentId}
                ref={componentRef}
                isMulti={isMulti}
                components={components}
                styles={currentStyles}
                className={currentClassName}
                value={displayValue}
                options={data}
                isLoading={isLoading || isFetching}
                onChange={setValue}
                onMenuScrollToBottom={handleOnMenuScrollToBottom}
                {...rest}
            />
            <InputErrorMessage error={error} allowRetry={false} />
        </FormInputGroup>
    </FormGroup>
});

export default ClientProjectSelect;