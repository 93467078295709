import React from "react";
import AdviceGridRowColumn from "./AdviceGridRowColumn";
import useOpenMasterAccount from "../../../hooks/useOpenMasterAccount";
import useUpdateBusinessSheet from "../hooks/useUpdateBusinessSheet";
import useOpenApplicationSearch from "../hooks/useOpenApplicationSearch";
import { AdviceGridRowActionColumn } from "./AdviceGridRowActionColumn";
import { ApplicationType } from "../types/ApplicationType";
import { DateDisplay, ProfileDisplay } from "../../../components";
import { FormSelect } from "../../../components/forms";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";
import { useAdviceGridData } from "../hooks/AdviceGridDataContext";
import { BusinessSheetStatuses } from "../types/BusinessSheetStatusType";

export type AdviceGridRowProps = {
    data: [ApplicationType?],
    index: number,
    style: {
        height: any,
        width: any
    }
};

export const AdviceGridRow = ({ data, index, style } : Readonly<AdviceGridRowProps>) => {
    const selectRef = React.useRef(null);
    const { height } = style;
    const { isLoading, statuses } = useAdviceGridData();
    const { buildUrl, open: openApplications } = useOpenApplicationSearch();
    const { updateStatus, uploadEvidence } = useUpdateBusinessSheet();
    const openMasterAccount = useOpenMasterAccount();
    
    // do not return a data row when the data has not yet been collected from the backend
    if (index >= data.length) {
        return <TableLoadingRow style={{ height }} />
    }

    // deconstruct the data object
    const {
        businessSheetId,
        qualifiedReference,
        masterAccountId,
        masterAccountName,
        masterAccountReference,
        status,
        statusDate,
        adviserId,
        description
    } = data[index] as ApplicationType;

    const handleOpenApplication = (e: React.MouseEvent<HTMLAnchorElement> | null, reference: string, isRedirect: boolean) : void => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        // TODO: use the "isRedirect" so we can open a summary modal in the future.. (it'll be a feature some time)
        openApplications(reference);
    };

    const handleOnStatusChanged = (status: number) : void => {
        // check we are not just setting the same value here..
        if (status === data[index]?.status) {
            return;
        }

        // check if we are sending the signed data back to admin
        // here, it could just be being changed to "Adviser Chasing" - so no need to upload evidence, etc..
        if (status !== BusinessSheetStatuses.Administration) {
            updateStatus(businessSheetId, status).then(r => {
                const originalStatus = statuses.find(el => el?.value === data[index]?.status);
                if (!r && selectRef?.current && originalStatus) {
                    // @ts-ignore
                    selectRef.current.setValue(originalStatus);
                }
            });
            return;
        }

        // this creates a confirmation dialog that will allow the user to upload their evidence or
        // choose an existing uploaded document as evidence and then confirm. The toast and waiting to update
        // the status is then handled within this generic method from the hook.
        uploadEvidence(masterAccountId!, businessSheetId, status).then(r => {
            const originalStatus = statuses.find(el => el?.value === data[index]?.status);
            if (!r && selectRef?.current && originalStatus) {
                // @ts-ignore
                selectRef.current.setValue(originalStatus);
            }
        });
    };

    const handleOpenMasterAccount = (e: any) : void => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        openMasterAccount(masterAccountId);
    };

    return (
        <tr>
            <AdviceGridRowColumn width={7.5} style={{ height }}>
                <a href={buildUrl(masterAccountReference ?? "")} onClick={(e) => handleOpenApplication(e, masterAccountReference ?? "", true)}>
                    <span>{qualifiedReference}</span>
                </a>
            </AdviceGridRowColumn>
            <AdviceGridRowColumn width={17.5}>
                <a href={`/client/${masterAccountId}`} onClick={handleOpenMasterAccount}>
                    <span>{masterAccountName}</span>
                </a>
            </AdviceGridRowColumn>
            <AdviceGridRowColumn width={12.5}>
                <FormSelect
                    ref={selectRef}
                    defaultValue={status}
                    isLoadingOptions={isLoading}
                    options={statuses}
                    onChange={({ value }) => handleOnStatusChanged(value as number)}
                    className="form-control-sm m-0 p-0"
                    menuPortalTarget={document.body}
                />
            </AdviceGridRowColumn>
            <AdviceGridRowColumn width={10}>
                <DateDisplay fromNow={false}>
                    {statusDate}
                </DateDisplay>
            </AdviceGridRowColumn>
            <AdviceGridRowColumn width={27.5}>
                {description}
            </AdviceGridRowColumn>
            <AdviceGridRowColumn width={12.5}>
                {adviserId ? (<ProfileDisplay userId={adviserId} />) : (<span>-</span>)}
            </AdviceGridRowColumn>
            <AdviceGridRowActionColumn data={data[index]} />
        </tr>
    );
};

export default AdviceGridRow;