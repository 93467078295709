import { useDeleteFeeTransactionMutation, useFetchTransactionAllocationsByGroupingQuery, useSearchTransactionAllocationsByGroupingQuery } from "../../../services/headofficeadmin";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useAdministrationFeesProvider } from "../../../hooks/AdministrationFeesContext";

const useTransactionFeeAllocations = (groupId) => {

    const {currentSearchType} = useAdministrationFeesProvider();

    const { filter } = useFilterContext();
    //const { data, isError, isFetching, isLoading, error, refetch } = useSearchTransactionAllocationsByGroupingQuery({ groupId, filters: {
    const { data, isError, isFetching, isLoading, error, refetch } = useFetchTransactionAllocationsByGroupingQuery({ groupId, filters: {
        ...filter,
        adviserIds: filter?.advisers.map(x => x.value),
        introducerIds: filter?.introducers.map(x => x.value),
        masterAccountIds: filter?.masterAccounts.map(x => x.value),
        providerIds: filter?.providers.map(x => x.value),
    } });
    //console.log(data);
    const [deleteTrigger] = useDeleteFeeTransactionMutation();

    const deleteAllocationsForFee = (feeTransactionId) => {
        return new Promise((resolve, reject) => {
            return deleteTrigger({ feeTransactionId, currentSearchType, previousValues: data, groupId, filter })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    }

    const retryFetchTransactionFeeAllocations = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    }

    //console.log('useTransactionFeeAllocationsFilters', filter);

    return {
        data: data || [],
        isError,
        isFetching,
        isLoading,
        error,
        deleteAllocationsForFee,
        retryFetchTransactionFeeAllocations
    };

};

export default useTransactionFeeAllocations;