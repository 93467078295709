import { useNavigate } from "react-router-dom";
import { FixedCell, StandardTableSortableHeader } from "../../../components/tables/StandardTable";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";
import { useAuth } from "react-oidc-context";

export const FundsSummaryHeader = ({ getSortProps, group }) => (
    <thead>
        <tr>
            <StandardTableSortableHeader {...getSortProps("monitoring")}>
                Monitoring
            </StandardTableSortableHeader>
            {!group && <StandardTableSortableHeader {...getSortProps("citicode")}>
                Citicode
            </StandardTableSortableHeader>}
            <StandardTableSortableHeader {...getSortProps("investment")}>
                Investment
            </StandardTableSortableHeader>
            <StandardTableSortableHeader {...getSortProps("totalValue")} className="text-end">
                Value
            </StandardTableSortableHeader>
        </tr>
    </thead>
);

const citicodeWidth = 20;

const FundsSummaryRow = ({ data, index, style, group }) => {
    const { height } = style;

    // For navigation to Asset Group pages, role-restricted
    const navigate = useNavigate();

    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;
    const canViewAssetGroups = roles.includes("ic_read");

    // Need to work out how much width to remove from other columns to make up for the lack of citicode
    const citicodeWidthPadding = group ? citicodeWidth / 3 : 0

    if (index >= data.length)
        return <TableLoadingRow style={{ height }} />;

    const {
        assetGroupId,
        monitoringText,
        citicode,
        investment,
        totalValue
    } = data[index];

    return <tr style={{ height }}>
        <FixedCell
            className="text-truncate align-middle"
            key={`row-col-${index}-monitoring`}
            width={25 + citicodeWidthPadding}
        >
            {monitoringText}
        </FixedCell>
        {!group && <FixedCell
            className="text-truncate align-middle"
            key={`row-col-${index}-citicode`}
            width={citicodeWidth}
        >
            {citicode}
        </FixedCell>}
        <FixedCell
            className="text-truncate align-middle"
            key={`row-col-${index}-investment`}
            width={40 + citicodeWidthPadding}
        >
            {(canViewAssetGroups && assetGroupId != null)
                ? <button className="anchor" onClick={() => navigate(`/investment-committee/funds/${assetGroupId}`)}>{investment}</button>
                : investment}
        </FixedCell>
        <FixedCell
            className="text-truncate align-middle text-end"
            key={`row-col-${index}-value`}
            width={15 + citicodeWidthPadding}
        >
            {totalValue.toLocaleString("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 })}
        </FixedCell>
    </tr>
}

export default FundsSummaryRow;