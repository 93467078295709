import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { CurrencyInput, FormSelect } from "../../../components/forms";
import { AssetGroupSelect, ClientProfileSelect, GenericAdviserSelect, GenericUserDivisionSelect, ProductSelect, ProductWrappersSelect, ServiceAgreementsSelect, StandardPortfolioSelect } from "../../../components/forms/selects";
import AssetSearchPagedSelect from "../../../components/forms/selects/AssetSearchPagedSelect";
import ProviderPagedSelect from "../../../components/forms/selects/ProviderPagedSelect";
import { useFilterContext } from "../../../hooks/FilterContext";

// Custom component to remove border-radius so the two inputs can be joined together
const MaxCurrencyInput = styled(CurrencyInput)`
    .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
        border-radius: 0;
    }
`;

const AssetsUnderAdviceFiltersModal = ({ handleClose, show }) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();

    const { control, watch, handleSubmit, reset, setValue, formState: { errors } } = useForm({ defaultValues: defaultFilters });

    const watchDivisionId = watch("divisionId");
    const watchMinPortfolio = watch("minPortfolioValue");
    const watchMaxPortfolio = watch("maxPortfolioValue");
    const watchMinHolding = watch("minHoldingValue");
    const watchMaxHolding = watch("maxHoldingValue");

    const onApplyFilters = (form) => {
        setFilter(form);
        handleClose();
    }

    const onClearFilters = () => {
        reset(defaultFilters);
    }

    const onClose = () => {
        handleClose();
        reset(filter);
    }

    useEffect(() => {
        reset(filter);
    }, [filter]);

    return <Modal size="lg" centered backdrop="static" show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Asset Filters</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onApplyFilters)}>
            <Modal.Body>
                <div className="row gy-2">
                    <div className="col-6">
                        <Controller
                            name="divisionId"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <GenericUserDivisionSelect
                                    label="Division"
                                    defaultValue={value}
                                    onChange={(selection) => setValue("divisionId", selection.value)}
                                    isClearable
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="adviserIds"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <GenericAdviserSelect
                                    label="Adviser"
                                    divisionId={watchDivisionId}
                                    defaultValue={value}
                                    onChange={(selections) => setValue("adviserIds", selections.map(x => x.value))}
                                    isMulti
                                    isClearable
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-3 pe-0">
                        <Controller
                            name="minPortfolioValue"
                            control={control}
                            rules={{
                                validate: value =>
                                    watchMaxPortfolio == null || (value ?? 0) <= watchMaxPortfolio
                                    || " "
                            }}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <CurrencyInput
                                    label="Min. Portfolio"
                                    value={value ?? ""}
                                    onChange={(_, values) => setValue("minPortfolioValue", values.floatValue ?? "")}
                                    disableAnimations
                                    decimalScale={0}
                                    fixedDecimalScale
                                    errorMessage={errors?.minPortfolioValue?.message}
                                    errorAllowRetry={false}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-3 ps-0">
                        <Controller
                            name="maxPortfolioValue"
                            control={control}
                            rules={{
                                validate: value =>
                                    value == null || value >= (watchMinPortfolio ?? 0)
                                    || "Maximum must be greater than Minimum"
                            }}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <MaxCurrencyInput
                                    label="Max. Portfolio"
                                    value={value ?? ""}
                                    onChange={(_, values) => setValue("maxPortfolioValue", values.floatValue ?? "")}
                                    disableAnimations
                                    decimalScale={0}
                                    fixedDecimalScale
                                    errorMessage={errors?.maxPortfolioValue?.message}
                                    errorAllowRetry={false}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-3 pe-0">
                        <Controller
                            name="minHoldingValue"
                            control={control}
                            rules={{
                                validate: value =>
                                    watchMaxHolding == null || (value ?? 0) <= watchMaxHolding
                                    // Returning empty string to only show the message one the max input
                                    || " "
                            }}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <CurrencyInput
                                    label="Min. Value"
                                    value={value ?? ""}
                                    onChange={(_, values) => setValue("minHoldingValue", values.floatValue ?? "")}
                                    disableAnimations
                                    decimalScale={0}
                                    fixedDecimalScale
                                    errorMessage={errors?.minHoldingValue?.message}
                                    errorAllowRetry={false}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-3 ps-0">
                        <Controller
                            name="maxHoldingValue"
                            control={control}
                            rules={{
                                validate: value =>
                                    value == null || value >= (watchMinHolding ?? 0)
                                    || "Maximum must be greater than Minimum"
                            }}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <MaxCurrencyInput
                                    label="Max. Value"
                                    value={value ?? ""}
                                    onChange={(_, values) => setValue("maxHoldingValue", values.floatValue ?? "")}
                                    disableAnimations
                                    decimalScale={0}
                                    fixedDecimalScale
                                    errorMessage={errors?.maxHoldingValue?.message}
                                    errorAllowRetry={false}
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="serviceLevelAgreementIds"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ServiceAgreementsSelect
                                    label="Service Level Agreement"
                                    divisionId={watchDivisionId}
                                    defaultValue={value}
                                    onChange={(selection) => setValue("serviceLevelAgreementIds", selection.map(x => x.value))}
                                    isMulti
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="targetProfileIds"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ClientProfileSelect
                                    label="Target Profile"
                                    value={value}
                                    setValue={(selection) => setValue("targetProfileIds", selection.map(x => x.value))}
                                    isMulti
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="standardPortfolioIds"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <StandardPortfolioSelect
                                    label="Portfolio"
                                    defaultValue={value}
                                    onChange={(selection) => setValue("standardPortfolioIds", selection.map(x => x.value))}
                                    isMulti
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="wrapperIds"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ProductWrappersSelect
                                    label="Wrapper"
                                    defaultValue={value}
                                    onChange={(selection) => setValue("wrapperIds", selection.map(x => x.value))}
                                    isMulti
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="productProviders"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ProviderPagedSelect
                                    label="Product Provider"
                                    value={value}
                                    setValue={(selection) => {
                                        setValue("productProviders", selection);
                                        setValue("productProviderIds", selection.map(x => x.value))
                                    }}
                                    isMulti
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="productIds"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <ProductSelect
                                    label="Product"
                                    value={value}
                                    setValue={(selection) => setValue("productIds", selection.map(x => x.value))}
                                    isMulti
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="assetGroups"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <AssetGroupSelect
                                    label="Asset Group"
                                    valueObject={value}
                                    setValue={(selection) => {
                                        setValue("assetGroups", selection);
                                        setValue("assetGroupIds", selection.map(x => x.value));
                                    }}
                                    isMulti
                                    isClearable
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="assets"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <AssetSearchPagedSelect
                                    label="Asset"
                                    valueObject={value}
                                    setValue={(selection) => {
                                        setValue("assets", selection);
                                        setValue("assetIds", selection.map(x => x.value));
                                    }}
                                    isMulti
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="monitoring"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <FormSelect 
                                    label="Monitoring"
                                    defaultValue={value}
                                    onChange={(selection) => setValue("monitoring", selection.map(x => x.value))}
                                    options={[
                                        { label: "Actively Monitored Core", value: 0 },
                                        { label: "Actively Monitored", value: 1 },
                                        { label: "Passively Monitored", value: 2 }
                                    ]}
                                    isMulti
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                    <div className="col-6">
                        <Controller
                            name="assetClass"
                            control={control}
                            render={({ field: { ref, value, onChange, ...rest } }) => (
                                <FormSelect 
                                    label="Asset Class"
                                    defaultValue={value}
                                    onChange={(selection) => setValue("assetClass", selection.map(x => x.value))}
                                    options={[
                                        { label: "Multi Asset", value: 0 },
                                        { label: "Equity", value: 1 },
                                        { label: "Equity Income", value: 2 },
                                        { label: "Fixed Interest", value: 3 },
                                        { label: "Property", value: 4 },
                                        { label: "Alternatives", value: 5 },
                                        { label: "Targeted Absolute Return", value: 6 },
                                        { label: "Structured Product", value: 7 },
                                        { label: "Cash", value: 8 }
                                    ]}
                                    isMulti
                                    disableAnimations
                                    {...rest}
                                />
                            )}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClearFilters}>Clear Filters</Button>
                <Button variant="success" type='submit'>Apply Filters</Button>
                <Button variant="light" onClick={onClose}>Close</Button>
            </Modal.Footer>
        </form>
    </Modal>
}

export default AssetsUnderAdviceFiltersModal;