import { io, Socket } from "socket.io-client";

export default class SocketClient {
    socket = null;

    connect() {
        if (!!window.chrome) {
            this.socket = io('/');
        }
        else {
            this.socket = io('/', {
                transports: ['websocket'],
                secure: true
            });
        }

        return new Promise((resolve, reject) => {    
            this.socket.on('connect', () => resolve());
            this.socket.on('connect_error', (error) => reject(error));
        });
    }

    disconnect() {
        if (!this.socket) {
            return;
        }

        return new Promise((resolve) => {
            this.socket.disconnect(() => {
                this.socket = null;
                resolve();
            });
        });
    }

    getId() {
        return new Promise((resolve, reject) => {
            if (!this.socket) {
                return reject("No socket connection.");
            }

            resolve(this.socket.id);
        });
    }

    emit(event, data) {
        //console.log("EMIT", event, data);
        return new Promise((resolve, reject) => {
            if (!this.socket) {
                console.log("failed");
                return reject("No socket connection.");
            }

            return this.socket.emit(event, data, (response) => {
                if (response.error) {
                    console.error(response.error);
                    return reject(response.error);
                }
                return resolve();
            });
        })
    }

    on(event, callback) {
        return new Promise((resolve, reject) => {
            if (!this.socket) {
                return reject("No socket connection.");
            }

            this.socket.on(event, callback);
            resolve();
        });
    }

    off(event, callback) {
        return new Promise((resolve, reject) => {
            if (!this.socket) {
                return reject("No socket connection.");
            }

            this.socket.off(event, callback);
            resolve();
        });
    }
};