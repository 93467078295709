import React from "react";
import useOpenDocument from "../../../hooks/useOpenDocument";
import useOpenMasterAccount from "../../../hooks/useOpenMasterAccount";
import ReportManagementDetailTableRowColumn from "./ReportManagementDetailTableRowColumn";
import ReportManagementDetailTableRowActionColumn from "./ReportManagementDetailTableRowActionColumn";
import ReportEfficacyIconDisplayColumn from "../../../components/tables/ReportEfficacyIconDisplayColumn";
import ReportDateDisplayColumn, { ReportDeadlineDateDisplayColumn } from "../../../components/tables/ReportDateDisplayColumn";
import { DateDisplay, ProfileDisplay } from "../../../components";
import { ThemedSpan } from "../../../components/utilities";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";
import { identity } from "lodash";

const ReportManagementDetailTableRow = ({ data, index, style }) => {
    const { height } = style;
    const { openDocument, isFetching: isOpening } = useOpenDocument();
    const openMasterAccount = useOpenMasterAccount();

    // do not return a data row when the data has not yet been collected from the backend
    if (index >= data.length) {
        return <TableLoadingRow style={{ height }} />
    }

    const {
        adviserId,
        authorId,
        compliantDate,
        createdDate,
        deadlineDate,
        documentId,
        efficacy,
        masterAccountId,
        masterAccountName,
        projectId,
        reportName,
        sentDate,
        status,
        statusDate,
        statusDescription,
        typeDescription,
        isReviewReport
    } = data[index];

    const handleOpenDocument = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        if (isOpening)
            return;

        openDocument(documentId, reportName);
    };

    const handleOpenMasterAccount = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        let suffix = projectId ? `projects?projectId=${projectId}` : `projects`;

        if(isReviewReport){
            suffix = `reviews`;
        }
        
        openMasterAccount(masterAccountId, { suffix });
    };

    return (
        <tr style={{ height }}>
            {/* Display the Adviser Profile Details */}
            <ReportManagementDetailTableRowColumn width={12.5} style={{ height }}>
                {adviserId ? (<ProfileDisplay userId={adviserId} />) : (<span>-</span>)}
            </ReportManagementDetailTableRowColumn>
            {/* The Report Name & Type combined into two rows */}
            <ReportManagementDetailTableRowColumn width={15}>
                <p className="m-0 lh-1">
                    <a href="#" onClick={handleOpenDocument}>{reportName}</a>
                    <br />
                    <ThemedSpan variant="muted" className="fs-smallest">{typeDescription}</ThemedSpan>
                </p>
            </ReportManagementDetailTableRowColumn>
            {/* Client Details */}
            <ReportManagementDetailTableRowColumn width={15}>
                <a href={isReviewReport ? `client/${masterAccountId}/reviews` : `/client/${masterAccountId}/projects${projectId ? `?projectId=${projectId}` : ''}`} onClick={handleOpenMasterAccount}>
                    <span>{masterAccountName}</span>
                </a>
            </ReportManagementDetailTableRowColumn>
            {/* Created Date */}
            <ReportManagementDetailTableRowColumn width={7.5}>
                <DateDisplay>{createdDate}</DateDisplay>
            </ReportManagementDetailTableRowColumn>
            {/* Status */}
            <ReportManagementDetailTableRowColumn width={7.5}>
                <p className="m-0 lh-1">
                    {statusDescription}
                    <br />
                    <ThemedSpan variant="muted" className="fs-smallest"><DateDisplay format="DD/MM/YYYY HH:mm">{statusDate}</DateDisplay></ThemedSpan>
                </p>
            </ReportManagementDetailTableRowColumn>
            {/* Author Profile Data */}
            <ReportManagementDetailTableRowColumn width={12.5}>
                {authorId ? (<ProfileDisplay userId={authorId} />) : (<span>-</span>)}
            </ReportManagementDetailTableRowColumn>
            {/* Deadline Display */}
            <ReportDeadlineDateDisplayColumn width={7.5} deadlineDate={deadlineDate} status={status} />
            {/* Efficacy Icon */}
            <ReportEfficacyIconDisplayColumn width={5} efficacy={efficacy} />
            {/* Compliant Date */}
            <ReportDateDisplayColumn width={7.5} date={compliantDate} deadlineDate={deadlineDate} />
            {/* Sent Date */}
            <ReportDateDisplayColumn width={7.5} date={sentDate} deadlineDate={deadlineDate} />
            {/* Action Column */}
            <ReportManagementDetailTableRowActionColumn data={data[index]} width={2.5} />
        </tr>
    );
};

export default ReportManagementDetailTableRow;