import { useState } from "react";
import FormSelectPaged from "./FormSelectPaged";
import { useLazySearchHoAdminProvidersQuery } from "../../../services/headofficeadmin";

const ProvidersFormSelectPaged = ({ isMulti, label, onChange, value }) => {

    return <FormSelectPaged
        setValue={onChange}
        cacheOptions={true}
        label={label}
        value={value}
        isMulti={isMulti}
        query={useLazySearchHoAdminProvidersQuery}
    />;
};

export default ProvidersFormSelectPaged;