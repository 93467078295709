import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { FormCheck } from "../components/forms";
import { PageHeaderTop } from "../components/layout/PageHeader";
import { Spinner } from "../components/loaders";
import { Main, PageHeader, PageSubHeader, ThemedIcon, ThemedParagraph } from "../components/utilities";
import AssetsUnderAdviceFiltersModal from "../features/assetsunderadvicegrid/components/AssetsUnderAdviceFilterModal";
import ValuationBuildPicker from "../features/assetsunderadvicegrid/components/ValuationBuildPicker";
import { AssetsUnderAdviceProvider, AssetViewTypes, useAssetsUnderAdvice } from "../features/assetsunderadvicegrid/hooks/AssetsUnderAdviceContext";
import { AssetsUnderAdviceDefaultFilters, FilterProvider } from "../hooks/FilterContext";
import withProviders from "../hooks/withProviders";
import { AssetAnalysisRouteTabs } from "../routes/administration.analysis.assets.routes";

const AssetsUnderAdvicePageHeader = styled(PageHeader)`
    padding: 1rem 1rem 0 1rem;
`;

const PageTitle = styled(PageHeaderTop)`
    font-size: 16px;
`;

const PageHeaderBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const TotalWrapper = styled.div`
    display: flex;
    font-size: 16px;
`;

const AssetsUnderAdviceSubHeader = () => {
    const {
        viewType,
        group,
        flipGroup,
        clearFilter,
        setShowFilterModal,
        isFiltersApplied,
    } = useAssetsUnderAdvice();

    return <PageSubHeader className="d-flex justify-content-between">
        <span className="d-flex">
            {isFiltersApplied && (
                <React.Fragment>
                    <ThemedIcon className="my-auto" icon="fa-triangle-exclamation" size="2x" variant="warning" />
                    <ThemedParagraph className="ms-2 text-muted my-auto">
                        Warning - Filters are currently being applied to your results.
                        <br />
                        <button className="anchor" onClick={clearFilter}>Click here</button> to clear your filters.
                    </ThemedParagraph>
                </React.Fragment>
            )}
            <ValuationBuildPicker />
            {viewType === AssetViewTypes.FundsSummary && <FormCheck
                groupClassName="mt-auto mb-2 mx-2"
                className="ms-0"
                label="Group?"
                labelFirst
                vertical
                isChecked={group}
                onChange={flipGroup}
                disableAnimations
            />}
        </span>
        <Button className="mt-auto" onClick={() => setShowFilterModal(true)}>
            Asset Filters
        </Button>
    </PageSubHeader>
}

const AssetsUnderAdviceLayout = () => {
    const { setViewType, buildId, showFilterModal, setShowFilterModal, totalValue, isError, isClearing, exportExcel } = useAssetsUnderAdvice();

    return <>
        <AssetsUnderAdviceFiltersModal
            handleClose={() => setShowFilterModal(false)}
            show={showFilterModal}
        />
        <AssetsUnderAdvicePageHeader>
            <span className="d-flex justify-content-between">
                <PageTitle>
                    Assets Under Advice
                </PageTitle>

                {buildId != null && <Dropdown className="caret-off">
                    <Dropdown.Toggle as={Button} variant="success" disabled={isClearing || isError}>
                        Export <ThemedIcon className="ms-2" variant="light" icon="file-export" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => exportExcel(true)}>
                            <span className="ms-1">As .CSV (Unformatted)</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => exportExcel(false)}>
                            <span className="ms-1">As .XLS (2003 Excel)</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
            </span>
            <PageHeaderBottom>
                <AssetAnalysisRouteTabs onTabChanged={(tab) => setViewType(tab?.viewType)} />
                {buildId != null && <TotalWrapper>
                    Total Value:&nbsp;
                    {isClearing
                        ? <Spinner className="ms-1" />
                        : !isError && <span>
                            {totalValue?.toLocaleString('en-GB', { style: 'currency', currency: 'GBP', maximumFractionDigits: 0 })}
                        </span>}
                </TotalWrapper>}
            </PageHeaderBottom>
        </AssetsUnderAdvicePageHeader>
        <Main className="d-flex flex-column">
            <AssetsUnderAdviceSubHeader />
            <Outlet />
        </Main>
    </>
}

export default withProviders([FilterProvider, { filterStore: "assetsUnderAdvice", defaultFilters: AssetsUnderAdviceDefaultFilters }], AssetsUnderAdviceProvider)(AssetsUnderAdviceLayout);