import { useLazyFetchAssetGroupQuery, useLazySearchAssetGroupsQuery } from "../../../services/assets";
import FormSelectPaged from "../FormSelectPaged";

const AssetGroupSelect = ({
    label,
    valueObject,
    setValue,
    ...rest
}) => {

    return <FormSelectPaged
        label={label}
        valueObject={valueObject}
        setValue={setValue}
        query={useLazySearchAssetGroupsQuery}
        additional={{ fetchType: "list", page: 1, limit: 25 }}
        onOptionsLoaded={(_, results) => results}
        fetchQuery={useLazyFetchAssetGroupQuery}
        onStartFetch={(value) => ({ assetGroupId: value })}
        onFetched={(obj) => ({ value: obj.assetGroupId, label: obj.assetName })}
        {...rest}
    />
}

export default AssetGroupSelect;