import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Button } from "../../../components";
import { useCustodianTransactionMatching } from "../hooks/CustodianTransactionMatchingContext";
import toast from "react-hot-toast";
import { ConfirmModal } from "../../../components/modals";

const ResolveTypes = {
    Accept: 0,
    Partial: 1,
    Regular: 2,
    Exception: 3,
    Tag: 4,
    Sedol: 5,
    Phased: 6,
    Error: 7,
    TopUpCash: 8
};

const TransactionExceptionsResolveDropdown = ({ platformTransactionId, platformTransactionType }) => {
    const [resolution, setResolution] = useState(null);
    const { exception, selectedApplicationTransactionId, resolveException, setSelectedApplicationTransactionId, resolveResult : { isLoading } } = useCustodianTransactionMatching();

    console.log(isLoading);

    const menuId = React.useId();
    const toggleId = React.useId();

    const onConfirmResolution = (resolution) => {
        const request = {
            ...exception,
            applicationTransactionId: selectedApplicationTransactionId,
            platformTransactionGuid: exception.id,
            resolution
        };

        toast.promise(resolveException(request), {
            loading: "Resolving...",
            error: (err) => err?.title ?? err?.data ?? "Unable to resolve exception.",
            success: (data) => {
                setSelectedApplicationTransactionId(null);
                return "Exception resolved!";
            }
        });
    };

    return (
        <React.Fragment>
            <Dropdown id={menuId} className="caret-off">
                <Dropdown.Toggle id={toggleId} as={Button}>Resolve <FontAwesomeIcon className="text-white" icon="caret-down" /></Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setResolution(ResolveTypes.Partial)} disabled={selectedApplicationTransactionId === null}>
                        <FontAwesomeIcon className="text-success" icon="arrows-split-up-and-left" /> Partial
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setResolution(ResolveTypes.Accept)} disabled={selectedApplicationTransactionId === null}>
                        <FontAwesomeIcon className="text-success" icon="circle-check" /> Accept
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setResolution(ResolveTypes.TopUpCash)} disabled={selectedApplicationTransactionId !== null || platformTransactionType !== "SALE"}>
                        <FontAwesomeIcon className="text-success" icon="money-bill-wave" /> Top Up Cash
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setResolution(ResolveTypes.Regular)} disabled={selectedApplicationTransactionId !== null || platformTransactionType !== "PURCH"}>
                        <FontAwesomeIcon className="text-warning" icon="coins" /> Regular
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setResolution(ResolveTypes.Exception)} disabled={selectedApplicationTransactionId !== null}>
                        <FontAwesomeIcon className="text-danger" icon="triangle-exclamation" /> Exception
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setResolution(ResolveTypes.Error)} disabled={selectedApplicationTransactionId !== null}>
                        <FontAwesomeIcon className="text-danger" icon="circle-xmark" /> Error
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <ConfirmModal
                show={resolution !== null}
                handleClose={() => setResolution(null)}
                handleConfirm={() => onConfirmResolution(resolution)}
                title={`Resolve Match: ${Object.keys(ResolveTypes).find(x => ResolveTypes[x] === resolution)}`}
                message={`Confirm resolution of platform transaction '${platformTransactionId}'.`}
                isUpdating={isLoading}
            />
        </React.Fragment>
    );

};

export default TransactionExceptionsResolveDropdown;