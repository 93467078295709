import DateDisplay from "../../../components/DateDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useAuth } from "react-oidc-context";
import { ProfileDisplay } from "../../../components";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useApproveNewMoneyMutation, useCancelNewMoneyMutation, useCreateAndApproveNewMoneyMutation, useCreateNewMoneyMutation, useLazySearchNewMoneyQuery, useHoSaveNewMoneyMutation } from "../../../services/headofficeadmin";
import { NewMoneyEditCellComponent } from "../components";

export const NewMoneyStatuses = {
    Pending: 0,
    Approved: 1,
    Aborted: 2
};

const NewMoneyIconProps = {
    [NewMoneyStatuses.Pending]: { icon: "fa-hourglass-half", className: "text-secondary" },
    [NewMoneyStatuses.Approved]: { icon: "fa-check-circle", className: "text-success" },
    [NewMoneyStatuses.Aborted]: { icon: "fa-times-circle", className: "text-danger" }
};

// Helper functions for formatting cells
export const formatCurrency = (value) => value.toLocaleString("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 });
export const formatPercentage = (value) => (value / 100).toLocaleString("en-GB", { style: "percent", maximumFractionDigits: 2 });

// Small helper for converting the totals object into readable strings
// Converts the object into an array of key-value pairs, then builds a key: { number, string } object
const makeReadableTotals = (totalsObj) =>
    Object.entries(totalsObj).reduce((acc, [key, value]) => {
        acc[key] = {
            number: value,
            string: key.includes("EffectiveFeeRate")
                ? formatPercentage(value)
                : formatCurrency(value)
        };
        return acc;
    }, {});

export const useAdminNewMoney = (setTotals = null) => {
    // User role check
    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;
    const canWrite = roles.includes("ho_write");

    // Search handling inc. filtering, sorting, and pagination
    const { filter } = useFilterContext();
    const [searchTrigger, { data, isError, error, isUninitialized, isFetching }] = useLazySearchNewMoneyQuery();
    const { pagination, results } = data || { results: [], pagination: { totalCount: 100, page: 0, pageSize: 20, totalPages: null } };
    const { totalCount, page, pageSize: limit } = pagination;
    const [sort, setSort] = useState(null);
    const [sortProperty, direction] = useMemo(() => sort?.split("+") ?? [], [sort]);

    const [isClearing, setIsClearing] = useState(false);

    const clear = useCallback(() => new Promise((res, rej) => {
        setIsClearing(true)
        searchTrigger({ page: 1, limit, sort, filter })
            .unwrap()
            .then(res => {
                const { pagination, results, ...totals } = res ?? {};
                if (typeof setTotals === 'function' && totals != null && typeof totals === 'object')
                    setTotals(makeReadableTotals(totals))
            })
            .then(res, rej)
            .finally(() => setIsClearing(false));
    }), [filter, limit, searchTrigger, setTotals, sort]);

    const isRowLoaded = (index) => (index < results.length) || isClearing;

    const loadMore = () => {
        if (page === 0) // Run the clear function on first page load to do the totals formatting
            return clear();
        else
            return searchTrigger({ page: page + 1, limit, sort, filter });
    };

    const onClickSort = useCallback((property) => {
        let newDirection = "";

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            // Remove sorting if already descending
            if (direction === "DESC") {
                setSort(null);
                return;
            }

            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    }, [direction, sortProperty]);

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        clear();
    };

    useEffect(() => {
        if (isUninitialized) return;

        clear();
        // Disabled eslint warning because we only want to run this effect when the filter or sort changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, sort]);

    const sortableHeaderRenderer = useCallback(({ label, property, key, headerClassName }) => {
        return <StandardTableSortableHeader
            className={classNames(headerClassName, "sticky-header")}
            key={key}
            onClick={() => onClickSort(property)}
            active={property === sortProperty}
            direction={direction}>
            {label}
        </StandardTableSortableHeader>;
    }, [direction, onClickSort, sortProperty]);

    // New Money creation
    const [createTrigger, { isLoading: isCreatingPending }] = useCreateNewMoneyMutation();
    const createNewMoney = (newMoney) =>
        toast.promise(createTrigger({ newMoney }).unwrap(), {
            loading: "Creating new money...",
            success: "New money created and pending approval!",
            error: (error) => `Failed to create new money: ${error.data}`
        });

    // Want to hide new money with newly changed status when filters are set
    const hideApproved = useMemo(() => filter.statuses.length !== 0 && !filter.statuses.includes(1), [filter]);
    const hideAborted = useMemo(() => filter.statuses.length !== 0 && !filter.statuses.includes(2), [filter]);

    const [createAndApproveTrigger, { isLoading: isCreatingApproved }] = useCreateAndApproveNewMoneyMutation();
    const createApprovedNewMoney = (newMoney) =>
        toast.promise(createAndApproveTrigger({ newMoney, filterOut: hideApproved }).unwrap(), {
            loading: "Creating pre-approved new money...",
            success: "New money created and approved!",
            error: (error) => `Failed to create new money: ${error.data}`
        });

    const isCreating = isCreatingPending || isCreatingApproved;

    // New Money editing
    const [saveNewMoneyTrigger, { isLoading: isSaving }] = useHoSaveNewMoneyMutation();
    const saveNewMoney = (id, newMoney, overwriteComment = null) =>
        toast.promise(saveNewMoneyTrigger({ id, newMoney, overwriteComment }).unwrap(), {
            loading: "Saving changes...",
            success: "Changes saved!",
            error: (error) => `Failed to save changes: ${error.data}`
        });

    // New Money status changing (approval/not taken up)
    const [approveTrigger, { isLoading: isApproving }] = useApproveNewMoneyMutation();
    const approveNewMoney = (id) =>
        toast.promise(approveTrigger({ id, filterOut: hideApproved }).unwrap(), {
            loading: "Approving new money...",
            success: `New money approved!${hideApproved ? " You will need to change your filters to see it." : ""}`,
            error: (error) => `Failed to approve new money: ${error.data}`
        });

    const [cancelTrigger, { isLoading: isCancelling }] = useCancelNewMoneyMutation();
    const cancelNewMoney = (id) => {
        toast.promise(cancelTrigger({ id, filterOut: hideAborted }).unwrap(), {
            loading: "Aborting new money...",
            success: `New money aborted.${hideAborted ? " You will need to change your filters to see it." : ""}`,
            error: (error) => `Failed to abort new money: ${error.data}`
        });
    }

    const isLoading = useMemo(() => isCreating || isApproving || isCancelling || isFetching,
        [isCreating, isApproving, isCancelling, isFetching]);

    // Only allow sorting on master account name, investment amount, fees, adviser, and new money date
    const columns = useMemo(() => [
        {
            label: "Type",
            property: "isOutflow",
            cellFormatter: (value) => value ? "Outflow" : "New Money",
            cellClassName: "text-truncate",
            width: 7
        },
        {
            label: "Adviser",
            property: "adviserId",
            cellFormatter: (value) => <ProfileDisplay userId={value} />,
            cellClassName: "text-truncate",
            width: 11
        },
        {
            label: "Master Account",
            property: "masterAccountName",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: (name, { masterAccountId }) => masterAccountId ? <a href={`../../client/${masterAccountId}`}>{name}</a> : name,
            cellClassName: "text-truncate",
            width: 16
        },
        {
            label: "Business Sheet",
            property: "businessSheetDescription",
            cellClassName: "text-truncate",
            width: 14
        },
        {
            label: "Description",
            property: "description",
            cellClassName: "text-truncate",
            width: 14
        },
        {
            label: "Investment Amount",
            property: "investmentAmount",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: formatCurrency,
            headerClassName: "text-end",
            cellClassName: "text-end",
            width: 9
        },
        {
            label: "Fee Percent",
            property: "serviceFeePercent",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: formatPercentage,
            headerClassName: "text-end",
            cellClassName: "text-end",
            width: 5
        },
        {
            label: "Fee Amount",
            property: "serviceFeeAmount",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: formatCurrency,
            headerClassName: "text-end",
            cellClassName: "text-end",
            width: 5
        },
        {
            label: "Date",
            property: "newMoneyDate",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: (value) => <DateDisplay>{value}</DateDisplay>,
            headerClassName: "text-center",
            cellClassName: "text-center",
            width: 9
        },
        {
            label: "Status",
            property: "status",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: (status) => <FontAwesomeIcon {...NewMoneyIconProps[status]} size="lg" />,
            headerClassName: "text-center",
            cellClassName: "text-center",
            width: 5,
        },
        // Use custom component to allow status editing
        {
            label: "Edit",
            CellComponent: NewMoneyEditCellComponent,
            headerClassName: classNames("text-center", { "d-none": !canWrite }),
            cellClassName: "text-center",
            width: 5
        },
    ], [canWrite, sortableHeaderRenderer]);

    return {
        results,
        sort,
        totalCount,
        columns,
        isLoading,
        isSaving,
        isClearing,
        isError,
        error,
        clear,
        isRowLoaded,
        loadMore,
        sortableHeaderRenderer,
        reload,
        createNewMoney,
        createApprovedNewMoney,
        saveNewMoney,
        approveNewMoney,
        cancelNewMoney,
        canWrite
    };
};

export default useAdminNewMoney;