import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { useFilterContext } from "../../../hooks/FilterContext";
import { Button } from "../../../components";
import { DateInput, FormSelect } from "../../../components/forms";
import FeeTypeFormSelect from "../../headofficefeemanagement/components/FeeTypeFormSelect";
import { ThemedSpan } from "../../../components/utilities";
import ProvidersFormSelectPaged from "../../headofficefeemanagement/components/ProvidersFormSelectPaged";
import { GenericHeadOfficeUserDivisionSelect } from "../../../components/forms/selects";
import IntroducersFormSelectPaged from "../../headofficefeemanagement/components/IntroducersFormSelectPaged";
import HeadOfficeMasterAccountFormSelect from "../../headofficefeemanagement/components/HeadOfficeMasterAccountFormSelect";
import HeadOfficeUserFormSelect from "../../headofficefeemanagement/components/HeadOfficeUserFormSelect";


export const HeadOfficeDebtorManagementModal = ({ handleClose, onFiltersChanged, show, currentSearchType, ...rest }) => {

    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);
    const onApplyFilters = () => {
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters(_ => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof (handleClose) === 'function') {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters(_ => filter);
        }
    }, [filter]);

    return <Modal size="lg" centered backdrop="static" show={show}>
        <Modal.Header>Debtor Management Filters</Modal.Header>
        <Modal.Body {...rest}>
            <HeadOfficeDebtorManagementFilters
                filter={tempFilters}
                setFilter={onFilterValueChanged}
                currentSearchType = {currentSearchType}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={onClearFilters}>Clear Filters</Button>
            <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>
};


const HeadOfficeDebtorManagementFilters = ({ filter, setFilter, currentSearchType }) => {

    const {
        //organisationId,
        //divisionId,
        advisers,
        //introducers,
        providers,
        feeTypes,
        masterAccounts,
        debtOnDate
    } = filter || {};

    return <React.Fragment>
        <div className="row mb-3">
            <div className="col d-flex align-items-end justify-content-between">
                <DateInput
                    label={"Debt On Date"}
                    placeholder="Debt On Date"
                    isClearable
                    disableAnimations={true}
                    value={debtOnDate}
                    onBlur={(value, _) => setFilter('debtOnDate', value)}
                />
                <div className="col">
                </div>
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <HeadOfficeUserFormSelect
                    label={"Advisers"}
                    isMulti={true}
                    value={advisers}
                    onChange={(values) => setFilter("advisers", values)}
                />
            </div>
            <div className="col">
                <HeadOfficeMasterAccountFormSelect
                    label={"Master Account"}
                    isMulti={true}
                    onChange={(value) => setFilter("masterAccounts", value)}
                    value={masterAccounts}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <FeeTypeFormSelect
                    label={"Fee Types"}
                    isMulti={true}
                    defaultValue={feeTypes}
                    onChange={(values) => setFilter("feeTypes", values.map(x => x.value))}
                />
            </div>
            <div className="col">
                <ProvidersFormSelectPaged
                    value={providers}
                    label={"Providers"}
                    onChange={(values) => setFilter("providers", values)}
                    isMulti={true}
                />
            </div>
        </div>
    </React.Fragment>
};

export default HeadOfficeDebtorManagementFilters;