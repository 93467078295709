import { useEffect, useMemo } from "react";
import { VirtualTable } from "../../components/tables";
import { TableRecordCountDisplayFooter } from "../../components/tables/VirtualTable";
import ClientsSummaryRow, { ClientsSummaryHeader } from "./components/ClientsSummaryRow";
import FundsSummaryRow, { FundsSummaryHeader } from "./components/FundsSummaryRow";
import HoldingsSummaryRow, { HoldingsSummaryHeader } from "./components/HoldingsSummaryRow";
import { AssetViewTypes, useAssetsUnderAdvice } from "./hooks/AssetsUnderAdviceContext";

const ViewTypeRows = {
    [AssetViewTypes.FundsSummary]: {
        header: FundsSummaryHeader,
        row: FundsSummaryRow
    },
    [AssetViewTypes.ClientsSummary]: {
        header: ClientsSummaryHeader,
        row: ClientsSummaryRow
    },
    [AssetViewTypes.HoldingsSummary]: {
        header: HoldingsSummaryHeader,
        row: HoldingsSummaryRow
    }
};

const AssetsUnderAdviceGrid = ({ viewType }) => {
    const {
        setViewType,
        buildId,
        group,
        results,
        sort,
        onClickSort,
        totalCount,
        isFetching,
        isClearing,
        isError,
        error,
        isRowLoaded,
        loadMore,
        reload
    } = useAssetsUnderAdvice();

    useEffect(() => setViewType(viewType), [setViewType, viewType]);

    const [sortProperty, sortDirection] = sort?.split("+") ?? [];

    // Funds Summary has variable number of columns so footer needs variable padding
    const spanLeft = useMemo(() =>
        viewType === AssetViewTypes.FundsSummary
            ? group
                ? 2
                : 3
            : 5,
        [group, viewType]);

    const { header: Header, row: Row } = ViewTypeRows[viewType];

    const getSortProps = (sortableProperty) => ({
        className: "text-truncate",
        key: `header-${sortableProperty}`,
        onClick: () => onClickSort(sortableProperty),
        active: sortableProperty === sortProperty,
        direction: sortDirection,
        isSticky: true
    })

    if (buildId == null) {
        return <p className="m-0 text-center">
            <span className="mx-1 text-muted">Select a build to view asset analysis</span>
        </p>
    }

    return <VirtualTable
        enableScrollTracking
        row={(props) => <Row group={group} {...props} />}
        header={<Header getSortProps={getSortProps} group={group} />}
        footer={<TableRecordCountDisplayFooter totalCount={totalCount} reload={reload} spanLeft={spanLeft} spanRight={1} />}
        error={error}
        isLoading={isFetching || isClearing}
        isItemLoaded={isRowLoaded}
        loadMoreItems={loadMore}
        itemSize={46}
        itemCount={totalCount === results.length || isError ? totalCount : results.length + 3}
        itemData={!isClearing ? results : []}
    />
}

export default AssetsUnderAdviceGrid;