import { BaseQueryFn, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { useFetchAmlSectionQuery } from "../../../services/clientfactfind";
import { UseQueryHookResult } from "@reduxjs/toolkit/dist/query/react/buildHooks"

type AntiMoneyLaunderingClientStatus = {
    clientAccountId: string,
    clientName: string,
    status: string,
    isValid: boolean
};

type UseFetchAmlSectionQueryResult = {
    clientStatuses: AntiMoneyLaunderingClientStatus[],
    evidence: any[],
    status: string,
    isValid: boolean,
};

type UseFetchAmlSectionQueryArgs = {
    masterAccountId: string
}

type UseFetchAmlSectionQueryDefinition = QueryDefinition<UseFetchAmlSectionQueryArgs, BaseQueryFn, string, UseFetchAmlSectionQueryResult>;

type UseFetchAmlEvidenceHookResult = {
    clientStatuses: AntiMoneyLaunderingClientStatus[]
} & UseQueryHookResult<UseFetchAmlSectionQueryDefinition>;

export const useFetchAmlEvidence = (masterAccountId: string): UseFetchAmlEvidenceHookResult => {
    const { data, ...result } = useFetchAmlSectionQuery({ masterAccountId });

    const { clientStatuses } = data || { clientStatuses: [] };
    return {
        clientStatuses,
        ...result
    }
}

export default useFetchAmlEvidence;