import React, { useCallback, useMemo, useState } from "react";
import  { useSearchDebtors }  from "../hooks/useSearchDebtors";
//import { useSearchFees } from "../../headofficefeemanagement/hooks/useSearchFees";
import DebtorTableRow from "./DebtorTableRow";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { VirtualTable } from "../../../components/tables";
import DebtorTableHeader from "./DebtorTableHeader";
import FeeGroupingBreakdownModal from "../../headofficefeemanagement/components/FeeGroupingBreakdownModal";//need one for debtors

const DebtorTable = () => {

    const { results, totalCount, loadMoreItems, isItemLoaded, error, isClearing, isError, retrySearchDebtors, updateSort, sort } = useSearchDebtors();
    const [chosenGrouping, setChosenGrouping] = useState(null);
    const chosenGroupingData = useMemo(() => results?.find(x => x.groupId === chosenGrouping ), [results, chosenGrouping]);
    const columns = useMemo(() => [
        {label: 'Fee Date'},
        { label: 'Fee Type' },
        { label: 'Fee (£)' },
        { label: 'Debt (£)' },
        { label: 'Client' },
        { label: 'Date' },
        { label: 'Application ID' },
        { label: 'Description' },
        { label: "" }
    ], [])

    //console.log('debtorGroupingData', chosenGroupingData);

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={retrySearchDebtors} />
        </ErrorMessageWrapper>;
    }
    return (
        <React.Fragment>
            <VirtualTable
                itemCount={totalCount === results.length ? totalCount :  results.length + 25}
                itemSize={42}
                itemData={{
                    isClearing,
                    results,
                    setChosenGrouping
                }}
                row={DebtorTableRow}
                header={<DebtorTableHeader sort={sort} setSort={updateSort} />}
                isItemLoaded={isItemLoaded}
                loadMoreItems={loadMoreItems}
                columns={[
                    {label: 'Fee Date'},
                    { label: 'Fee Type' },
                    { label: 'Fee (£)' },
                    { label: 'Debt (£)' },
                    { label: 'Client' },
                    { label: 'Date' },
                    { label: 'Application ID' },
                    { label: 'Description' },
                    { label: "" }
                ]}
            />
            <FeeGroupingBreakdownModal
                show={chosenGrouping !== null}
                onHide={() => setChosenGrouping(null)}
                groupId={chosenGrouping}
                data={chosenGroupingData}
            />
        </React.Fragment>
    );
};

export default DebtorTable;