import React, { useState } from "react";
import styled from "styled-components";
import { useFilterContext } from "../../hooks/FilterContext";
import { HeadOfficeDebtorManagementModal } from "./components/HeadOfficeDebtorManagementFilters";
//import { HeadOfficeFeeManagementModal, FeeManagementFiltersModalButton } from "../headofficefeemanagement/components/HeadOfficeFeeManagementFilters";
import SearchDebtorsProvider from "./hooks/useSearchDebtors";
import DebtorTable from "./components/DebtorTable";
import { DebtorManagementPageSubHeader, Main, ThemedIcon, ThemedParagraph } from "../../components/utilities";
import { Button } from "../../components";
import { useAdministrationFeesProvider } from "../../hooks/AdministrationFeesContext"

const DebtorsManagementFiltersAppliedWarningMessage = styled(ThemedParagraph)`
    font-size: 12px !important;
`;

const HeadOfficeDebtorManagement = () => {
    const [isFiltersShown, setIsFiltersShown] = useState(false);
    const { filter, clearFilter, isFiltersApplied } = useFilterContext();
    const {currentSearchType, setFilters} = useAdministrationFeesProvider();

    React.useEffect(() => {
        setFilters(_ => filter)
    },[filter]);

    return <React.Fragment>
        <HeadOfficeDebtorManagementModal
            handleClose={() => setIsFiltersShown(false)}
            currentSearchType = {currentSearchType}
            show={isFiltersShown}
        />
        <Main className="d-flex flex-column h-100">
            <DebtorManagementPageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && (
                            <React.Fragment>
                                <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                <DebtorsManagementFiltersAppliedWarningMessage className="ms-2 text-muted">
                                    Warning - Filters are currently being applied to your results.
                                    <br />
                                    <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                </DebtorsManagementFiltersAppliedWarningMessage>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="d-flex">
                        {/*<Button
                            variant="success"
                            className="me-3"
                            onClick={() => setShowCreateGroupModal(true)}
                        >
                        <FontAwesomeIcon className="me-1" icon="plus" />Create Fee</Button>*/}
                        <Button onClick={() => setIsFiltersShown(true)}>Filter Debtors</Button>
                    </div>
                </div>
            </DebtorManagementPageSubHeader>
            <section className="px-4 my-3 h-100">
                <SearchDebtorsProvider searchType={"debtors"}>
                    <DebtorTable />
                </SearchDebtorsProvider>
            </section>
        </Main>
    </React.Fragment>


};

export default HeadOfficeDebtorManagement;