import { createContext, useContext, useEffect, useState } from "react";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useLazySearchFeeGroupsQuery } from "../../../services/headofficeadmin";

const SearchFeesContext = createContext();

export const useSearchFees = () => useContext(SearchFeesContext);


const SearchFeesProvider = ({ children, searchType}) => {

    const limit = 50;
    const { filter } = useFilterContext();
    const [isClearing, setIsClearing] = useState(false);
    const [sort, setSort] = useState("feeDate+ASC");
    const [searchTrigger, searchResults] = useLazySearchFeeGroupsQuery();
    const { data, isLoading, isFetching, isError, error, isUninitialized } = searchResults;
    const { results, pagination, hasMore } = data || { results: [], hasMore: true };
    const { page } = pagination || { page: 0 };

    

    const clear = () => {
        setIsClearing(true);
        searchTrigger({
            searchType: searchType,
            filter:{...filter,
            adviserIds: filter.advisers?.map(x => x.value) ?? [],
            introducerIds: filter.introducers?.map(x => x.value) ?? [],
            masterAccountIds: filter.masterAccounts?.map(x => x.value) ?? [],
            providerIds: filter.providers?.map(x => x.value) ?? [],
            limit,
            page: 1,
            sort}
        }).finally(() => setIsClearing(false));
    };
    
    const isItemLoaded = (index) => index < results.length || results[index];

    const loadMoreItems = () => {
        searchTrigger({
            searchType: searchType,
            filter:{...filter,
            adviserIds: filter.advisers?.map(x => x.value) ?? [],
            introducerIds: filter.introducers?.map(x => x.value) ?? [],
            masterAccountIds: filter.masterAccounts?.map(x => x.value) ?? [],
            providerIds: filter.providers?.map(x => x.value) ?? [],
            limit,
            page: page + 1,
            sort}
        });
    };

    const retrySearchFees = (e) => {
        if (e && typeof (e) === "function") {
            e.preventDefault();
        }

        clear();
    };

    const updateSort = (property) => {
        const [oldProperty, direction] = sort.split("+");

        if (oldProperty === property) {
            return setSort(`${property}+${direction === "ASC" ? "DESC" : "ASC"}`)
        }

        setSort(`${property}+DESC`);
    };

    useEffect(() => {
        if (isUninitialized) return;

        clear();
    }, [sort, filter]);


    return <SearchFeesContext.Provider value={{
        results: results || [],
        page,
        isClearing,
        isLoading,
        isFetching,
        error,
        isError,
        sort,
        totalCount: hasMore ? results.length + 25 : results.length,
        isItemLoaded,
        loadMoreItems,
        retrySearchFees,
        updateSort
    }}>
        {children}
    </SearchFeesContext.Provider>
};
//};
export default SearchFeesProvider;