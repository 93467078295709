import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useClientContext } from "../../../hooks/ClientContext";
import { useCreateMessageMutation } from "../../../services/messages";

const ClientMessagingContext = createContext();

export const useClientMessaging = () => useContext(ClientMessagingContext);

export const ClientMessagingViewTypes = {
    Messages: "messages",
    Conversations: "conversations"
};

export const viewTypeOptions = [
    {
        value: 0,
        label: "All Messages",
        viewType: ClientMessagingViewTypes.Messages,
        // route: `?viewType=${ClientMessagingViewTypes.Messages}`
    },
    {
        value: 1,
        label: "Client Messages",
        viewType: ClientMessagingViewTypes.Messages,
        clientMessagesOnly: true
        // route: `?viewType=${ClientMessagingViewTypes.Messages}&clientMessagesOnly=true`
    },
    {
        value: 2,
        label: "All Conversations",
        viewType: ClientMessagingViewTypes.Conversations
        // route: `?viewType=${ClientMessagingViewTypes.Conversations}`
    },
    {
        value: 3,
        label: "Client Conversations",
        viewType: ClientMessagingViewTypes.Conversations,
        clientMessagesOnly: true
        // route: `?viewType=${ClientMessagingViewTypes.Conversations}&clientMessagesOnly=true`
    },
    {
        value: 4,
        label: "Outstanding Messages",
        viewType: ClientMessagingViewTypes.Messages,
        active: true
        // route: `?viewType=${ClientMessagingViewTypes.Messages}&active=true`
    },
    {
        value: 5,
        label: "Unread Messages",
        viewType: ClientMessagingViewTypes.Messages,
        unreadMessages: true,
        active: true
        // route: `?viewType=${ClientMessagingViewTypes.Messages}&unreadMessages=true&active=true`
    }
];

const getRoute = ({ value: _v, label: _l, viewType, ...searchParams }) =>
    `?viewType=${viewType}${Object.keys(searchParams).length === 0 ? '' : `?${new URLSearchParams(searchParams)}`}`;

const ClientMessagingProvider = ({ children }) => {
    const navigate = useNavigate();
    const { id: masterAccountId } = useClientContext();
    const [createMessageTrigger, createMessageResult] = useCreateMessageMutation();

    const [searchParams, setSearchParams] = useSearchParams();
    const [hideSystemMessages, setHideSystemMessages] = useState(true);
    const [feedbackOnly, setFeedbackOnly] = useState(false);
    const [showMyMessages, setShowMyMessages] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const filters = useMemo(() => ({
        active: searchParams.get("active") === "true",
        clientMessagesOnly: searchParams.get("clientMessagesOnly") === "true",
        feedbackOnly: feedbackOnly,
        hideSystemMessages: hideSystemMessages,
        showMyMessages: showMyMessages,
        threadId: searchParams.get("threadId"),
        unreadMessages: searchParams.get("unreadMessages") === "true",
        viewType: searchParams.get("viewType")
    }), [searchParams, feedbackOnly, hideSystemMessages, showMyMessages]);

    const [selectedViewType, setSelectedViewType] = useState(0);

    const createMessage = ({ threadId, recipients = [], message = "", isActionRequired = false, messagePriority, threadSubject = null, threadId: replyThreadId = null }) => {
        return new Promise((resolve, reject) => {
            const recipientIds = recipients.map(x => x.userId);
            return createMessageTrigger({
                ...filters,
                threadId: replyThreadId ?? threadId,
                recipientIds,
                masterAccountId,
                message,
                page: 1,
                limit: 25,
                isActionRequired,
                messagePriority,
                threadSubject
            })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    const navigateToThread = (threadId) => {
        if(threadId === null){
            searchParams.delete("threadId");
            setSearchParams(searchParams);
            return;
        }

        searchParams.set("threadId", threadId)
        setSearchParams(searchParams);
    }

    useEffect(() => {
        navigate(getRoute(viewTypeOptions[selectedViewType]));
    }, [selectedViewType, viewTypeOptions, navigate]);


    return <ClientMessagingContext.Provider value={{
        createMessageResult,
        filters,
        hideSystemMessages,
        feedbackOnly,
        selectedViewType,
        showMyMessages,
        searchTerm,
        showThreads: filters.viewType === ClientMessagingViewTypes.Conversations,
        threadId: filters.threadId,
        createMessage,
        navigateToThread,
        setHideSystemMessages,
        setFeedbackOnly,
        setSelectedViewType,
        setShowMyMessages,
        setSearchTerm
    }}>
        {children}
    </ClientMessagingContext.Provider>
};

export default ClientMessagingProvider;