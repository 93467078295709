import React from "react";
import FavouriteSection from "./FavouriteSection";
import useFetchRecentClients from "../hooks/useFetchRecentClients";
import { DateDisplay } from "../../../components";
import { ActionIcon } from "../../../components/utilities";
import { useNavigate } from "react-router-dom";

export const RecentClientsListItem = ({ account, onToggle }) => {
    const appNavigator = useNavigate();
    const { accountName, accountReference, masterAccountId, lastOpenedOnDate } = account || {};

    const onAccountOpenClicked = () => {
        if (onToggle && typeof (onToggle) === 'function') {
            onToggle();
        }
        appNavigator(`/client/${masterAccountId}`)
    };

    return <div className="row">
        <div className="col-md-auto">
            <ActionIcon icon="fa-folder-open" onClick={onAccountOpenClicked} />
        </div>
        <div className="col-md-auto font-monospace">{accountReference}</div>
        <div className="col">{accountName}</div>
        <div className="col-md-auto">
            <DateDisplay format="DD/MM/YYYY HH:mm" className="font-monospace">{lastOpenedOnDate}</DateDisplay>
        </div>
    </div>
};

const RecentClientsList = (props) => <FavouriteSection label="Recent Clients" hook={useFetchRecentClients} RecordItem={RecentClientsListItem} {...props} />

export default RecentClientsList;   