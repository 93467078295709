import React from "react";
import ClientReportTableRowActionColumn from "./ClientReportTableRowActionColumn";
import ClientReportTableRowColumn from "./ClientReportTableRowColumn";
import ClientReportTableRowEfficacyColumn from "./ClientReportTableRowEfficacyColumn";
import { useNavigate } from "react-router-dom";
import { DateDisplay, ProfileDisplay } from "../../../components";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";
import { ThemedSpan } from "../../../components/utilities";
import { useClientReportsList } from "../../../hooks/ClientReportContext";

// TODO: return here and start displaying names, etc all within a tippy

const ClientReportTableRow = ({ data, index, style, dateFormat = "DD/MM/YYYY" }) => {
    const { height } = style;
    const { openReport } = useClientReportsList();
    const navigate = useNavigate();

    // do not return a data row when the data has not yet been collected from the backend
    if (index >= data.length) {
		return <TableLoadingRow style={{ height }} />
	}

    const {
        documentId,
        typeDescription, 
        reportName,
        projectId,
        projectName,
        statusDescription,
        authorId,
        efficacy,
        complianceUserId,
        compliantDate,
        sentDate,
        createdDate
    } = data[index];

    const handleOpenDocument = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        if (openReport && typeof(openReport) === 'function') {
            openReport(documentId, reportName);
        }
    };

    const handleOpenProject = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        navigate(`../projects?projectId=${projectId}`, { relative: 'path' });
    };

    return (
        <tr>
            {/* Report Name & Type */}
            <ClientReportTableRowColumn width={25} style={{ height }}>
                <p className="m-0 lh-1">
                    <a href={`open?id=${documentId}`} onClick={handleOpenDocument}>{reportName}</a>
                    <br/>
                    <ThemedSpan variant="muted" className="fs-smallest">{typeDescription}</ThemedSpan>
                </p>
            </ClientReportTableRowColumn>
            {/* Project */}
            <ClientReportTableRowColumn width={12.5}>
                <a href={`../projects?projectId=${projectId}`} onClick={handleOpenProject}>{projectName}</a>
            </ClientReportTableRowColumn>
            {/* Status */}
            <ClientReportTableRowColumn width={11.5}>
                {statusDescription}
            </ClientReportTableRowColumn>
            {/* Author */}
            <ClientReportTableRowColumn width={11.25}>
                {authorId ? (<ProfileDisplay userId={authorId} />) : 'Author not assigned'}
            </ClientReportTableRowColumn>
            {/* Efficacy */}
            <ClientReportTableRowEfficacyColumn width={5} efficacy={efficacy} />
            {/* Compliance User / Officer Info */}
            <ClientReportTableRowColumn width={11.25}>
                {complianceUserId ? (<ProfileDisplay userId={complianceUserId} />) : (<span>-</span>)}
            </ClientReportTableRowColumn>
            {/* The date the report was made compliant */}
            <ClientReportTableRowColumn width={7}>
                {compliantDate ? (<DateDisplay format="DD/MM/YYYY">{compliantDate}</DateDisplay>) : (<span>-</span>)}
            </ClientReportTableRowColumn>
            {/* The date the report was sent out to the client */}
            <ClientReportTableRowColumn width={7}>
                {sentDate ? (<DateDisplay format="DD/MM/YYYY">{sentDate}</DateDisplay>) : (<span>-</span>)}
            </ClientReportTableRowColumn>
            {/* The date the report was created */}
            <ClientReportTableRowColumn width={7}>
                <DateDisplay format={dateFormat}>{createdDate}</DateDisplay>
            </ClientReportTableRowColumn>
            {/* Action Column  */}
            <ClientReportTableRowActionColumn width={2.5} data={data[index]} />
        </tr>
    );
};

export default ClientReportTableRow;