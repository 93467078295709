import { wealthProApi } from '../../redux/api';

const hoAdminFeeGroupingApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["applicationFees"]
})
    .injectEndpoints({
        endpoints: (builder) => ({
            createFeeGroup: builder.mutation({
                query: (request) => ({
                    url: 'api/headofficeadmin/feegrouping',
                    method: 'POST',
                    body: request
                }),
                async onQueryStarted(params, { dispatch, queryFulfilled }) {
                    try {
                        var { data: newFeeGroup } = await queryFulfilled;

                        dispatch(wealthProApi.util.updateQueryData('searchFeeGroups', {}, ({ results, pagination }) => {
                            return {
                                pagination: {
                                    ...pagination,
                                    totalCount: pagination.totalCount + 1
                                },
                                results: [newFeeGroup, ...results]
                            }
                        }));
                    }
                    catch { }
                },
                invalidatesTags: ['applicationFees']
            }),
            fetchTransactionAllocationsByGrouping: builder.query({
                query: ({ groupId }) => `api/headofficeadmin/feegrouping/${groupId}/transactionAllocations`,
                providesTags: ['applicationFees']
            }),
            searchTransactionAllocationsByGrouping: builder.query({
                query: ({ groupId, filters }) => ({
                    url: `api/headofficeadmin/feegrouping/${groupId}/transactionAllocations`,
                    method: 'POST',
                    body: filters
                }),
                providesTags: ['applicationFees']
            }),
            getFeeGroupingTotals: builder.query({
                query: () => 'api/headofficeadmin/feegrouping/totals',
                providesTags: ['applicationFees']
            }),
            searchFeeGroups: builder.query({
                query: ({searchType, filter}) => ({
                    url: `api/headofficeadmin/feegrouping/search${searchType? `?searchType=${searchType}` :""}`,
                    method: 'POST',
                    body: filter,
                }),
                serializeQueryArgs: ({ endpointName, queryArgs }) => {
                    return endpointName;
                },
                merge: (currentCache, newItems) => {
                    const { pagination: { page: currPage } } = currentCache || {};
                    const { pagination: { page: nextPage }, hasMore } = newItems || {};

                    if (!currPage || nextPage <= currPage) {
                        return newItems;
                    }

                    currentCache.hasMore = newItems.hasMore;
                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                },
                forceRefetch: ({ currentArg, previousArg }) => {
                    const { pagination: { page: currPage }, userId: currUserId } = currentArg || { pagination: { page: 1 }, userId: null };
                    const { pagination: { page: prevPage }, userId: prevUserId } = previousArg || { pagination: { page: 0 }, userId: null };
                    return currPage !== prevPage || currUserId !== prevUserId;
                },
            }),
            searchFeeGroupTotals: builder.query({
                query: ({searchType, filter}) => ({
                    url: `api/headofficeadmin/feegrouping/search/totals${searchType? `?searchType=${searchType}` :""}`,
                    method: 'POST',
                    body: filter
                }),
                providesTags: ['applicationFees']
            }),
        })
    });

export const {
    useCreateFeeGroupMutation,
    useFetchTransactionAllocationsByGroupingQuery,
    useSearchTransactionAllocationsByGroupingQuery,
    useGetFeeGroupingTotalsQuery,
    useSearchFeeGroupsQuery,
    useSearchFeeGroupTotalsQuery,
    useLazySearchFeeGroupTotalsQuery,
    useLazySearchFeeGroupsQuery
} = hoAdminFeeGroupingApiEndpoints;