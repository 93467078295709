import React from "react";
import moment from "moment";
import Moment from "react-moment";
import styled from "styled-components";
import { variantToColour } from "../helpers/theme";

const StyledMoment = styled(Moment)`
    color: ${props => variantToColour(props)};
`;

const DateDisplay = ({ 
    format = "DD/MM/YYYY",
    fromNow = false,
    utc = true, 
    days = 7,
    children,
    variant = null,
    ...rest 
}) => {
    if (!children || typeof(children) !== 'string') {
        return null;
    }

    const daysDiff = moment().diff(moment(children), 'days');
    if (fromNow === true && daysDiff <= days) {
        if (!variant) {
            return <Moment fromNow utc {...rest}>{children}</Moment>
        }
        else {
            return <StyledMoment fromNow utc {...rest}>{children}</StyledMoment>
        }
    }
    else {
        if (!variant) {
            return <Moment format={format} utc={utc} {...rest}>{children}</Moment>
        }
        else {
            return <StyledMoment format={format} utc={utc} {...rest}>{children}</StyledMoment>
        }
    }
    
};

export default DateDisplay;