import React, { useMemo, useState } from "react";
import styled from "styled-components";
import DateDisplay from "../../components/DateDisplay";
import Button, { IconButton } from "../../components/buttons";
import { NumericFormat } from "react-number-format";
import { FormSelect } from "../../components/forms";
import { ThemedSpan } from "../../components/utilities";
import { Modal } from "react-bootstrap";
import { useFetchApplicationPhasingOptionsQuery, usePatchApplicationMutation, useSetCurrentPhaseTransactionsToReadyMutation } from "../../services/headofficeadmin";
import { patchReplace } from "../../helpers/patchDoc";


const FeeType = {
    Fixed: 0,
    Percent: 1,
    None: 2
};

const PolicyTimePeriodFrequencies = {
    0: "one off",
    1: "monthly",
    2: "quarterly",
    3: "biannually",
    4: "annually"
};

const TimePeriodFrequencies = {
    0: "monthly",
    1: "quarterly",
    2: "annually",
    3: "biannually"
};

const ApplicationHeaderWrapper = styled.div`
    display: flex;
    justify-content : space-around;
    align-items: center;
    text-align: center;
    padding: 1rem 0;
`;

const ReadyToTransactModal = ({ show, onClose, currentPhase, applicationReference, applicationId }) => {

    const [trigger] = useSetCurrentPhaseTransactionsToReadyMutation();

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = (request, e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        onSubmit(request)
            .then(
                r => {
                    handleClose()
                }
            );
    };

    const onSubmit = (request) => {
        return new Promise((resolve, reject) => {
            return trigger({ applicationId, ...request })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                );
        });
    };

    return <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>{applicationReference} Phase {currentPhase}</Modal.Header>
        <Modal.Body>
            <ThemedSpan>
                This will set all the transactions in phase {currentPhase} to 'Ready to Transact'.
                <br />
                <br />
                Are you sure you want to proceed?
            </ThemedSpan>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={(e) => handleSubmit({ includeTaxReclaim: true }, e)} variant="primary">Confirm, include tax reclaim</Button>
            <Button onClick={(e) => handleSubmit({ includeTaxReclaim: false }, e)} variant="success">Confirm</Button>
            <Button onClick={handleClose} variant="light">Cancel</Button>
        </Modal.Footer>
    </Modal>

};

const AdministrationApplicationInfo = ({ application }) => {

    const { data: phasingOptions, isLoading: isLoadingPhasingOptions } = useFetchApplicationPhasingOptionsQuery({ applicationId: application.applicationId });
    const [patchTrigger] = usePatchApplicationMutation();
    const [readyToTransactModal, setShowReadyToTransactModal] = useState(false);
    const { isRaiseFee } = application || {};

    const updateApplication = (property, newValue) => {
        return new Promise((resolve, reject) => {
            const operations = [patchReplace(property, newValue)];
            return patchTrigger({ applicationId: application.applicationId, operations })
                .unwrap()
                .then(
                    r => resolve(r),
                    e => reject(e)
                )
        });
    }

    const FeeDisplay = useMemo(() => {
        if (!application) return;

        if (application.feeType === FeeType.Fixed) {
            return <NumericFormat
                displayType="text"
                value={application.totalFees}
                thousandSeparator
                prefix={"£"}
            />;
        }
        else if (application.feeType === FeeType.Percent) {
            return <NumericFormat
                displayType="text"
                value={application.feePercent}
                thousandSeparator
                suffix="%"
            />;
        }

        return null;
    }, [application])

    if (!application) return;


    if (isRaiseFee) {
        return <ApplicationHeaderWrapper className="border-bottom">
            <b className="me-3"><ThemedSpan>{application.description}</ThemedSpan></b>

            <b className="me-3">
                <ThemedSpan>
                    Created:
                    <DateDisplay className="ps-1">
                        {application.createdDate}
                    </DateDisplay>
                </ThemedSpan>
            </b>

            <b className="me-3"><ThemedSpan>Application ID: {application.referenceId}</ThemedSpan></b>
        </ApplicationHeaderWrapper>;
    }


    return <ApplicationHeaderWrapper className="border-bottom">
        <b><ThemedSpan>{application.description}</ThemedSpan></b>

        <b>
            <ThemedSpan>
                Created:
                <DateDisplay className="ps-1">
                    {application.createdDate}
                </DateDisplay>
            </ThemedSpan>
        </b>

        {!application.isPolicy && <b><ThemedSpan>{application.providerName}</ThemedSpan></b>}

        <b>
            <ThemedSpan>
                Total: <NumericFormat
                    value={application.amount}
                    thousandSeparator={true}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    prefix={"£"}
                />
                {application.amountFrequency && <ThemedSpan className="mx-1">
                    {!application.isPolicy
                        ? TimePeriodFrequencies[application.amountFrequency]
                        : PolicyTimePeriodFrequencies[application.amountFrequency]
                    }
                </ThemedSpan>}
            </ThemedSpan>

        </b>

        {application.totalFees > 0 ? (<b>
            <ThemedSpan>
                Fees: {FeeDisplay} {!application.isPolicy && `(${application.feePaymentMethodText})`}
            </ThemedSpan>
        </b>

        ) : (
            <b>No Fees</b>
        )}

        {!application.isPolicy && (
            application.monthsPhased > 0 ? (
                <div className="d-flex align-items-center">
                    <FormSelect
                        className={"w-100"}
                        options={phasingOptions}
                        isLoadingOptions={isLoadingPhasingOptions}
                        defaultValue={application.currentPhase}
                        onChange={({ value }) => updateApplication("currentPhase", value)}
                    />
                    {
                        application.phaseStartDate
                            ? <ThemedSpan className="ms-2"><b>Started: <DateDisplay>{application.phaseStartDate}</DateDisplay></b></ThemedSpan>
                            : <ThemedSpan className="ms-2"><b>Not started.</b></ThemedSpan>
                    }

                    <IconButton
                        icon="fa-refresh"
                        className="ms-2"
                        onClick={() => setShowReadyToTransactModal(true)}
                    />
                </div>
            ) : (
                <b><ThemedSpan>No Phasing</ThemedSpan></b>
            ))}
        <ReadyToTransactModal
            currentPhase={application.currentPhase}
            show={readyToTransactModal}
            onClose={() => setShowReadyToTransactModal(false)}
            applicationId={application.applicationId}
            applicationReference={application.applicationReference}
        />
    </ApplicationHeaderWrapper>;
};

export default AdministrationApplicationInfo;