import toast from "react-hot-toast";
import { useLazyExportAllClientValuationsBuildQuery } from "../../../services/valuations";

const useAcvExcelExport = ({ isFetching, isError, ...request }) => {
    const [exportTrigger, exportResult] = useLazyExportAllClientValuationsBuildQuery();

    const exportAndOpen = async (csv) =>
        await exportTrigger({
            csv,
            ...request
        }).unwrap().then(res => {
            const { blob, filename } = res;
            const url = window.URL.createObjectURL(blob);

            // Create a temporary link to trigger the download with set filename
            const tempAnchor = document.createElement('a');
            tempAnchor.href = url;
            tempAnchor.download = filename;
            document.body.appendChild(tempAnchor);
            tempAnchor.click();

            // Clean up the URL object
            document.body.removeChild(tempAnchor);
            window.URL.revokeObjectURL(url);
        });

    const exportExcel = () => {
        if (isFetching || isError)
            return;

        return toast.promise(exportAndOpen(), {
            loading: "Exporting...",
            success: "Export complete.",
            error: (err) => console.error(err) || "Failed to export."
        });
    }

    return {
        exportExcel,
        ...exportResult
    };
}

export default useAcvExcelExport;