import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { useFilterContext } from "../../../hooks/FilterContext";
import { Button } from "../../../components";
import { DateInput, FormSelect } from "../../../components/forms";
import FeeTypeFormSelect from "./FeeTypeFormSelect";
import { ThemedSpan } from "../../../components/utilities";
import ProvidersFormSelectPaged from "./ProvidersFormSelectPaged";
import { GenericHeadOfficeUserDivisionSelect } from "../../../components/forms/selects";
import IntroducersFormSelectPaged from "./IntroducersFormSelectPaged";
import HeadOfficeMasterAccountFormSelect from "./HeadOfficeMasterAccountFormSelect";
import HeadOfficeUserFormSelect from "./HeadOfficeUserFormSelect";


export const HeadOfficeFeeManagementModal = ({ handleClose, onFiltersChanged, show, currentSearchType, ...rest }) => {

    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onApplyFilters = () => {
        setFilter(original => {
            return {
                ...original,
                ...tempFilters
            }
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters(_ => defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters(original => {
            return {
                ...original,
                [property]: value
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof (handleClose) === 'function') {
            handleClose();
        }
    };

    useEffect(() => {
        if (!_.isEqual(filter, tempFilters)) {
            setTempFilters(_ => filter);
        }
    }, [filter]);

    return <Modal size="lg" centered backdrop="static" show={show}>
        <Modal.Header>{currentSearchType !== "cash" ? "Fee" : "Cash"} Management Filters</Modal.Header>
        <Modal.Body {...rest}>
            <HeadOfficeFeeManagementFilters
                filter={tempFilters}
                setFilter={onFilterValueChanged}
                currentSearchType = {currentSearchType}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={onClearFilters}>Clear Filters</Button>
            <Button variant="success" onClick={onApplyFilters}>Apply Filters</Button>
            <Button variant="light" onClick={onClose}>Cancel</Button>
        </Modal.Footer>
    </Modal>
};


const HeadOfficeFeeManagementFilters = ({ filter, setFilter, currentSearchType }) => {

    const {
        organisationId,
        divisionId,
        advisers,
        introducers,
        providers,
        feeTypes,
        masterAccounts,
        startDate,
        endDate
    } = filter;

    return <React.Fragment>
        <div className="row mb-3">
            <div className="col">
                <FormSelect
                    options={[]}
                    label={"Organisation"}
                    defaultValue={organisationId}
                    onChange={({ value }) => setFilter("organisationId", value)}
                />
            </div>
            <div className="col">
                <GenericHeadOfficeUserDivisionSelect
                    label={"Division"}
                    defaultValue={divisionId}
                    onChange={({ value }) => setFilter("divisionId", value)}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <FeeTypeFormSelect
                    label={"Fee Types"}
                    isMulti={true}
                    defaultValue={feeTypes}
                    onChange={(values) => setFilter("feeTypes", values.map(x => x.value))}
                />
            </div>
            <div className="col">
                <ProvidersFormSelectPaged
                    value={providers}
                    label={"Providers"}
                    onChange={(values) => setFilter("providers", values)}
                    isMulti={true}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <HeadOfficeUserFormSelect
                    label={"Advisers"}
                    isMulti={true}
                    value={advisers}
                    onChange={(values) => setFilter("advisers", values)}
                />
            </div>
            <div className="col">
                <IntroducersFormSelectPaged
                    isMulti={true}
                    label={"Introducers"}
                    value={introducers}
                    onChange={(values) => setFilter("introducers", values)}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <HeadOfficeMasterAccountFormSelect
                    label={"Master Account"}
                    isMulti={true}
                    onChange={(value) => setFilter("masterAccounts", value)}
                    value={masterAccounts}
                />
            </div>
            <div className="col d-flex align-items-end justify-content-between">
                <DateInput
                    label={currentSearchType !== "cash" ? "Fee Date" : "Cash Received"}
                    placeholder="Start Date"
                    isClearable
                    disableAnimations={true}
                    value={startDate}
                    onBlur={(value, _) => setFilter('startDate', value)}
                />
                <ThemedSpan
                    className="mx-3"
                    variant="primary"
                >
                    to
                </ThemedSpan>
                <DateInput
                    label={null}
                    placeholder="End Date"
                    isClearable
                    disableAnimations={true}
                    value={endDate}
                    onBlur={(value, _) => setFilter('endDate', value)}
                />
            </div>
        </div>
    </React.Fragment>
};

export default HeadOfficeFeeManagementFilters;