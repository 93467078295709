import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../components/buttons";
import { DateInput, FormInput, FormSelect } from "../../components/forms";
import { useFilterContext } from "../../hooks/FilterContext";
import useFundManagementFilterOptions from "./hooks/useFundManagementFilterOptions";

const options = {
    volatility: [
        { label: "Cash", value: 0 },
        { label: "Low", value: 1 },
        { label: "Low to Moderate", value: 2 },
        { label: "Moderate", value: 3 },
        { label: "Moderate to High", value: 4 },
        { label: "High", value: 5 },
        { label: "Structured Product", value: 6 }
    ],
    monitoring: [
        { label: "Actively Monitored Core", value: 0 },
        { label: "Actively Monitored", value: 1 },
        { label: "Passively Monitored", value: 2 }
    ],
    management: [
        { label: "Active", value: 0 },
        { label: "Passive", value: 1 },
        { label: "Hybrid", value: 2 }
    ],
    assetClass: [
        { label: "Multi Asset", value: 0 },
        { label: "Equity", value: 1 },
        { label: "Equity Income", value: 2 },
        { label: "Fixed Interest", value: 3 },
        { label: "Property", value: 4 },
        { label: "Alternatives", value: 5 },
        { label: "Targeted Absolute Return", value: 6 },
        { label: "Structured Product", value: 7 },
        { label: "Cash", value: 8 },
        { label: "Investment Trust" }
    ],
    region: [
        { label: "Default", value: 0 },
        { label: "Global", value: 1 },
        { label: "United Kingdom", value: 2 },
        { label: "Europe", value: 3 },
        { label: "North America", value: 4 },
        { label: "Japan", value: 5 },
        { label: "China", value: 6 },
        { label: "Asia Pacific", value: 7 },
        { label: "Emerging Markets", value: 8 },
        { label: "Other", value: 9 }
    ],
    style: [
        { label: "Value", value: 0 },
        { label: "Growth", value: 1 },
        { label: "Flexible", value: 2 }
    ],
    opinion: [
        { label: "Satisfied", value: 0 },
        { label: "Under Review", value: 1 },
        { label: "Change Recommended", value: 2 },
        { label: "Not Applicable", value: 3 }
    ],
    guidance: [
        { label: "Appropriate for New Investments", value: 0 },
        { label: "Appropriate for Disposals Proceeds", value: 1 }
        // TODO: More guidance options, still to be defined
    ],
    controlStatus: [
        { label: "Draft", value: 0 },
        { label: "Await Approval", value: 1 },
        { label: "Approved", value: 2 },
        { label: "Published", value: 3 }
    ],
    publishStatus: [
        { label: "Draft", value: 0 },
        { label: "Approved", value: 1 },
        { label: "Published", value: 2 }
    ]
};

export const InvestmentCommitteeFundFiltersModalButton = ({
    className,
    variant = "primary",
    ...rest
}) => {
    return <div className={className}>
        <Button variant={variant} {...rest}>
            Filter Funds
        </Button>
    </div>;
};

export const InvestmentCommitteeFundFiltersModal = ({
    handleClose,
    onFiltersChanged,
    show,
    isManagement = false,
    ...rest
}) => {
    const { defaultFilters, filter, setFilter } = useFilterContext();
    const [tempFilters, setTempFilters] = useState(filter);

    const onApplyFilters = () => {
        setFilter((original) => {
            return {
                ...original,
                ...tempFilters,
            };
        });
        onClose();
    };

    const onClearFilters = () => {
        setTempFilters(defaultFilters);
    };

    const onFilterValueChanged = (property, value) => {
        setTempFilters((original) => {
            return {
                ...original,
                [property]: value,
            };
        });
    };

    const onClose = () => {
        if (handleClose && typeof handleClose === "function") {
            handleClose();
        }
    };

    useEffect(() => {
        setTempFilters(oldFilter => _.isEqual(filter, oldFilter)
            ? oldFilter
            : filter);
    }, [filter]);

    return <Modal size="xl" centered backdrop="static" show={show}>
        <Modal.Header>Fund Filters</Modal.Header>
        <Modal.Body {...rest}>
            {isManagement
                ? <FundManagementFilters
                    filter={tempFilters}
                    setFilter={onFilterValueChanged}
                />
                : <FundAnalysisFilters
                    filter={tempFilters}
                    setFilter={onFilterValueChanged}
                />}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={onClearFilters}>
                Clear Filters
            </Button>
            <Button variant="success" onClick={onApplyFilters}>
                Apply Filters
            </Button>
            <Button variant="light" onClick={onClose}>
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>;
};

const getSelectedValues = (selection) => selection.value;

export const FundAnalysisFilters = ({ filter, setFilter }) => {
    const { assetName, monitoring, standardPortfolio, volatilityProfile, assetClass, managementType, region, style } = filter;

    return <React.Fragment>
        <div className="row mb-3">
            <div className="col-5">
                <FormInput
                    id="assetName"
                    label="Asset Name"
                    value={assetName}
                    placeholder="Asset Name"
                    onBlur={(value) => setFilter("assetName", value)}
                    disableAnimations
                />
            </div>
            <div className="col">
                <FormSelect
                    label="Monitoring"
                    isMulti
                    defaultValue={monitoring}
                    options={options.monitoring}
                    onBlur={(selections) => setFilter("monitoring", selections.map(getSelectedValues))}
                    disableAnimations
                />
            </div>
            <div className="col">
                <FormSelect
                    label="Standard Portfolio"
                    isMulti
                    defaultValue={standardPortfolio}
                    // TODO options={}
                    onBlur={(selections) => setFilter("standardPortfolio", selections.map(getSelectedValues))}
                    disableAnimations
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <FormSelect
                    label="Volatility"
                    isMulti
                    defaultValue={volatilityProfile}
                    options={options.volatility}
                    onBlur={(selections) => setFilter("volatilityProfile", selections.map(getSelectedValues))}
                    disableAnimations
                />
            </div>
            <div className="col">
                <FormSelect
                    label="Asset Class"
                    isMulti
                    defaultValue={assetClass}
                    options={options.assetClass}
                    onBlur={(selections) => setFilter("assetClass", selections.map(getSelectedValues))}
                    disableAnimations
                />
            </div>
            <div className="col">
                <FormSelect
                    label="Management Type"
                    isMulti
                    defaultValue={managementType}
                    options={options.management}
                    onBlur={(selections) => setFilter("managementType", selections.map(getSelectedValues))}
                    disableAnimations
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <FormSelect
                    label="Region"
                    isMulti
                    defaultValue={region}
                    options={options.region}
                    onBlur={(selections) => setFilter("region", selections.map(getSelectedValues))}
                    disableAnimations
                />
            </div>
            <div className="col">
                <FormSelect
                    label="Style"
                    isMulti
                    defaultValue={style}
                    options={options.style}
                    onBlur={(selections) => setFilter("style", selections.map(getSelectedValues))}
                    disableAnimations
                />
            </div>
        </div>
    </React.Fragment>;
};


export const FundManagementFilters = ({ filter, setFilter }) => {
    const { assetName, monitoring, standardPortfolio, controlStatus, opinion, guidance, analystId, publishedStartDate, publishedEndDate, opinionModifiedStartDate, opinionModifiedEndDate, publishedById } = filter;

    const { analysts, publishers, isLoadingAnalystOptions, isLoadingPublisherOptions } = useFundManagementFilterOptions();

    return <React.Fragment>
        <div className="row mb-3">
            <div className="col-5">
                <FormInput
                    id="assetName"
                    label="Asset Name"
                    value={assetName}
                    placeholder="Asset Name"
                    disableAnimations
                    onBlur={(value) => setFilter("assetName", value)}
                />
            </div>
            <div className="col">
                <FormSelect
                    label="Monitoring"
                    isMulti
                    defaultValue={monitoring}
                    options={options.monitoring}
                    disableAnimations
                    onBlur={(selections) => setFilter("monitoring", selections.map(getSelectedValues))}
                />
            </div>
            <div className="col">
                <FormSelect
                    label="Standard Portfolio"
                    isMulti
                    defaultValue={standardPortfolio}
                    // TODO options={}
                    disableAnimations
                    onBlur={(selections) => setFilter("standardPortfolio", selections.map(getSelectedValues))}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <FormSelect
                    label="Control Status"
                    isMulti
                    defaultValue={controlStatus}
                    options={options.controlStatus}
                    disableAnimations
                    onBlur={(selections) => setFilter("controlStatus", selections.map(getSelectedValues))}
                />
            </div>
            <div className="col">
                <FormSelect
                    label="Opinion"
                    isMulti
                    defaultValue={opinion}
                    options={options.opinion}
                    disableAnimations
                    onBlur={(selections) => setFilter("opinion", selections.map(getSelectedValues))}
                />
            </div>
            <div className="col">
                <FormSelect
                    label="Guidance"
                    isMulti
                    defaultValue={guidance}
                    options={options.guidance}
                    disableAnimations
                    onBlur={(selections) => setFilter("guidance", selections.map(getSelectedValues))}
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <FormSelect
                    label="Published By"
                    isMulti
                    defaultValue={publishedById}
                    options={publishers}
                    isLoadingOptions={isLoadingPublisherOptions}
                    disableAnimations
                    onBlur={(selections) => setFilter("publishedById", selections.map(getSelectedValues))}
                />
            </div>
            <div className="col-3">
                <DateInput
                    label="Published Start Date"
                    value={publishedStartDate}
                    disableAnimations
                    onBlur={(value) => setFilter("publishedStartDate", value)}
                    isClearable
                />
            </div>
            <div className="col-3">
                <DateInput
                    label="Published End Date"
                    value={publishedEndDate}
                    disableAnimations
                    onBlur={(value) => setFilter("publishedEndDate", value)}
                    isClearable
                />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col">
                <FormSelect
                    label="Analyst"
                    isMulti
                    defaultValue={analystId}
                    options={analysts}
                    isLoadingOptions={isLoadingAnalystOptions}
                    disableAnimations
                    onBlur={(selections) => setFilter("analystId", selections.map(getSelectedValues))}
                />
            </div>
            <div className="col-3">
                <DateInput
                    label="Opinion Modified Start Date"
                    value={opinionModifiedStartDate}
                    disableAnimations
                    onBlur={(value) => setFilter("opinionModifiedStartDate", value)}
                    isClearable
                />
            </div>
            <div className="col-3">
                <DateInput
                    label="Opinion Modified End Date"
                    value={opinionModifiedEndDate}
                    disableAnimations
                    onBlur={(value) => setFilter("opinionModifiedEndDate", value)}
                    isClearable
                />
            </div>
        </div>
    </React.Fragment>;
};