import { ButtonGroup } from "react-bootstrap";
import { ThemedSpan } from "../../../components/utilities";
import Button, { IconButton } from "../../../components/buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { DateDisplay, ProfileIcon } from "../../../components";
import FileMailModal from "./FileMailModal";
import { MailSendType, usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import { useAuth } from "react-oidc-context";
import CreateMailModal from "../../createmailmodal";

const EmailHeader = ({ data, fileMail, fileMailResult }) => {

    const [showFileMailModal, setShowFileMailModal] = useState(false);
    const [showCreateEmailModal, setShowCreateEmailModal] = useState(false);
    const [createEmailModalProps, setCreateEmailModalProps] = useState({});
    const { user: { profile } } = useAuth();
    const { email } = profile || {};
    const {
        subject,
        from,
        hasAttachments,
        sentDateTime,
        toRecipients,
        ccRecipients,
        bccRecipients,
        isDraft,
        isFiled,
        id
    } = data || {};



    const getRecipientListText = useCallback((recipients) => recipients?.map(x => x.emailAddress?.name ?? x.emailAddress?.address).join(", "), []);

    const bccDisplayText = useMemo(() => getRecipientListText(bccRecipients), [bccRecipients]);

    const ccDisplayText = useMemo(() => getRecipientListText(ccRecipients), [ccRecipients]);

    const fromDisplayText = useMemo(() => from?.emailAddress?.name
        ? `${from?.emailAddress?.name} <${from?.emailAddress?.address}>`
        : from?.emailAddress?.address,
        [from]
    );

    const mapRecipientToSelectOption = useCallback((x) => ({ label: x.emailAddress?.name ?? x.emailAddress?.address, value: x.emailAddress?.address }), []);

    const toDisplayText = useMemo(() => getRecipientListText(toRecipients), [toRecipients]);

    const onHideCreateMailModal = () => {
        setCreateEmailModalProps({});
        setShowCreateEmailModal(false);
    }

    const onForwardClick = () => {
        setCreateEmailModalProps({
            sendType: MailSendType.Forward,
            parentMessageId: id
        });
        setShowCreateEmailModal(true);
    };

    const onReplyClick = () => {
        setCreateEmailModalProps({
            sendType: MailSendType.Reply,
            initialToRecipients: [mapRecipientToSelectOption(from)],
            parentMessageId: id
        });
        setShowCreateEmailModal(true);
    };

    const onReplyAllClick = () => {
        let initialFrom = mapRecipientToSelectOption(from);
        let initialToRecipients = toRecipients.map(mapRecipientToSelectOption).filter((x, i) => x.value !== email && toRecipients.indexOf(x) === i);
        let initialCcRecipients = ccRecipients.map(mapRecipientToSelectOption).filter((x, i) => x.value !== email && ccRecipients.indexOf(x) === i);
        let initialBccRecipients = bccRecipients.map(mapRecipientToSelectOption).filter((x, i) => x.value !== email && bccRecipients.indexOf(x) === i);

        if (initialFrom.value !== email) {
            initialToRecipients.push(initialFrom);
        }

        setCreateEmailModalProps({
            initialBccRecipients,
            initialCcRecipients,
            initialToRecipients,
            sendType: MailSendType.ReplyAll,
            parentMessageId: id
        });
        setShowCreateEmailModal(true);
    };

    return <React.Fragment>
        <div className="row mb-2">
            <h4 className="col">{subject}</h4>
            <div className="col-auto">
                <IconButton
                    className="me-2"
                    disabled={isFiled}
                    variant={isFiled ? "success" : "danger"}
                    icon={isFiled ? "file-circle-check" : "file-circle-xmark"}
                    size="lg"
                    onClick={() => setShowFileMailModal(true)}
                />
            </div>
        </div>

        <div className="row">
            <div className="d-flex justify-content-between">
                <div className="d-flex flex-row align-items-center">
                    {/* {from?.emailAddress && <ProfileIcon
                        height={48}
                        width={48}
                        className="me-2"
                        user={{ displayName: from?.emailAddress?.name ?? from?.emailAddress?.address, surname: "" }}
                    />} */}
                    <div className="d-flex flex-column">
                        <ThemedSpan><b>{fromDisplayText}</b></ThemedSpan>
                        <ThemedSpan>To: {toDisplayText}</ThemedSpan>
                        {bccRecipients?.length > 0 && <ThemedSpan>Bcc: {bccDisplayText}</ThemedSpan>}
                        {ccRecipients?.length > 0 && <ThemedSpan>Cc: {ccDisplayText}</ThemedSpan>}
                    </div>
                </div>

                {<div className="d-flex flex-column">
                    <ButtonGroup>
                        <Button className="border" onClick={onReplyClick}>
                            <FontAwesomeIcon icon={"fa-reply"} className="me-2" />
                            Reply
                        </Button>
                        <Button className="border" onClick={onReplyAllClick}>
                            <FontAwesomeIcon icon={"fa-reply-all"} className="me-2" />
                            Reply All
                        </Button>
                        <Button className="border" onClick={onForwardClick}>
                            <FontAwesomeIcon icon={"fa-share"} className="me-2" />
                            Forward
                        </Button>
                    </ButtonGroup>

                    <ThemedSpan className="text-end"><DateDisplay format="ddd Do MMM YYYY HH:mm">{sentDateTime}</DateDisplay></ThemedSpan>
                </div>}
            </div>
        </div>
        <CreateMailModal
            show={showCreateEmailModal}
            onHide={onHideCreateMailModal}
            {...createEmailModalProps}
        />
        <FileMailModal
            show={showFileMailModal}
            onClose={() => setShowFileMailModal(false)}
            data={data}
            fileMail={fileMail}
            fileMailResult={fileMailResult}
        />
    </React.Fragment>;
};

export default EmailHeader;