import { useCallback, useEffect, useMemo } from "react";
import { IconButton } from "../../../components";
import { FormSelect } from "../../../components/forms";
import { ThemedTableHeader } from "../../../components/tables/StandardTable";
import { patchReplace } from "../../../helpers/patchDoc";
import { useHouseRules } from "../../../hooks/HouseRulesContext";
import { useCreateFundRuleMutation, useDeleteFundRuleMutation, useFetchClassGroupsForSelectQuery, useLazyFetchFundRulesQuery, usePatchFundRuleMutation } from "../../../services/assets";

const classificationOptions = [
    { label: "Asset Classification", value: 0 },
    { label: "Regional Classification", value: 1 },
    { label: "Volatility Classification", value: 2 },
    { label: "Management Style", value: 3 }
];

// Map for determining the options available
const classOptions = {
    0: [ // Asset Classification
        { label: "Multi Asset", value: 0 },
        { label: "Equity", value: 1 },
        { label: "Equity Income", value: 2 },
        { label: "Fixed Interest", value: 3 },
        { label: "Property", value: 4 },
        { label: "Alternatives", value: 5 },
        { label: "Targeted Absolute Return", value: 6 },
        { label: "Structured Product", value: 7 },
        { label: "Cash", value: 8 }
    ],
    1: [ // Regional Classification
        { label: "UK Equity", value: 9 },
        { label: "Global Equity", value: 10 },
        { label: "European Equity", value: 11 },
        { label: "North American Equity", value: 12 },
        { label: "Japanese Equity", value: 13 },
        { label: "Chinese Equity", value: 14 },
        { label: "Asia Pacific Equity", value: 15 },
        { label: "Emerging Markets", value: 16 },
        { label: "Other", value: 17 }
    ],
    2: [ // Volatility Classification
        { label: "Low Volatility", value: 18 },
        { label: "Low to Moderate Volatility", value: 19 },
        { label: "Moderate Volatility", value: 20 },
        { label: "Moderate to High Volatility", value: 21 },
        { label: "High Volatility", value: 22 },
        { label: "Cash", value: 23 },
        { label: "Structured Product", value: 24 }
    ],
    3: [ // Management Style
        { label: "Active", value: 25 },
        { label: "Passive", value: 26 },
        { label: "Hybrid", value: 27 }
    ]
}

const ClassificationSelectCell = ({ data, fullData, patchClassification, ...props }) => {
    return <FormSelect
        {...props}
        defaultValue={data.parent}
        options={classificationOptions}
        onBlur={(selection) => patchClassification(data.ruleId, selection.value)}
    />
}

const ClassOptionSelectCell = ({ data, fullData, patchFn, ...props }) => {
    // Get the options allowed for this classification category, then remove any that already have rules
    const options = useMemo(() =>
        classOptions[data.parent]?.filter(option => option.value === data.option || !fullData.some(rule => rule.option === option.value)) ?? [],
        [data.option, data.parent, fullData]);

    return <FormSelect
        {...props}
        defaultValue={data.option}
        options={options}
        onBlur={(selection) => patchFn(data.ruleId, "option", selection.value)}
        isDisabled={options.length === 0}
    />
}

const defaultPercentageColumn = {
    type: 'percent',
    isEditable: true,
    allowNegative: false,
    decimalScale: 1,
    min: 0,
    max: 100,
    headerRenderer: ({ label, ...props }) => <ThemedTableHeader className="col-1 text-center" {...props}>{label}</ThemedTableHeader>
}

const useFundRules = () => {
    const { currentClientProfile } = useHouseRules();

    const [fetchFundRules, { data: fundRules, isLoading, isFetching, isError }] = useLazyFetchFundRulesQuery({ refetchOnFocus: true });

    useEffect(() => {
        if (!isError)
            fetchFundRules({ clientProfileId: currentClientProfile }, true)
    }, [currentClientProfile, fetchFundRules, isError]);

    const refetch = useCallback(() =>
        fetchFundRules({ clientProfileId: currentClientProfile }).unwrap(),
        [currentClientProfile, fetchFundRules]);

    // CRUD operations for table
    const [createFundRuleTrigger, { isLoading: isCreatingFundRule }] = useCreateFundRuleMutation();

    const createFundRule = useCallback(() =>
        createFundRuleTrigger({ clientProfileId: currentClientProfile }).unwrap(),
        [createFundRuleTrigger, currentClientProfile]);

    const [patchFundRuleTrigger] = usePatchFundRuleMutation();

    const patchRuleClassification = useCallback((ruleId, value) =>
        patchFundRuleTrigger({ clientProfileId: currentClientProfile, ruleId, operations: [patchReplace("option", null), patchReplace("parent", value)] }).unwrap(),
        [currentClientProfile, patchFundRuleTrigger]);

    const patchFundRule = useCallback((ruleId, property, value) =>
        patchFundRuleTrigger({ clientProfileId: currentClientProfile, ruleId, operations: [patchReplace(property, value)] }).unwrap(),
        [currentClientProfile, patchFundRuleTrigger]);

    const [deleteFundRuleTrigger] = useDeleteFundRuleMutation();

    const deleteFundRule = useCallback((ruleId) =>
        deleteFundRuleTrigger({ clientProfileId: currentClientProfile, ruleId }).unwrap(),
        [currentClientProfile, deleteFundRuleTrigger]);

    // Columns and column options for table
    const { data: groups, isFetching: isLoadingGroups } = useFetchClassGroupsForSelectQuery({ clientProfileId: currentClientProfile });

    const columns = useMemo(() => [
        {
            label: "Classification",
            property: "parent",
            isEditable: true,
            headerRenderer: (props) => <ThemedTableHeader className="col" {...props}>Classification</ThemedTableHeader>,
            CustomCellComponent: ClassificationSelectCell
        },
        {
            label: "Class Option",
            property: "classificationOption",
            isEditable: true,
            headerRenderer: (props) => <ThemedTableHeader className="col" {...props}>Class Option</ThemedTableHeader>,
            CustomCellComponent: ClassOptionSelectCell
        },
        {
            ...defaultPercentageColumn,
            label: "Min. %",
            property: "minAllocation",
        },
        {
            ...defaultPercentageColumn,
            label: "Max. %",
            property: "maxAllocation",
        },
        {
            ...defaultPercentageColumn,
            label: "Max. Fund %",
            property: "maxFundAllocation",
        },
        {
            label: "Weighting",
            property: "weighting",
            isEditable: true,
            allowNegative: false,
            type: 'number',
            decimalScale: 2,
            headerRenderer: (props) => <ThemedTableHeader className="col-1 text-center" {...props}>Weighting</ThemedTableHeader>
        },
        {
            headerRenderer: () => <ThemedTableHeader style={{ width: '1px' }}>
                <IconButton icon="plus" variant="success" onClick={createFundRule} />
            </ThemedTableHeader>,
            deleteFn: deleteFundRule
        },
        {
            label: "Group",
            property: "groupId",
            isEditable: true,
            type: 'select',
            headerRenderer: (props) => <ThemedTableHeader className="col" {...props}>Group</ThemedTableHeader>,
            options: groups,
            isLoadingOptions: isLoadingGroups
        },
    ], [createFundRule, deleteFundRule, groups, isLoadingGroups]);

    return {
        fundRules,
        isLoading,
        isFetching,
        isError,
        refetch,
        createFundRule,
        isCreatingFundRule,
        patchRuleClassification,
        patchFundRule,
        columns,
    }
}

export default useFundRules;