import useEditFeeTransaction from "../hooks/useEditFeeTransaction";
import TransactionFeeModal from "../../administrationfees/components/TransactionFeeModal";
import { useAdministrationFeesProvider } from "../../../hooks/AdministrationFeesContext";
import { useEffect } from "react";

const EditTransactionFeeModalWrapper = ({ group, show, handleClose, feeTransactionId }) => {

    const {currentSearchType} = useAdministrationFeesProvider();
    
    const {
        feeTransactionDefaultValues,
        feeTransactionAllocationDefaultValues,
        isLoading,
        isError,
        error,
        transactionTypes,
        isLoadingTransactionTypes,
        retryGetFeeTransaction,
        updateFeeTransaction
    } = useEditFeeTransaction(feeTransactionId, currentSearchType);

    useEffect(() => console.log(feeTransactionId), [feeTransactionId])
    //useEffect(() => console.log(group), [group])
    //useEffect(() => console.log(currentSearchType), [currentSearchType])

    if(!feeTransactionId) return null;

    return <TransactionFeeModal
        group={group}
        show={show}
        handleClose={handleClose}
        onSubmitFn={updateFeeTransaction}
        onSubmitLabel={"Update Fee"}
        defaultAllocationsError={error}
        isDefaultAllocationsError={isError}
        feeTransactionDefaultValues={feeTransactionDefaultValues}
        feeTransactionAllocationDefaultValues={feeTransactionAllocationDefaultValues}
        transactionTypes={transactionTypes}
        isLoadingTransactionTypes={isLoadingTransactionTypes}
        isLoadingDefaultAllocations={isLoading}
        retryFetchDefaultAllocations={retryGetFeeTransaction}
        //currentSearchType={currentSearchType}
    />;

};

export default EditTransactionFeeModalWrapper;