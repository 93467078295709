import { useLazyGetProductByIdQuery, useLazySearchProductsQuery } from "../../../services/products";
import FormSelectPaged from "../FormSelectPaged";

const ProductSelect = ({
    label,
    value,
    setValue,
    ...rest
}) => {

    return <FormSelectPaged
        label={label}
        value={value}
        setValue={setValue}
        query={useLazySearchProductsQuery}
        additional={{ fetchType: "list", page: 1, limit: 25 }}
        onOptionsLoaded={(_, results) => results}
        fetchQuery={useLazyGetProductByIdQuery}
        onStartFetch={(value) => ({ productId: value })}
        onFetched={(obj) => ({ value: obj.productId, label: obj.productName })}
        {...rest}
    />
}

export default ProductSelect;