import { useEffect, useMemo } from "react";
import { components } from "react-select";
import styled from "styled-components";
import { DateDisplay } from "../../../components";
import { FormSelect } from "../../../components/forms";
import { useFetchAllClientValuationBuildsQuery } from "../../../services/valuations";

export const ValuationBuildStatuses = {
    Running: 0,
    Finished: 1,
    Aborted: 2
};

const BuildOption = styled(components.Option)`
    font-size: 1.125em !important;
`

const BuildSelectOption = styled(BuildOption)`
    /* Bold any of the user's builds */
    ${({ data: { data } }) => data.isCreator && 'font-weight: bold;'}

    /* Style any purged builds appropriately */
    ${({ data: { data } }) => data.purgedDate != null && `
        background-color: var(--bs-danger) !important;
        backdrop-filter: blur(2px);
        color: white !important;
        opacity: 0.75;
    `}

    /* Ensure the 'muted' text is readable if the option is selected/purged */
    & span.text-muted {
        ${({ isSelected, data: { data } }) => (isSelected || data.purgedDate != null) && `
            color: var(--bs-gray-400) !important;
        `}
    }
`;

const BuildCreateOption = styled(BuildOption)`
    /* Style as a button */
    background-color: ${({ isDisabled }) => isDisabled ? 'var(--bs-danger)' : 'var(--bs-success)'} !important;
    color: white !important;
    border-radius: 0.25rem 0.25rem 0 0;
    padding: 0.5rem 1rem;
    cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'pointer'} !important;
    ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'}
`;

const DateTimeDisplay = (props) => <DateDisplay format="HH:mm DD/MM/YY" {...props} />

const ValuationBuildSelectOption = (props) => {
    const { data: { data } } = props;
    const { valuationCount, buildStartDate, buildEndDate, createdBy, statusText, purgedDate, isPersistent } = data || {};

    const statusInformation = useMemo(() => {
        if (buildEndDate == null)
            return statusText;

        if (isPersistent)
            return "Saved";

        if (purgedDate != null)
            return <>Purged: <DateTimeDisplay>{purgedDate}</DateTimeDisplay></>

        return "Temporary";
    }, [buildEndDate, isPersistent, purgedDate, statusText]);

    if (data == null)
        return <BuildCreateOption {...props} />;

    return <BuildSelectOption {...props}>
        <span className="d-flex justify-content-between small text-muted">
            <span>
                {buildEndDate == null
                    ? <>Started by {createdBy} @ <DateDisplay format="HH:mm DD/MM/YY">{buildStartDate}</DateDisplay></>
                    : <>Built by {createdBy} @ <DateDisplay format="HH:mm DD/MM/YY">{buildEndDate}</DateDisplay></>}
            </span>
            <span>
                {statusInformation}
            </span>
        </span>
        {valuationCount != null && <span className="small">
            ({valuationCount} Accounts)
        </span>}
    </BuildSelectOption>
}

const ValuationBuildSelect = ({
    valuationDate,
    disabled,
    defaultMostRecent = false,
    buildId,
    onSelectBuild,
    showCreateBuild = true,
    ...rest
}) => {
    const { options, isFetching: isFetchingBuilds, error } = useFetchAllClientValuationBuildsQuery({ valuationDate },
        {
            // Map the results so they include the create build/build running option
            selectFromResult: (res) => {
                const { currentData, ...rest } = res;
                if (currentData == null)
                    return res;

                const { builds, canRun } = currentData || { builds: [], canRun: false };

                let options = [...builds];

                if (showCreateBuild) {
                    // Add a 'Create New Build' option dependent on the response from the server
                    const createBuildOption = canRun
                        ? { label: "Create New Build", value: -1 }
                        : { label: "Build In Progress...", isDisabled: true };

                    options = [createBuildOption, ...options];
                }

                return { options, ...rest };
            }
        });

    // Set the build ID to the most recent build (if present) once the options are loaded
    useEffect(() => {
        if (options == null || options.length === 0) return;

        if (!defaultMostRecent)
            return;

        // If the value is already set to something in our options list, don't override it
        if (buildId != null && options.some(opt => opt.value === buildId))
            return;

        const validOptions = options.filter(opt => !opt.isDisabled && opt.value !== -1);

        // If there are no valid options, don't set a value
        if (validOptions.length === 0) {
            onSelectBuild(null);
            return;
        }

        const mostRecent = validOptions[0];

        onSelectBuild(mostRecent);
    }, [options]);

    return <FormSelect
        {...rest}
        defaultValue={buildId}
        options={options}
        isLoadingOptions={isFetchingBuilds}
        disabled={disabled || isFetchingBuilds}
        components={{
            ...components,
            Option: ValuationBuildSelectOption
        }}
        onChange={onSelectBuild}
        errorMessage={error?.data}
    />;
}

export default ValuationBuildSelect;