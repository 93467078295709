import React from "react";
import Skeleton from "react-loading-skeleton";
import { DateDisplay } from "../../../components";
import {
    ErrorMessage,
    ErrorMessageWrapper,
} from "../../../components/messages";
import useClientIntroducer from "../hooks/useClientIntroducer";
import {
    CurrencyInput,
    FormLabel,
    FormSelect,
    PercentageInput
} from "./../../../components/forms/";
import CenterFormColumnComponent from "./CenterFormColumnComponent";
import ServiceAgreementsWrapper from "./ServiceAgreementsWrapper";

const IntroducerAgreement = ({ masterAccountId, loadingLines = 2 }) => {

    const {
        introducers,
        clientIntroducerAgreement,
        isLoading,
        isError,
        error,
        reload,
        updateClientIntroducerAgreement,
        updateError,
        isErrorUpdating,
        isUpdating,
        createClientIntroducerAgreement,
        createError,
        isErrorCreating,
        isCreating
    } = useClientIntroducer(masterAccountId);

    const feeTypes = [
        { label: "Fixed", value: 0 },
        { label: "Percentage", value: 1 },
        { label: "No Fee", value: 2 }
    ];

    const handleRetry = (e) => {
        e.preventDefault();
        reload(e);
    };

    const onFieldChanged = (property, value) => updateClientIntroducerAgreement(clientIntroducerAgreement.clientIntroducerAgreementId, property, value);

    const onCreatePressed = (e) => createClientIntroducerAgreement(e);

    
    return (
        <ServiceAgreementsWrapper label="Introducer Agreement">

            {isError &&
                <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
                    <ErrorMessage error={error} retryCallback={handleRetry} />
                </ErrorMessageWrapper>
            }
            {(!isError && !isLoading && !isCreating && Object.keys(clientIntroducerAgreement).length === 0) && (
                <div className="row mb-2">
                    <div className="col">
                        There is no active introducer agreements available. You can click{" "}
                            <a href="#" onClick={onCreatePressed} >
                                here
                            </a>{" "}
                        to create one.
                    </div>
                </div>
            )}
        {(isLoading === true || isCreating === true) && <Skeleton count={loadingLines} />}
        {(!isLoading && Object.keys(clientIntroducerAgreement).length > 0)  &&
            <div>
                <div className="row mb-2">
                    <div className="col-6">
                        <FormSelect
                            label="Introducer"
                            options={introducers}
                            isMulti={false}
                            defaultValue={
                                clientIntroducerAgreement.introducerId
                            }
                            onBlur={(selection) =>
                                onFieldChanged("introducerId", selection.value)
                            }
                        />
                    </div>
                    <div className="col-4">
                        <FormSelect
                            label="Status"
                            options={[
                                { label: "Approved", value: 0 },
                                { label: "Pending", value: 1 },
                                { label: "Rejected", value: 2 },
                            ]}
                            isMulti={false}
                            defaultValue={
                                clientIntroducerAgreement.introducerStatus
                            }
                            onBlur={(selection) =>
                                onFieldChanged(
                                    "introducerStatus",
                                    selection.value
                                )
                            }
                        />
                    </div>
                    {clientIntroducerAgreement.statusUpdatedBy != null && clientIntroducerAgreement.statusUpdatedDate != null &&
                        <CenterFormColumnComponent className="col-md-2">
                            <FormLabel>
                                 by {clientIntroducerAgreement.statusUpdatedBy}
                                
                                <DateDisplay
                                    className="ps-1"
                                    format="DD/MM/YYYY h:mm a"
                                >
                                    {clientIntroducerAgreement.statusUpdatedDate}
                                </DateDisplay>

                            </FormLabel>
                        </CenterFormColumnComponent>
                    }
                </div>
                <div className="row mb-2">
                    <div className="col-2">
                        <FormSelect
                            label="Finders Fee"
                            options={feeTypes}
                            isMulti={false}
                            defaultValue={
                                clientIntroducerAgreement.findersFeeType
                            }
                            onBlur={(selection) =>
                                onFieldChanged(
                                    "findersFeeType",
                                    selection.value
                                )
                            }
                        />
                    </div>
                    <CenterFormColumnComponent className="col-2">
                       {clientIntroducerAgreement.findersFeeType == 1 ? (
                            <PercentageInput
                                value={
                                    clientIntroducerAgreement?.findersFeeRate ??
                                    0
                                }
                                onBlur={(value) =>
                                    onFieldChanged("findersFeeRate", value)
                                }
                                disabled={clientIntroducerAgreement.findersFeeType == 2}
                            />
                        ) : (
                            <CurrencyInput
                                value={
                                    clientIntroducerAgreement?.findersFeeRate ??
                                    0
                                }
                                onBlur={(value) =>
                                    onFieldChanged("findersFeeRate", value)
                                }
                                disabled={clientIntroducerAgreement.findersFeeType == 2}
                            />
                        )}
                    </CenterFormColumnComponent>
                    <div className="col-2">
                        <FormSelect
                            label="Advice Fee"
                            options={feeTypes}
                            isMulti={false}
                            defaultValue={
                                clientIntroducerAgreement.adviceFeeType
                            }
                            onBlur={(selection) =>
                                onFieldChanged("adviceFeeType", selection.value)
                            }
                        />
                    </div>
                    <CenterFormColumnComponent className="col-2">
                        {clientIntroducerAgreement.adviceFeeType == 1 ? (
                           <PercentageInput
                                value={
                                    clientIntroducerAgreement?.adviceFeeRate ??
                                    0
                                }
                                onBlur={(value) =>
                                    onFieldChanged("adviceFeeRate", value)
                                }
                                disabled={clientIntroducerAgreement.adviceFeeType == 2}
                            />
                        ) : (
                            <CurrencyInput
                                value={
                                    clientIntroducerAgreement?.adviceFeeRate ??
                                    0
                                }
                                onBlur={(value) =>
                                    onFieldChanged("adviceFeeRate", value)
                                }
                                disabled={clientIntroducerAgreement.adviceFeeType == 2}
                            />
                        )}
                    </CenterFormColumnComponent>
                    <div className="col-2">
                        <FormSelect
                            label="Service Fee"
                            options={feeTypes}
                            isMulti={false}
                            defaultValue={
                                clientIntroducerAgreement.serviceFeeType
                            }
                            onBlur={(selection) =>
                                onFieldChanged(
                                    "serviceFeeType",
                                    selection.value
                                )
                            }
                        />
                    </div>
                    <CenterFormColumnComponent className="col-2">
                        {clientIntroducerAgreement.serviceFeeType == 1 ? (
                            <PercentageInput
                                value={
                                    clientIntroducerAgreement?.serviceFeeRate ??
                                    0
                                }
                                onBlur={(value) =>
                                    onFieldChanged("serviceFeeRate", value)
                                }
                                disabled={clientIntroducerAgreement.serviceFeeType == 2}
                            />
                        ) : (
                            <CurrencyInput
                                value={
                                    clientIntroducerAgreement?.serviceFeeRate ??
                                    0
                                }
                                onBlur={(value) =>
                                    onFieldChanged("serviceFeeRate", value)
                                }
                                disabled={clientIntroducerAgreement.serviceFeeType == 2}
                            />
                        )}
                    </CenterFormColumnComponent>
                </div>
            </div>}
        </ServiceAgreementsWrapper>
    );
};

export default IntroducerAgreement;
