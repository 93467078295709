import { useSearchDebtorTotalsQuery } from "../../../services/headofficeadmin";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useAdministrationFeesProvider } from "../../../hooks/AdministrationFeesContext";
//import { FilterProvider, DebtorManagementDefaultFilters } from "../../../hooks/FilterContext";
import { useEffect } from "react";

const useDebtorTotals = (searchType) => {

    const { filters, currentSearchType } = useAdministrationFeesProvider();
    //setFilters(DebtorManagementDefaultFilters);
    //const { filters } = useAdministrationFeesProvider();
    //console.log('filtersNow', filters);

    const { data, isLoading, isFetching, isError, error, refetch } = useSearchDebtorTotalsQuery({
        searchType:currentSearchType,
        filter:{
        ...filters,
        adviserIds: filters.advisers?.map(x => x.value) ?? [],
        masterAccountIds: filters.masterAccounts?.map(x => x.value) ?? [],
        providerIds: filters.providers?.map(x => x.value) ?? [],
    }});

    const retryGetDebtorTotals = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }
        refetch();
    }

    return {
        data,
        isFetching,
        isLoading,
        isError,
        error,
        retryGetDebtorTotals
    }
};
export default useDebtorTotals;