import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from "dompurify";
import React, { useCallback, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import AutoSizer from "react-virtualized-auto-sizer";
import styled from "styled-components";
import { Button, DateDisplay } from "../../../components";
import { FormLabel } from "../../../components/forms";
import { ClientProjectSelect } from "../../../components/forms/selects";
import { Spinner } from "../../../components/loaders";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { Main, ThemedSpan } from "../../../components/utilities";
import { useClientContext } from "../../../hooks/ClientContext";
import { MailSendType } from "../../../hooks/PersonalOutlookContext";
import CreateMailModal from "../../createmailmodal";
import { useClientEmails } from "../hooks/ClientEmailsContext";
import useEmail from "../hooks/useFiledEmail";
import Attachment from "./ClientEmailAttachment";

const ClientEmailContainer = styled.div`
    overflow-y: auto;
`;


const ClientEmail = ({ showProjectSelect = true }) => {
    const { id: masterAccountId } = useClientContext();
    const { mailId } = useClientEmails();
    const [emailModalProps, setEmailModalProps] = useState({ masterAccountId });
    const [showEmailModal, setShowEmailModalProps] = useState(false);
    const { data, isFetching, error, isError, reload, updateProject } = useEmail(mailId);
    const { user: { profile } } = useAuth();
    const { email } = profile || {};

    const {
        subject,
        senderEmailAddress,
        projectId,
        attachments,
        sentDate,
        recipientEmails,
        graphId,
        body
    } = data || {};


    const mapRecipientToSelectOption = useCallback((x) => ({ label: x, value: x }), []);

    const onCloseEmailModal = () => {
        setEmailModalProps({ masterAccountId });
        setShowEmailModalProps(false);
    }

    const onForwardClick = () => {
        setEmailModalProps({
            sendType: MailSendType.Forward,
            parentMessageId: graphId,
            masterAccountId
        });
        setShowEmailModalProps(true);
    };

    const onReplyClick = () => {
        setEmailModalProps({
            sendType: MailSendType.Reply,
            initialToRecipients: [mapRecipientToSelectOption(senderEmailAddress)],
            parentMessageId: graphId,
            masterAccountId
        });
        setShowEmailModalProps(true);
    };

    const onReplyAllClick = () => {
        let initialFrom = mapRecipientToSelectOption(senderEmailAddress);
        let initialToRecipients = recipientEmails.map(mapRecipientToSelectOption).filter(x => x.value !== email);

        if (initialFrom.value !== email) {
            initialToRecipients.push(initialFrom);
        }

        setEmailModalProps({
            initialToRecipients,
            sendType: MailSendType.ReplyAll,
            parentMessageId: graphId,
            masterAccountId
        });
        setShowEmailModalProps(true);
    };

    if (isFetching) {
        return <Main className="d-flex justify-content-center align-items-center h-75">
            <Spinner />
        </Main>
    }

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={reload} />
        </ErrorMessageWrapper>
    }

    return <React.Fragment>
        <CreateMailModal
            show={showEmailModal}
            onHide={onCloseEmailModal}
            {...emailModalProps}
        />
        <AutoSizer>
            {({ height, width }) => (
                <ClientEmailContainer className="p-2" style={{ height, width }}>
                    <div className="d-flex flex-row justify-content-between mb-2">
                        <h4>{subject}</h4>
                        {showProjectSelect &&
                            <>
                                <FormLabel horizontal className="ms-auto pe-3 text-end flex-fill">Associate with Project:</FormLabel>
                                <ClientProjectSelect
                                    className="col-4"
                                    value={projectId}
                                    setValue={(selection) => updateProject(selection?.value)}
                                    isClearable
                                />
                            </>
                        }
                    </div>

                    <div className="row mx-0">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                                <div className="d-flex flex-column">
                                    <ThemedSpan><b>{senderEmailAddress}</b></ThemedSpan>
                                    <ThemedSpan>To: {recipientEmails.join(", ")}</ThemedSpan>
                                </div>
                            </div>

                            {<div className="d-flex flex-column">
                                <ButtonGroup>
                                    <Button className="border" onClick={onReplyClick}>
                                        <FontAwesomeIcon icon={"fa-reply"} className="me-2" />
                                        Reply
                                    </Button>
                                    <Button className="border" onClick={onReplyAllClick}>
                                        <FontAwesomeIcon icon={"fa-reply-all"} className="me-2" />
                                        Reply All
                                    </Button>
                                    <Button className="border" onClick={onForwardClick}>
                                        <FontAwesomeIcon icon={"fa-share"} className="me-2" />
                                        Forward
                                    </Button>
                                </ButtonGroup>

                                <ThemedSpan className="text-end"><DateDisplay format="ddd Do MMM YYYY HH:mm" >{sentDate}</DateDisplay></ThemedSpan>
                            </div>}
                        </div>
                    </div>
                    <hr className="mt-2" />
                    <div
                        id="email-body"
                        className="row mx-0"
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
                    />
                    {attachments && attachments.length > 0 && <div className="border-top py-2">
                        <ThemedSpan>{attachments.length} attached files.</ThemedSpan>
                        <div className="d-flex mt-2">
                            {attachments.map(file => <Attachment key={file.fileName} attachment={file} />)}
                        </div>
                    </div>
                    }
                </ClientEmailContainer>
            )}
        </AutoSizer>
    </React.Fragment>
};

export default ClientEmail;