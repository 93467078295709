import React from "react";
import DateDisplay from "../../DateDisplay";
import FormSelect from "../FormSelect";
import styled from "styled-components";
import { components } from "react-select";
import { useFetchMasterAccountContactDetailsEmailListQuery } from "../../../services/clientdetails";

const MasterAccountEmailSelectOptionHeaderRow = styled.div``;

const MasterAccountEmailSelectOptionDetailsRow = styled.div`
    font-size: 0.875em;
    color: ${(props) => props.theme.muted};
`;

const MasterAccountEmailSelectOption = (props) => {
    const { data: { data }, label } = props;

    if (!data || props['__isNew__'] === true) {
        return (
            <components.Option {...props}><span>{label}</span></components.Option>
        )
    }

    const { createdOnDateUtc, description, details } = data || {};
    return (
        <components.Option {...props}>
            <div>
                <MasterAccountEmailSelectOptionHeaderRow>
                    {description}
                </MasterAccountEmailSelectOptionHeaderRow>
                <MasterAccountEmailSelectOptionDetailsRow className="row text-muted">
                    <div className="col">
                        <span>{details}</span>
                    </div>
                    <div className="col-3">
                        <span>Created on </span>
                        <DateDisplay format="Do MMMM YYYY">{createdOnDateUtc}</DateDisplay>
                    </div>
                </MasterAccountEmailSelectOptionDetailsRow>
            </div>
        </components.Option>
    )
};

const MasterAccountEmailSelectValue = (props) => {
    const { data: { data, label }} = props;

    if (!data) {
        return (
            <components.SingleValue {...props}>
                <span>{label}</span>
            </components.SingleValue>
        )
    }

    if (data.emailAddress) {
        const { emailAddress } = data || {};
        return (
            <components.SingleValue {...props}>
                <span>{emailAddress}</span>
            </components.SingleValue>
        )
    }

    const { details } = data || {};
    return (
        <components.SingleValue {...props}>
            <span>{details}</span>
        </components.SingleValue>
    )
};

const MasterAccountEmailSelect = ({ options = [], masterAccountId, ...rest }) => {
    const { data, error, isError, isLoading, isFetching } = useFetchMasterAccountContactDetailsEmailListQuery({ masterAccountId, fetchType: "List" });
    const apiOptions = data || [];

    return (
        <FormSelect 
            options={[...options, ...apiOptions]}
            isCreateable={true}
            isLoadingOptions={isLoading || isFetching}
            components={{ 
                Option: MasterAccountEmailSelectOption, 
                SingleValue: MasterAccountEmailSelectValue 
            }}
            {...rest}
        />
    );
};

export default MasterAccountEmailSelect;