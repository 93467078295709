import _ from "lodash";
import { wealthProApi } from "../../redux/api";
import { useCacheKeySerializeQueryArgsHandler, useCacheKeyTransformResponseHandler } from "../../redux/helpers";

const allClientValuationsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["all-client-valuations-builds"]
}).injectEndpoints({
    endpoints: (builder) => ({
        fetchAllClientValuationBuilds: builder.query({
            query: ({ valuationDate }) => `api/valuations/allclientvaluations/${valuationDate}`,
            providesTags: (_res, _err, { valuationDate }) => [{ type: "all-client-valuations-builds", id: valuationDate }]
        }),
        fetchAllClientValuationsTotal: builder.query({
            query: ({ buildId, ...body }) => ({
                url: `api/valuations/allclientvaluations/${buildId}/total`,
                method: "POST",
                body
            })
        }),
        searchAllClientValuations: builder.query({
            query: ({ buildId, ...body }) => ({
                url: `api/valuations/allclientvaluations/${buildId}/search`,
                method: "POST",
                body
            }),
            transformResponse: useCacheKeyTransformResponseHandler,
            serializeQueryArgs: useCacheKeySerializeQueryArgsHandler,
            // Need to re-implement the merge handler to get the total value to map across properly
            merge: (currentCache, newItems) => {
                if (_.isEqual(currentCache.cacheKey, newItems.cacheKey)) {
                    currentCache.pagination = newItems.pagination;
                    currentCache.results.push(...newItems.results);
                    // totalValue only calculated on first page load, so ignore it when cacheKey is unchanged
                }
                else {
                    currentCache.newRecordsSinceCache = 0;
                    currentCache.cacheKey = newItems.cacheKey;
                    currentCache.pagination = newItems.pagination;
                    currentCache.results = [...newItems.results];
                    currentCache.totalValue = newItems.totalValue;
                }
            }
        }),
        buildAllClientValuations: builder.mutation({
            query: ({ valuationDate }) => ({
                url: `api/valuations/allclientvaluations/${valuationDate}`,
                method: "POST"
            }),
            async onQueryStarted({ valuationDate }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(wealthProApi.util.updateQueryData("fetchAllClientValuationBuilds", { valuationDate }, (draft) => ({
                        ...draft,
                        canRun: false
                    })));
                } catch (error) {
                    console.error("Error creating build", error);
                }
            }
        }),
        persistAllClientValuationsBuild: builder.mutation({
            query: ({ buildId }) => ({
                url: `api/valuations/allclientvaluations/${buildId}/persist`,
                method: "POST"
            }),
            invalidatesTags: [{ type: "all-client-valuations-builds" }]
        }),
        exportAllClientValuationsBuild: builder.query({
            query: ({ buildId, csv = false, ...body }) => ({
                url: `api/valuations/allclientvaluations/${buildId}/export?csv=${csv}`,
                method: "POST",
                body,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const disposition = response.headers.get('content-disposition');
                    let filename = "AllClientValuationsExport.xls";

                    const fileNameStarRegex = /(?<=filename\*=UTF-8'')([^;]+)/i;
                    const matchesStar = disposition.match(fileNameStarRegex);
                    
                    if (matchesStar && matchesStar[1]) {
                        filename = decodeURIComponent(matchesStar[1].trim());
                    } else {
                        console.error("Cannot extract filename");
                    }

                    return { blob, filename };
                },
                cache: 'no-cache'
            })
        }),
    }),
    overrideExisting: false
});

export const {
    useLazyFetchAllClientValuationBuildsQuery,
    useFetchAllClientValuationBuildsQuery,
    useLazyFetchAllClientValuationsTotalQuery,
    useFetchAllClientValuationsTotalQuery,
    useLazySearchAllClientValuationsQuery,
    useBuildAllClientValuationsMutation,
    usePersistAllClientValuationsBuildMutation,
    useLazyExportAllClientValuationsBuildQuery
} = allClientValuationsApiEndpoints;