import moment from "moment";
import { DateDisplay } from "../../../components";
import { NumericFormat } from "react-number-format";
import AssetPricesTableRowActionCol from "./AssetPricesTableRowActionCol";
import { usePricesManagement } from "../hooks/PricesManagementContext";
import Skeleton from "react-loading-skeleton";

const AssetPricesTableRow = ({ data, index, style: { height, width } }) => {

    const { columns, deletePrice, setPriceToEdit } = usePricesManagement();
    const price = data[index];

    const {
        priceDate,
        assetId,
        midPrice,
        bidPrice,
        offerPrice
    } = price || {};


    if (!price) {
        return <tr style={{height, width}}>
            <td colSpan={columns.length + 1}>
                <Skeleton height={height - 4}/>
            </td>
        </tr>
    }

    return (
        <tr style={{height, width}}>
            <td>{moment(priceDate).isValid ? <DateDisplay>{priceDate}</DateDisplay> : <span className="px-2">-</span>}</td>
            <td><NumericFormat displayType="text" value={bidPrice} /></td>
            <td><NumericFormat displayType="text" value={midPrice} /></td>
            <td><NumericFormat displayType="text" value={offerPrice} /></td>
            <td align="right">
                <AssetPricesTableRowActionCol
                    editPrice={() => setPriceToEdit(priceDate)}
                    deletePrice={() => deletePrice(priceDate)}
                />
            </td>
        </tr>
    )
};

export default AssetPricesTableRow;