import React, { useState, useMemo, useEffect, useCallback } from "react";
import { patchReplace } from "../../helpers/patchDoc";
import { ControllerFetchType } from "../../helpers/fetchTypes";
import { Form } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import FormInputGroup from "./FormInputGroup";
import FormLabel from "./FormLabel";
import debounce from 'lodash.debounce';
import FormGroup from "./FormGroup";
import { useId } from "react";

const PagedSelect = ({
    className,
    label,
    value,
    defaultValue,
    horizontal,
    id,
    patchMutationFn,
    patchParameters,
    propName,
    fetchOptionsFn,
    pageQueryParams,
    pageQueryFn,
    startingPage = 1,
    pageLimit = 5,
    setSelectFieldValue,
    active = true,
    searchTimeout = 1000,
    ...rest
}) => {

    const defaultComponentId = useId();
    const componentId = id || defaultComponentId;
    const [page, setPage] = useState(startingPage);
    const [limit, setLimit] = useState(pageLimit);
    const fetchType = ControllerFetchType.List;

    const [pagedState, setPagedState] = useState([]);
    const [paginationState, setPaginationState] = useState();

    const styles = {
        container: base => ({
            ...base,
            flex: 1,
        })
    };

    useEffect(() => {
        pageQueryFn({ page, limit, fetchType, ...pageQueryParams })
            .then((res) => {
                const { data, isLoading, isError } = res;
                const { pagination, results } = data || {};

                if (results) {
                    setPaginationState(pagination);
                    setPagedState(prev => {
                        var newSet = new Set([...[...prev, ...results]]);
                        return [...newSet];
                    });
                }
            });

    }, [page, limit, setPaginationState, setPagedState]);

    const getDefaultValue = () => {
        if (pagedState === []) return;
        let def = pagedState.find(({ value: v }) => v === defaultValue);
        return def;

    }

    const loadNextPage = () => {
        if (page < paginationState.totalPages) {
            setPage(page => page + 1);
        }
    }

    const searchForOptions = React.useCallback(
        debounce((searchTerm, callback) => {
            fetchOptionsFn({ searchTerm, fetchType: ControllerFetchType.List }, false).then(response => {
                console.log(response)
                callback(response.data)
            })
        }, searchTimeout),
        []
    );

    const onBlurEvent = () => {
        if (currentValue?.value === originalValue?.value) {
            return;
        }

        if (
            !patchMutationFn ||
            !patchParameters ||
            !propName ||
            typeof patchMutationFn !== "function"
        ) {
            return;
        }

        // attach select value and label to params so can used if needed in
        // optimistic updates
        patchParameters.value = currentValue.value;
        patchParameters.label = currentValue.label;

        var patchDoc = patchReplace(propName, currentValue.value);

        patchMutationFn({ ...patchParameters, operations: [patchDoc] });

    };

    const onChangeEvent = (e) => {
        if (setSelectFieldValue && typeof (setSelectFieldValue) === 'function') {
            setSelectFieldValue(e);
        }
        setCurrentValue(e);
    }

    useEffect(() => {
        if (defaultValue && defaultValue !== currentValue) {
            setCurrentValue(getDefaultValue());
            setOriginalValue(getDefaultValue())
        }
    }, [defaultValue]);

    const [currentValue, setCurrentValue] = useState(getDefaultValue());
    const [originalValue, setOriginalValue] = useState(getDefaultValue());



    return <FormGroup className={className ?? ''} horizontal={horizontal}>
        {label && <FormLabel htmlFor={componentId} horizontal={horizontal}>{label}</FormLabel>}
        <FormInputGroup horizontal={horizontal} hasLabel={label ? true : false}>
            <AsyncSelect
                id={componentId}
                styles={styles}
                classNamePrefix="Select"
                menuPosition="fixed"
                isDisabled={!active}
                isLoading={!pagedState}
                isSearchable={true}
                defaultOptions={pagedState}
                loadOptions={searchForOptions}
                onMenuScrollToBottom={loadNextPage}
                onChange={onChangeEvent}
                onBlur={onBlurEvent}
                value={currentValue ?? getDefaultValue()}
                {...rest}
            />
        </FormInputGroup>

    </FormGroup>;
};


export default PagedSelect;