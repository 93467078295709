import React from "react";
import toast, { ToastOptions } from "react-hot-toast";
import { useAuth } from "react-oidc-context";
import { BasicToast } from "../components/toasts";
import { socket } from "../redux/socket";
import { toNString } from "../helpers/guids";

type ToastSocketMessageOptions = ToastOptions & {
    action?: string | undefined,
    actionLabel?: string | undefined,
    isCustom: boolean,
    title?: string | undefined,
};

type ToastSocketMessage = {
    message: string,
    options: ToastSocketMessageOptions | undefined,
    userId: string | null
};

type ToastSocketContextProps = { };
const ToastSocketContext = React.createContext<ToastSocketContextProps>({ });

export const useToastSocket = () => React.useContext(ToastSocketContext);
export const ToastSocketProvider = ({ children } : Readonly<{ children: React.ReactNode }>) : React.ReactNode => {
    const { user } = useAuth();
    const currentUserId = user?.profile ? toNString(user.profile.sub) : null;
    
    const onToastReceived = (model: string) => {
        const notification : ToastSocketMessage = JSON.parse(model);
        if (notification.options && notification.options?.isCustom === true) {
            toast.custom((t) => (
                <BasicToast 
                    action={notification.options!.action}
                    actionLabel={notification.options!.actionLabel}
                    control={t}
                    icon={notification.options!.icon}
                    title={notification.options!.title}
                    message={notification.message}
                    isDismissable={false}
                />
            ), notification.options);
            return;
        }
        toast(notification.message, notification.options);
    }

    React.useEffect(() => {
        if (!socket || !currentUserId) {
            console.warn('unable to connect to the toast socket as either the socket is not connected or the user could not be identified.');
            return;
        }

        socket.emit('subscribe-toast', { room: currentUserId });
        socket.on('toast.notify', onToastReceived);

        return (() => {
            socket.emit('unsubscribe-toast', { room: currentUserId });
            socket.off('toast.notify');
        });
    }, [ socket, currentUserId ]);

    return (
        <ToastSocketContext.Provider value={{ }}>
            {children}
        </ToastSocketContext.Provider>
    );
};