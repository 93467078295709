import React, {useState} from "react";
import { DateDisplay } from "../../../components";
import { FormLabel, FormTextArea } from "../../../components/forms";
import styled from "styled-components";
import {IconButton} from "../../../components";
import { AttachmentsToggle, CenterFormColumnComponent } from "../../../components/utilities";
import DrawdownStatusSelect from "./DrawdownStatusSelect";
import { FileUploadModal } from "../../../components/modals";
import useClientReview from "../hooks/useClientReview";
import toast from "react-hot-toast";
import TargetDate from "./TargetDate";

const Drawdown = ({review, onFieldChanged, amberWarning = 5 }) => {

    const { reviewId } = review;

    const { 
        uploadDrawDownEvidence,
        isUploadingDocument
    } = useClientReview(reviewId);

    const [showFileUpload, setShowFileUpload] = useState(false);

    
    const onDrawdownEvidenceUploaded  = (files) => toast.promise(uploadDrawDownEvidence(files), {
        loading: "Uploading evidence...",
        error: "Unable to upload evidence",
        success: "Evidence uploaded."
    });


    return (
        <React.Fragment>
            <FileUploadModal
                show={showFileUpload}
                handleClose={() => setShowFileUpload(false)}
                title="Upload Evidence"
                handleConfirm={onDrawdownEvidenceUploaded}
                isUploadingFile={isUploadingDocument}
            />
            <div className="row mt-4">
                    <div className="col-2">
                       <DrawdownStatusSelect  
                            label="Drawdown Status"
                            defaultValue={review.drawdownStatus}
                            onBlur={(selection) =>
                                onFieldChanged(
                                    "drawdownStatus",
                                    selection.value
                                )
                            }
                       />
                    </div>

                    <div className="col">
                        <FormTextArea
                            label="Comment"
                            value={review.drawdownStatusComment}
                            onBlur={(value) =>
                                onFieldChanged(
                                    "drawdownStatusComment",
                                    value
                                )
                            }
                            disabled={review.drawdownVerifiedDate != null}
                        />
                    </div>

                    <div className="col-2 align-items-end">
                        <FormLabel className="col">Drawdown Evidence</FormLabel>
                        <AttachmentsToggle 
                            isCreatable
                            menuDirection="down"
                            attachments={review.drawdownEvidenceDocuments}
                            toggleVariant="success"
                            toggleType="button"
                            onUploadFn={() => setShowFileUpload(true)}
                            disabled={review.drawdownVerifiedDate != null}
                        >
                            <span>Upload Evidence</span>
                        </AttachmentsToggle>
                       
                    </div>
                    <div className="col-md-auto d-flex align-items-end">
                        <CenterFormColumnComponent>
                            <IconButton 
                                className="col-auto"
                                icon={review.preReviewDocumentRequired ? "fa-x" : "fa-check"}
                                variant={review.preReviewDocumentRequired ? "danger" : "success"}
                                onClick={(_) =>
                                    onFieldChanged(
                                        "preReviewDocumentRequired",
                                        !review.preReviewDocumentRequired
                                    )
                                }
                            />
                        </CenterFormColumnComponent>
                    </div>
                </div>
            <div className="row">
                <div className="col-6">
                    {review.drawdownVerifiedByUser && review.drawdownVerifiedDate && (
                        <FormLabel>
                            Drawdown verified by {review.drawdownVerifiedByUser} on
                            <DateDisplay
                                className="ps-1"
                                format="DD/MM/YYYY HH:mm"
                            >
                                {review.drawdownVerifiedDate}
                            </DateDisplay>
                        </FormLabel>
                    )}
                </div>
                <div className="col-6 d-flex justify-content-end">
                    {review.drawdownVerificationTargetDate && (
                       <TargetDate date={review.drawdownVerifiedDate} targetDate={review.drawdownVerificationTargetDate}/>                        
                    )}
                </div>
            </div>
        </React.Fragment>
    )

}

export default Drawdown;