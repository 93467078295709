import { useEffect, useState } from "react";
import { useCreateReportMutation } from "../../../services/clientreports";

const useCreateReport = (masterAccountId = null, projectId = null, authorId = null, reviewId = null) => {
    const [create, createResults] = useCreateReportMutation();
    const [defaultValues, setDefaultValues] = useState({
        masterAccountId,
        projectId,
        authorId: authorId,
        reportName: '', 
        typeId: null,
        templateId: null,
        deadlineDate: null,
        estimatedMinutesToComplete: 0,
        generateFeedbackTemplate: true,
        categories: [],
        productAreas: [],
        reviewId: reviewId
    });

    const handleCreate = (data) => {
        return new Promise((resolve, reject) => {
            return create({ data, masterAccountId }).unwrap().then(
                r => resolve(r),
                e => reject(e)
            );
        });
    };

    useEffect(() => {
        setDefaultValues((current) => { return { ...current, masterAccountId, projectId, authorId, reviewId } });
        console.log("Project", projectId);
        console.log("Review", reviewId);
    }, [masterAccountId, projectId, authorId, reviewId]);

    return {
        createResults,
        defaultValues,
        handleCreate
    };
};

export default useCreateReport;