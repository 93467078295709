import { wealthProApi } from '../../redux/api';

const hoAdminDebtorsApiEndpoints = wealthProApi.enhanceEndpoints({
    addTagTypes: ["applicationDebtors"]
}).injectEndpoints({
    endpoints: (builder) => ({
        agedDebtors: builder.query({
            //query: ({ data }) => ({
            query: (data) => ({
                url: `api/headofficeadmin/debtors/ageddebtors`,
                method: "POST",
                body: data
            }),
        }),
        agedDebtorsDetail: builder.query({
            query: ({ data }) => ({
            //query: (data) => ({
                url: `api/headofficeadmin/debtors/ageddebtorsdetail`,
                method: "POST",
                body: data
            }),
        }),
        searchDebtors: builder.query({
            query: ({filter}) => ({
                //url: `api/headofficeadmin/debtors/searchdebtors`,
                url: `api/headofficeadmin/debtors/search`,
                method: "POST",
                body: filter
            }),
            serializeQueryArgs: ({ endpointName, queryArgs }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                const { pagination: { page: currPage } } = currentCache || {};
                const { pagination: { page: nextPage }, hasMore } = newItems || {};

                if (!currPage || nextPage <= currPage) {
                    return newItems;
                }

                currentCache.hasMore = newItems.hasMore;
                currentCache.pagination = newItems.pagination;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { pagination: { page: currPage }, userId: currUserId } = currentArg || { pagination: { page: 1 }, userId: null };
                const { pagination: { page: prevPage }, userId: prevUserId } = previousArg || { pagination: { page: 0 }, userId: null };
                return currPage !== prevPage || currUserId !== prevUserId;
            },
        }),
        searchDebtorTotals: builder.query({
            query: ({filter}) => ({
                url: `api/headofficeadmin/debtors/search/totals`,
                method: 'POST',
                body: filter
            }),
            providesTags: ['applicationDebtors']
        })
    }),   
});

export const {
    useLazyAgedDebtorsQuery,
    useLazyAgedDebtorsDetailQuery,
    useLazySearchDebtorsQuery,
    useSearchDebtorTotalsQuery,
    useLazySearchDebtorTotalsQuery
} = hoAdminDebtorsApiEndpoints;