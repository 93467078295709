import { useEffect } from "react";
import useMessageList from "../hooks/useMessageList";
import { useRef } from "react";
import React from "react";
import Skeleton from "react-loading-skeleton";
import MessageBox from "./MessageBox";
import ReadOnlyMessage from "./ReadOnlyMessage";
import DraftMessage from "./DraftMessage";
import useMessage from "../hooks/useMessage";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { VariableSizeList } from "react-window";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { useClientMessaging } from "../hooks/ClientMessagingContext";

const LoadingMessage = React.forwardRef(({ style }, ref) => {
    return <div style={style} className="px-3 pt-3">
        <MessageBox ref={ref} className="rounded">
            <div className="d-flex justify-content-between mb-3">
                <Skeleton height={25} width={175} />
                <Skeleton height={25} width={75} />
            </div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </MessageBox>
    </div>;
});

const MessageRow = ({ data: { results, setRowHeight }, index, style }) => {

    const rowRef = useRef();

    var message = results[index];

    const {
        messageId
    } = message || {};

    const { isReplying, reply, toggleDone } = useMessage(messageId);
    const { threadId, navigateToThread } = useClientMessaging();

    const onDeleteCallbackFn = () => {
        // if the only message was deleted
        if (results.length <= 1 && threadId) {
            navigateToThread(null);
        }
    };;

    useEffect(() => {
        if (rowRef.current && setRowHeight) {
            setRowHeight(index, rowRef.current.clientHeight);
        }
    }, [rowRef.current, results]);

    if (!message) {
        return <LoadingMessage ref={rowRef} index={index} style={style} />
    }

    if (!message.sentOnDate) {
        return <div style={style} className="px-3 pt-3">
            <DraftMessage
                ref={rowRef}
                message={message}
                onDeleteCallbackFn={() => onDeleteCallbackFn()}
            />
        </div>
    }

    return (
        <div style={style} className="px-3 pt-3">
            <ReadOnlyMessage
                ref={rowRef}
                isReplying={isReplying}
                message={message}
                onReply={() => reply(message)}
                onDone={toggleDone}
            />
        </div>
    );
};

const MessageList = ({ fetchHook, queryParams }) => {

    const listRef = useRef();
    const rowHeights = useRef({});

    const { results, error, isError, isClearing, totalCount, isItemLoaded, loadMoreItems, retry } = useMessageList(fetchHook, queryParams);

    const getRowHeight = (index) => {
        return (rowHeights.current[index] || 150) + 24;
    };

    const setRowHeight = (index, size) => {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    };

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={retry} />
        </ErrorMessageWrapper>
    }

    return (
        <AutoSizer>
            {({ height, width }) => (
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={totalCount}
                    loadMoreItems={loadMoreItems}
                >
                    {({ onItemsRendered, ref }) => (
                        <VariableSizeList
                            ref={(e) => {
                                listRef.current = e;
                                ref(e);
                            }}
                            onItemsRendered={onItemsRendered}
                            height={height}
                            width={width}
                            itemCount={totalCount}
                            itemData={{ results: isClearing ? [null, null, null] : results, setRowHeight }}
                            itemSize={getRowHeight}
                        >
                            {MessageRow}
                        </VariableSizeList>
                    )}
                </InfiniteLoader>
            )}
        </AutoSizer>
    );
};

export default MessageList;