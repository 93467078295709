import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DateDisplay, ProfileDisplay } from "../../../components";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";
import { ThemedSpan } from "../../../components/utilities";
import { useFilterContext } from "../../../hooks/FilterContext";
import useOpenDocument from "../../../hooks/useOpenDocument";
import { useLazySearchProjectsQuery } from "../../../services/clientprojectsummary";

// Helper function to format cells
const formatCurrency = (value) => value?.toLocaleString("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 });
const formatDate = (value) => <DateDisplay>{value}</DateDisplay>;
const formatUser = (value) => <ProfileDisplay userId={value} />;

const ProjectManagementNavigationCell = ({ value, row, destination = null, getDestination }) => {
    return <a href={destination ?? getDestination(row)}>{value}</a>
}

const ProjectManagementSplitCell = ({ value, row, dateProperty, statusProperty = null, isClickable = false, getDestination = null, documentIdProperty = null }) => {
    const status = useMemo(() => (statusProperty && row[statusProperty])
        ? row[statusProperty]
        : null, [row, statusProperty]);
    const date = useMemo(() => row[dateProperty]
        ? <DateDisplay format="Do MMM 'YY">{row[dateProperty]}</DateDisplay>
        : null, [row, dateProperty]);
    const separator = status && date ? " - " : "";

    const { openDocument, isFetching: isOpening } = useOpenDocument();
    const handleOpenDocument = () => openDocument(row[documentIdProperty]);

    return value == null
        ? "N/A"
        : <p className="m-0 lh-1 text-truncate">
            <span>{isClickable && (typeof getDestination === "function" || (documentIdProperty != null && row[documentIdProperty] != null))
                ? typeof getDestination === "function"
                    ? <a href={getDestination(row)}>{value}</a>
                    : <button className="anchor" disabled={isOpening} onClick={handleOpenDocument}>{value}</button>
                : value}</span>
            <br />
            <ThemedSpan variant="muted" className="fs-smallest">
                {status}{separator}{date}
            </ThemedSpan>
        </p>;
}

const limit = 50;
const defaultResponse = { pagination: { page: 1, pageSize: limit, totalCount: 0, totalPages: 1 }, results: [], feePotential: null };

export const useProjectManagement = ({ setFeePotential }) => {
    const {
        filter,
        isReady,
    } = useFilterContext();

    // Search handling inc. filtering, sorting, and pagination
    const [cacheKey, setCacheKey] = useState(new Date().valueOf());
    const [searchTrigger, { data, isError, error, isFetching }] = useLazySearchProjectsQuery();
    const { pagination, results, feePotential } = (!isError && data) || defaultResponse
    const { totalCount, page, totalPages } = pagination;
    const [sort, setSort] = useState(null);
    const [sortProperty, direction] = useMemo(() => sort?.split("+") ?? [], [sort]);

    const [isClearing, setIsClearing] = useState(false);

    const clear = useCallback(() => new Promise((res, rej) => {
        if (isFetching)
            return;

        setIsClearing(true)

        const newCacheKey = new Date().valueOf();
        searchTrigger({ page: 1, limit, sort, cacheKey: newCacheKey, ...filter })
            .unwrap()
            .then(() => {
                setCacheKey(newCacheKey);
            })
            .finally(() => {
                setIsClearing(false);
            })
            .then(res, rej);
    }), [filter, isFetching, searchTrigger, sort]);

    const isRowLoaded = (index) => !(totalCount > 0 && page < totalPages) || index < results.length;

    const loadMore = () => {
        if (isFetching) return;

        searchTrigger({ cacheKey, page: page + 1, limit, sort, ...filter });
    };

    const onClickSort = useCallback((property) => {
        let newDirection = "";

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    }, [direction, sortProperty]);

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }

        clear();
    };

    useEffect(() => {
        // Don't fetch more if we're already fetching
        if (isFetching || !isReady)
            return;

        clear();
        // Disabled eslint warning because we only want to run this effect when the filter or sort changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, sort, isReady]);

    const sortableHeaderRenderer = useCallback(({ label, property, key, headerClassName }) => {
        return <StandardTableSortableHeader
            className={headerClassName}
            key={key}
            onClick={() => onClickSort(property)}
            active={property === sortProperty}
            direction={direction}
            isSticky>
            {label}
        </StandardTableSortableHeader>;
    }, [direction, onClickSort, sortProperty]);

    // Update layout fee potential with amount returned from full query (or null to show spinner)
    useEffect(() => {
        if (isClearing)
            setFeePotential(null);
        else
            setFeePotential(formatCurrency(feePotential));
    }, [feePotential, isClearing, setFeePotential]);

    const columns = [
        {
            label: "Master Account",
            property: "accountName",
            headerRenderer: sortableHeaderRenderer,
            CellComponent: ProjectManagementNavigationCell,
            getDestination: ({ masterAccountId }) => `/client/${masterAccountId}`,
            width: 10
        },
        {
            label: "Description",
            property: "description",
            headerRenderer: sortableHeaderRenderer,
            CellComponent: ProjectManagementSplitCell,
            dateProperty: "createdDate",
            isClickable: true,
            getDestination: ({ masterAccountId, projectId }) => `/client/${masterAccountId}/projects?projectId=${projectId}`,
            width: 12.5
        },
        {
            label: "Comment",
            property: "comment",
            headerRenderer: sortableHeaderRenderer,
            width: 12.5
        },
        {
            label: "Value",
            property: "projectValue",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: formatCurrency,
            className: "text-end",
            headerClassName: "text-center",
            width: 5
        },
        {
            label: "Status",
            property: "statusDescription",
            width: 6.5
        },
        {
            label: "Target Date",
            property: "targetDate",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: formatDate,
            width: 6
        },
        {
            label: "Advice Type",
            property: "adviceTypeDescription",
            width: 8
        },
        {
            label: "Adviser",
            property: "adviserId",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: formatUser,
            width: 10
        },
        {
            label: "Author",
            property: "authorId",
            headerRenderer: sortableHeaderRenderer,
            cellFormatter: formatUser,
            width: 10
        },
        {
            label: "Report Status",
            property: "reportStatusDescription",
            CellComponent: ProjectManagementSplitCell,
            dateProperty: "reportStatusDate",
            isClickable: true,
            documentIdProperty: "reportDocumentId",
            width: 9.5
        },
        {
            label: "Campaign",
            property: "campaignDescription",
            headerRenderer: sortableHeaderRenderer,
            CellComponent: ProjectManagementSplitCell,
            dateProperty: "campaignStatusDate",
            statusProperty: "campaignStatusDescription",
            width: 10
        }
    ];

    return {
        results: (isClearing || isError) ? [] : results,
        sort,
        totalCount: (isClearing || isError) ? 0 : totalCount,
        columns,
        isLoading: isClearing,
        isError,
        error,
        clear,
        isRowLoaded,
        loadMore,
        sortableHeaderRenderer,
        reload
    };
};

export default useProjectManagement;