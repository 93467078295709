import React, {useState} from "react";
import styled from "styled-components";
import withProviders from "../hooks/withProviders";
import { AdministrationFeeRouteTabs } from "../routes/administration.fees.routes";
import { FilterProvider, FeeManagementDefaultFilters, DebtorsManagementDefaultFilters } from "../hooks/FilterContext";
import { Outlet } from "react-router-dom";
import { AgGridMain, ThemedParagraph } from "../components/utilities";
import PageHeader, { PageHeaderTop, PageHeaderBottom } from "../components/layout/PageHeader";
import FeeTotals from "../features/headofficefeemanagement/components/FeeTotals";
import DebtorTotals from "../features/headofficedebtormanagement/components/DebtorTotals";
import { AdministrationFeesProvider, useAdministrationFeesProvider } from "../hooks/AdministrationFeesContext";

const FeeManagementHeaderTitle = styled.div`
    flex: 1;
    display: flex;
    font-size: 16px;

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

const FeeManagementHeaderBottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;



const AdministrationFeesLayout = () => {
    const {currentSearchType, setTabIndex} = useAdministrationFeesProvider();
    return <React.Fragment>
        <PageHeader>
            <PageHeaderTop>
                <FeeManagementHeaderTitle>
                    <span>Fees Administration</span>
                </FeeManagementHeaderTitle>
            </PageHeaderTop>
            <FeeManagementHeaderBottom>
                <AdministrationFeeRouteTabs onTabChanged={(e, i) => setTabIndex(_ => i)} /> 
                {/*{(currentSearchType !== null && currentSearchType !== 2 && currentSearchType !==3)  && (<FeeTotals currentSearchType={currentSearchType}/>)}
                {(currentSearchType !== null && currentSearchType !== 0 && currentSearchType !==1 && currentSearchType !==3)  && (<DebtorTotals/>)}*/}
                {(currentSearchType !== null && currentSearchType !== "debtors" && currentSearchType !=="ageddebtors")  && (<FeeTotals currentSearchType={currentSearchType}/>)}
                {(currentSearchType !== null && currentSearchType !== "fees" && currentSearchType !== "cash" && currentSearchType !== "ageddebtors")  && (<DebtorTotals currentSearchType={currentSearchType}/>)}
            </FeeManagementHeaderBottom>
        </PageHeader>
        <AgGridMain>
            <Outlet/>
        </AgGridMain>
    </React.Fragment>
};

export default withProviders([AdministrationFeesProvider])(AdministrationFeesLayout);