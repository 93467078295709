import React, { useState } from "react";
import { useTheme } from "styled-components";
import { DateDisplay } from "../../../components";
import { ConfirmModal } from "../../../components/modals";
import { ValuationDatePicker } from "./ValuationBuildPicker";

const ValuationDateConfirmModal = ({ show, setShow, defaultValue, build }) => {
    const theme = useTheme();

    const [selectedDate, setSelectedDate] = useState(defaultValue);

    const onConfirm = () => {
        build(selectedDate);
        setShow(false);
    }

    const onCancel = () => {
        setShow(false);
    }

    return <ConfirmModal
        theme={theme}
        show={show}
        size="md"
        title="Confirm Valuation Date"
        confirmButtonLabel="Start Build"
        handleConfirm={onConfirm}
        handleClose={onCancel}
    >
        <p>Are you sure you want to start a valuation build for <DateDisplay format="Do MMMM YYYY">{selectedDate}</DateDisplay>? Select a different date below to change.</p>

        <div className="row-cols-3">
            <ValuationDatePicker
                value={selectedDate}
                setValue={setSelectedDate}
            />
        </div>
    </ConfirmModal>
}

export default ValuationDateConfirmModal;