import React from "react";
import withProviders from "../../hooks/withProviders";
import PageHeader, { PageHeaderTop } from "../../components/layout/PageHeader";
import { PageHeaderTitle } from "../../components/utilities/PageHeader";
import { FiltersAppliedWarningMessage, Main, PageSubHeader, ThemedIcon } from "../../components/utilities";
import { FilterProvider, AdvicePendingDefaultFilters, useFilterContext } from "../../hooks/FilterContext";
import { PendingApproval, AdviceGridDataProvider } from "../../features/advicependingapproval";
import { AdvicePendingFiltersModal, AdvicePendingFiltersModalButton } from "../../features/advicependingapprovalfilters";

const AdvicePendingApprovalPage = ({ title = "Advice Pending Approval" }) => {
    const [isFiltersShown, setIsFiltersShown] = React.useState(false);
    const { clearFilter, isFiltersApplied } = useFilterContext();
    return (
        <React.Fragment>
            <AdvicePendingFiltersModal
                handleClose={() => setIsFiltersShown(false)}
                show={isFiltersShown}
            />
            <PageHeader>
                <PageHeaderTop>
                    <PageHeaderTitle>
                        <span>{title}</span>
                    </PageHeaderTitle>
                    
                </PageHeaderTop>
            </PageHeader>
            <Main className="d-flex flex-column">
                <PageSubHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            {isFiltersApplied && (
                                <React.Fragment>
                                    <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                    <FiltersAppliedWarningMessage className="ms-2 text-muted">
                                        Warning - Filters are currently being applied to your results.
                                        <br />
                                        <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                    </FiltersAppliedWarningMessage>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="d-flex flex-fill justify-content-end align-items-center">
                            <AdvicePendingFiltersModalButton onClick={() => setIsFiltersShown(true)} />
                        </div>
                    </div>
                </PageSubHeader>
                <section className="px-4 my-3 h-100">
                    <PendingApproval />
                </section>
            </Main>
        </React.Fragment>
    );
}

export default withProviders(
    [FilterProvider, { filterStore: "hoadvice", defaultFilters: AdvicePendingDefaultFilters }],
    [AdviceGridDataProvider]
)(AdvicePendingApprovalPage);