import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useLazySearchAllClientValuationsQuery } from "../../../services/valuations";
import useAcvExcelExport from "./useAcvExcelExport";

export const AssetViewTypes = {
    FundsSummary: 0,
    ClientsSummary: 1,
    HoldingsSummary: 2
}

const AssetsUnderAdviceContext = React.createContext();

export const useAssetsUnderAdvice = () => React.useContext(AssetsUnderAdviceContext);

export const AssetsUnderAdviceProvider = ({ children }) => {
    const [viewType, setViewType] = useState(AssetViewTypes.FundsSummary);

    const sessionBuildId = sessionStorage.getItem("valuationBuildId");
    const [buildId, setBuildId] = useState(sessionBuildId);

    const sessionValuationDate = sessionStorage.getItem("valuationDate");
    const [valuationDate, setValuationDate] = useState(sessionValuationDate ?? moment().format("YYYY-MM-DD"));

    // Update session storage with the current build ID and valuation date
    useEffect(() => {
        if (sessionBuildId !== buildId && buildId != null)
            sessionStorage.setItem("valuationBuildId", buildId);
    }, [buildId]);

    useEffect(() => {
        if (sessionValuationDate !== valuationDate && valuationDate != null)
            sessionStorage.setItem("valuationDate", valuationDate);
    }, [valuationDate])

    const {
        filter,
        clearFilter,
        showFilterModal,
        setShowFilterModal,
        isReady,
        isFiltersApplied
    } = useFilterContext();

    const [cacheKey, setCacheKey] = useState(new Date().valueOf());

    // Only used in fund summary view, determines if assets are grouped to core funds or shown at share class level
    const [group, setGroup] = useState(true);
    const requestGroup = useMemo(() => viewType === AssetViewTypes.FundsSummary && group, [viewType, group]);

    const flipGroup = useCallback(() => setGroup(prev => !prev), []);

    const [search, { currentData, isError, error, isFetching }] = useLazySearchAllClientValuationsQuery();
    const queryInProgress = useRef(null);
    const { results, pagination, totalValue } = currentData || { results: [], pagination: { totalCount: 100, page: 1, pageSize: 50, totalPages: null }, totalValue: 0 };
    const { page, pageSize: limit, totalCount } = pagination;

    const [sort, setSort] = useState(null);
    const [sortProperty, direction] = useMemo(() => sort?.split("+") || [], [sort]);

    const [isClearing, setIsClearing] = useState(false);

    const clear = () =>
        new Promise((res, rej) => {
            if (buildId == null || !isReady)
                return;

            setIsClearing(true);

            // Remove objects from filters
            const { assets, productProviders, ...rest } = filter;

            const newCacheKey = new Date().valueOf();

            const request = search({
                cacheKey: newCacheKey,
                buildId,
                viewType,
                page: 1,
                limit: viewType === AssetViewTypes.HoldingsSummary ? 500 : limit,
                sort,
                filter: rest,
                group: requestGroup
            });

            queryInProgress.current = request;

            return request.unwrap()
                .then(_ => setCacheKey(newCacheKey))
                .then(res, rej)
                .finally(() => setIsClearing(false));
        });

    const isRowLoaded = (index) => (index < results.length);

    const loadMore = () => {
        if (isClearing || !isReady)
            return;

        return search({
            cacheKey,
            buildId,
            viewType,
            page: page + 1,
            limit,
            sort,
            filter,
            group
        })
    };

    const onClickSort = useCallback((property) => {
        let newDirection = "";

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            // Remove sorting if already descending
            if (direction === "DESC") {
                setSort(null);
                return;
            }

            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    }, [direction, sortProperty])

    const reload = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        clear();
    }

    useEffect(() => {
        // Bit of a workaround to ensure the existing query is cancelled so the data shown is for the most recent args
        if (queryInProgress.current != null && typeof queryInProgress.current.abort === "function") {
            queryInProgress.current.abort();
            setTimeout(() => clear(), 0);
        } else {
            clear();
        }

    }, [buildId, filter, group, sort, isReady, viewType]);

    // Reset sort between tabs
    useEffect(() => {
        setSort(null);
    }, [viewType]);

    const { exportExcel } = useAcvExcelExport({ isFetching, isError, viewType, buildId, group: requestGroup, filter });

    return <AssetsUnderAdviceContext.Provider value={{
        viewType,
        setViewType,
        valuationDate,
        setValuationDate,
        buildId,
        setBuildId,
        group,
        requestGroup,
        flipGroup,
        filter,
        isFiltersApplied,
        clearFilter,
        showFilterModal,
        setShowFilterModal,
        results: isClearing || isError ? [] : results,
        totalValue,
        sort,
        onClickSort,
        totalCount: isError || isClearing
            ? 0
            : totalCount,
        isFetching,
        isClearing,
        isError,
        error,
        clear,
        isRowLoaded,
        loadMore,
        reload,
        exportExcel
    }}>
        {children}
    </AssetsUnderAdviceContext.Provider>
}