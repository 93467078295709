import { NumericFormat } from "react-number-format";
import { CenterArea, ContentWrapper, Main, ThemedSpan } from "../../components/utilities";
import useFeeTotals from "./hooks/useFeeTotals";
import Skeleton from "react-loading-skeleton";
import { ErrorMessage, ErrorMessageWrapper } from "../../components/messages";
import FeeTotalsHeader from "./components/FeeTotalsHeader";
import TransactionFeeAllocations from "./components/TransactionFeeAllocations";
import { useState } from "react";
import CreateTransactionFeeModalWrapper from "./components/CreateTransactionFeeModalWrapper";
import EditTransactionFeeModalWrapper from "./components/EditTransactionFeeModalWrapper";
import CreateFeeGroupModal from "./components/CreateFeeGroupingModal";
import { Spinner } from "../../components/loaders";

const AdministrationFees = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
    const [feeTransactionId, setFeeTransactionId] = useState(null);

    const { data, isLoading, isError, error, retryFetchFeeTotals } = useFeeTotals();

    const handleModalStateChange = (e, setter, newValue) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        setter(newValue);
    }

    if (isLoading) {
        return <CenterArea>
            <Spinner/>
        </CenterArea>
    }

    if (isError && error.originalStatus === 404) {
        return <Main className="p-3">
            <ThemedSpan>
                No fee groupings exist for this application.
                Click <a href="#" onClick={(e) => handleModalStateChange(e, setShowCreateGroupModal, true)}>here</a> to create one.
            </ThemedSpan>
            <CreateFeeGroupModal show={showCreateGroupModal} handleClose={(e) => handleModalStateChange(e, setShowCreateGroupModal, false)} />
        </Main>
    }

    if (isError) {

        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={retryFetchFeeTotals} />
        </ErrorMessageWrapper>
    }


    return <Main className="p-3">
        <ContentWrapper label={"Advice Fees"}>

            <FeeTotalsHeader feeTotals={data} />

            <TransactionFeeAllocations
                groupId={data.groupId}
                openCreateModal={(e) => handleModalStateChange(e, setShowCreateModal, true)}
                openEditModal={(e, feeTransactionId) => handleModalStateChange(e, setFeeTransactionId, feeTransactionId)}
            />

            <CreateTransactionFeeModalWrapper
                group={data}
                show={showCreateModal}
                handleClose={(e) => handleModalStateChange(e, setShowCreateModal, false)}
            />

            <EditTransactionFeeModalWrapper
                group={data}
                show={feeTransactionId !== null}
                feeTransactionId={feeTransactionId}
                handleClose={(e) => handleModalStateChange(e, setFeeTransactionId, null)}
            />
        </ContentWrapper>
    </Main>;
};

export default AdministrationFees;