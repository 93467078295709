import classNames from "classnames";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { IconButton, DateDisplay, ProfileDisplay } from "../../components";
import { StandardTable } from "../../components/tables";
import EventsTableActionColumn from "./components/EventsTableActionColumn";
import { EmptyRowComponent } from "../../components/tables/EditableTable";
import { AutoFitCell, ThemedTableHeaderActionColumn } from "../../components/tables/StandardTable";
import { MessagePriorities } from "../../helpers/constants";
import EventsTableRow from "./components/EventsTableRow";
import React from "react";
import { CreateReminderModal } from "../createreminderform";
import EditReminderModal from "../reminders/components/EditReminderModal";
import EventsTableEmptyRow from "./components/EventsTableEmptyRow";

const EventsTableProjectCol = ({ project, projectId, masterAccountId }) => {
    const navigate = useNavigate();

    const handleOpenProject = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        navigate(`/client/${masterAccountId}/projects?projectId=${projectId}`, { relative: 'route' });
    };

    return (
        <AutoFitCell>
            <button className="anchor" onClick={handleOpenProject}>{project}</button>
        </AutoFitCell>
    );
}

const EventsColumns = [
    {
        label: "Project",
        CellComponent: EventsTableProjectCol
    },
    {
        label: "Date",
        property: 'deadlineDate',
        valueFormatter: (value) => {
            const date = moment(value);

            return <DateDisplay format={date.hours() === 0 && date.minutes() === 0 ? "DD/MM/YYYY" : "HH:mm DD/MM/YYYY"}>{date}</DateDisplay>;
        },
        fitContent: true
    },
    {
        label: "Type",
        property: 'type',
        fitContent: true
    },
    {
        label: "Description",
        property: 'description',
        fitContent: true,
        maxWidth: "20%"
    },
    {
        label: "Notes",
        property: 'notes'
    },
    {
        label: "Created By",
        property: 'userId',
        valueFormatter: (value) => value == null ? "Unknown" : <ProfileDisplay userId={value} />,
        fitContent: true
    },
    {
        label: "Priority",
        property: 'priority',
        valueFormatter: (value) => {
            const priorityClassName = {
                'bg-priority-high text-priority-high': value === MessagePriorities.High,
                'bg-priority-medium text-priority-medium': value === MessagePriorities.Medium,
                'bg-priority-low text-priority-low': value === MessagePriorities.Low
            };

            return <div className={classNames("badge py-1 px-2 mx-1", priorityClassName)}>
                {{
                    [MessagePriorities.Low]: "Low",
                    [MessagePriorities.Medium]: "Medium",
                    [MessagePriorities.High]: "High"
                }[value]}
            </div>;
        },
        fitContent: true
    },
    // {
    //     label: "Status",
    //     property: 'status',
    //     valueFormatter: (value) => {
    //         const className = [{
    //             'bg-cancelled text-cancelled': value === ReminderStatuses.Cancelled,
    //             'bg-todo text-todo': value === ReminderStatuses.Active,
    //         }];
    //         return <span className={classNames("badge py-1 px-2 min-w-2/3", className)}>
    //             {{
    //                 [ReminderStatuses.Cancelled]: "Cancelled",
    //                 [ReminderStatuses.Active]: "Active",
    //             }[value]}
    //         </span>;
    //     },
    //     fitContent: true
    // },
    {
        CellComponent: EventsTableActionColumn,
        headerRenderer: ({ openCreate }) => <ThemedTableHeaderActionColumn>
            <IconButton
                buttonSize="sm"
                icon="fa-plus"
                onClick={openCreate}
                variant="success"
            />
        </ThemedTableHeaderActionColumn>,
        fitContent: true,
        openCreate: () => { }
    }
];

const ClientEventsTable = ({
    masterAccountId,
    projectId = null,
    showProject = false,
    patchEvent,
    ...tableProps
}) => {
    const [showCreateModal, setShowCreateModal] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState(null);

    const showEditModal = React.useMemo(() => selectedEvent != null, [selectedEvent]);

    const columns = showProject
        ? EventsColumns
        : EventsColumns.slice(1);
    
    columns[columns.length - 1].openCreate = () => setShowCreateModal(true);

    return <React.Fragment>
        <CreateReminderModal
            show={showCreateModal}
            showUserSelect={true}
            masterAccountId={masterAccountId}
            projectId={projectId}
            onHide={() => setShowCreateModal(false)}
        />
        <EditReminderModal
            show={showEditModal}
            onHide={() => setSelectedEvent(null)}
            reminder={selectedEvent}
        />
        <StandardTable
            {...tableProps}
            columns={columns}
            isTableResponsive={false}
            EmptyRowComponent={EventsTableEmptyRow}
            emptyRowComponentProps={{ openCreate: () => setShowCreateModal(true), columns }}
            hideHeaderOnEmpty
            RowComponent={EventsTableRow}
            rowComponentProps={{ setSelectedEvent, patchEvent }}
        />
    </React.Fragment>
}

export default ClientEventsTable