import { useMemo } from "react";
import { DateDisplay } from "../../../components";
import { useAuth } from "react-oidc-context";
import { useClientContext } from "../../../hooks/ClientContext";
import { useArchiveFactFindMutation, useFetchFactFindArchiveStatusQuery } from "../../../services/clientfactfind";

const useArchiveFactFind = () => {
    const { id: masterAccountId } = useClientContext();
    const { data: archiveStatus, isFetching, error: fetchStatusError, refetch } = useFetchFactFindArchiveStatusQuery({ masterAccountId });
    const [archive, { isLoading: isArchiving, error: archiveError }] = useArchiveFactFindMutation();

    const archived = useMemo(() => archiveStatus != null, [archiveStatus])

    const archivedInfo = useMemo(() => {
        if (archiveStatus != null) {
            return <span>Archived on <DateDisplay format="DD/MM/YYYY @ HH:mm">{archiveStatus.archivedDate}</DateDisplay> by {archiveStatus.archivedByName}</span>;
        }

        return "Never Archived";
    }, [archiveStatus]);

    const error = useMemo(() => {
        if (fetchStatusError)
            return { data: "Could not fetch archive status", allowRetry: true, isFetchingError: true }

        if (archiveError) {
            if (archiveError.status === 403) // Unauthorized
                return { data: "You do not have permission to archive fact finds.", allowRetry: false, isFetchingError: false };

            return { data: "Could not archive fact find", allowRetry: true, isFetchingError: false };
        }

        return null;
    }, [archiveError, fetchStatusError]);

    const { user: { profile: { roles } } } = useAuth();
    const canArchive = roles?.includes("ff_archive") || roles?.includes("sys_admin_access");

    const archiveFactFind = () => archive({ masterAccountId })
        .unwrap();

    return {
        canArchive,
        archiveFactFind,
        isFetching,
        isArchiving,
        error,
        archived,
        archivedInfo,
        refetch
    };
}

export default useArchiveFactFind;