import classNames from "classnames";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { DateDisplay } from "../../../components";
import { MessagePriorities, ReminderStatuses } from "../../../helpers/constants";
import usePersonalReminderRow from "../hooks/usePersonalReminderRow";
import PersonalRemindersVirtualTableRowActionCol from "./PersonalRemindersVirtualTableRowActionCol";
import { usePersonalReminders } from "../hooks/PersonalRemindersContext";

const TableCell = styled.td`
    width: ${props => props.width}%;
    max-width: 50px;
    vertical-align: middle;
`;

const ReminderPriorityCell = ({ value, isInfoOnly }) => {

    if (!value || isInfoOnly) return <span className="py-1 px-2">-</span>

    const className = [{
        'bg-priority-high text-priority-high': value === MessagePriorities.High,
        'bg-priority-medium text-priority-medium': value === MessagePriorities.Medium,
        'bg-priority-low text-priority-low': value === MessagePriorities.Low
    }];

    return <span className={classNames("badge py-1 px-2", className)}>
        {{
            [MessagePriorities.Low]: "Low",
            [MessagePriorities.Medium]: "Medium",
            [MessagePriorities.High]: "High"
        }[value]}
    </span>;
};

const ReminderDeadlineDateCell = ({ deadlineDate }) => {

    var date = moment(deadlineDate);
    let format = "HH:mm DD/MM/YYYY";

    if (date.hours() === 0 && date.minutes() === 0) {
        format = "DD/MM/YYYY";
    }

    return <DateDisplay format={format}>{date}</DateDisplay>;
};

const ReminderStatusCell = ({ status }) => {
    if (typeof (status) !== "number") return <span className="py-1 px-2">-</span>

    const className = [{
        'bg-cancelled text-cancelled': status === ReminderStatuses.Cancelled,
        'bg-todo text-todo': status === ReminderStatuses.Active,
        // 'bg-info-only text-info-only': status === ReminderStatuses.InfoOnly,
        // 'bg-complete text-complete': status === ReminderStatuses.Complete,
    }];

    return <span className={classNames("badge py-1 px-2 min-w-2/3", className)}>
        {{
            [ReminderStatuses.Cancelled]: `Cancelled`,
            //[ReminderStatuses.Complete]: `Completed`,
            //[ReminderStatuses.InfoOnly]: "Info-only",
            [ReminderStatuses.Active]: "Active",
        }[status]}
    </span>;
};

const PersonalReminderTableRow = ({ style: { height, width }, index, data }) => {

    const { isClearing } = usePersonalReminders();

    const reminder = data[index];

    const {
        description,
        priority,
        status,
        statusUpdatedBy,
        id,
        type,
        userName,
        notes,
        deadlineDate,
        masterAccountName
    } = reminder || {};

    const { patchReminder } = usePersonalReminderRow(id);

    if (!reminder || isClearing) {
        return <tr style={{ height, width }}>
            <td colSpan={9}>
                <Skeleton height={32} />
            </td>
        </tr>
    }

    return <tr style={{ height, width }}>
        <TableCell width={8.5} className="text-truncate">
            <ReminderDeadlineDateCell deadlineDate={deadlineDate} />
        </TableCell>
        <TableCell width={12} className="text-truncate">
            {type}
        </TableCell>
        <TableCell width={12.5} className="text-truncate">
            {masterAccountName ?? <span className="py-1 px-2">-</span>}
        </TableCell>
        <TableCell width={15} className="text-truncate">
            {description}
        </TableCell>
        <TableCell width={30} className="text-truncate">
            {notes}
        </TableCell>
        <TableCell width={7.5} className="text-truncate">
            {userName}
        </TableCell>
        <TableCell width={6} className="align-middle text-center">
            <ReminderStatusCell status={status} statusUpdatedBy={statusUpdatedBy} />
        </TableCell>
        <TableCell width={6} className="align-middle text-center">
            <ReminderPriorityCell value={priority} />
        </TableCell>
        <TableCell width={2.5}>
            <PersonalRemindersVirtualTableRowActionCol
                data={reminder}
                updateReminder={patchReminder}
            />
        </TableCell>
    </tr>

};

export default PersonalReminderTableRow;