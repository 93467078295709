import { useEffect, useReducer } from "react";
import { useDeleteShareClassMappingMutation, useFetchShareClassMappingsQuery } from "../../../services/assets";
import { useAuth } from "react-oidc-context";

const shareClassRowReducer = (state, action) => {
    switch (action.type) {
        case 'init':
            return action.payload;
        case 'add-draft':
            return [...state, { assetGroupId: action.payload }];
        case 'remove':
            return state.filter((_, index) => index !== action.payload);
        case 'update':
            return state.map((row, index) => index === action.payload.index ? { ...row, ...action.payload.row } : row);
        default:
            return state;
    }
}

const useShareClassMappings = (assetGroupId) => {
    const auth = useAuth();
    const canEditDetails = ["ic_details_write", "sys_admin_access"].some(role => auth.user.profile.roles.includes(role));

    const [shareClassRows, dispatch] = useReducer(shareClassRowReducer)
    const { currentData: shareClasses, isFetching: isFetchingShareClasses } = useFetchShareClassMappingsQuery({ assetGroupId });

    useEffect(() => {
        dispatch({ type: 'init', payload: shareClasses })
    }, [shareClasses]);

    const createMappingDraft = () => dispatch({ type: 'add-draft', payload: assetGroupId });

    const updateMapping = (index, row) => dispatch({ type: 'update', payload: { index, row } });

    const [deleteMappingTrigger] = useDeleteShareClassMappingMutation();

    const deleteRow = (index) => {
        const row = shareClassRows[index];
        if (row?.assetId != null) {
            deleteMappingTrigger({ assetGroupId, assetId: row.assetId, index }).unwrap()
                .then(() => dispatch({ type: 'remove', payload: index }));
        } else
            dispatch({ type: 'remove', payload: index });
    }

    return [{ createMappingDraft, updateMapping, deleteRow }, { shareClassRows, isFetchingShareClasses, canEditDetails }, {}];
}

export default useShareClassMappings;