import _ from "lodash";
import React, { useContext, useState } from "react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

export type FilterContextProps = {
    clearFilter: (e: any, filters: any) => void,
    defaultFilters: any,
    filter: any,
    isFiltersApplied: boolean,
    isReady: boolean,
    setFilter: (prev: any) => void,
    setNewFilter: (data: { newFilter: any }) => void,
    showFilterModal: boolean,
    setShowFilterModal: (prev: boolean) => void
};

const FilterContext = React.createContext<FilterContextProps>({ 
    clearFilter: () => { },
    defaultFilters: { },
    filter: { },
    isFiltersApplied: false,
    isReady: false,
    setFilter: () => { },
    setNewFilter: () => { },
    showFilterModal: false,
    setShowFilterModal: () => { }
});

export const useFilterContext = () => useContext(FilterContext);

export const convertSearchParamsToFilter = (searchParams : URLSearchParams) : any => {
    let obj = { };
    for (const key of searchParams.keys()) {
        const entries = searchParams.getAll(key);
        obj[key] = entries.length === 1 ? entries[0] : entries;
    }
    return obj;
}

export const FilterProvider = ({ 
    children, 
    filterStore = null, 
    defaultFilters = {} 
} : Readonly<{ children: React.ReactNode, filterStore: string | null, defaultFilters: any }>) : React.ReactNode => {
    const [filter, setFilter] = useState<any>(defaultFilters);                      // The current state of the filters
    const [hasFilteredOnSearch, setHasFilteredOnSearch] = useState<boolean>(false); // Checks if we have already applied the filters and basically don't do it again when we navigate back
    const [isReady, setIsReady] = useState<boolean>(false);                         // Allows the calling component to wait for session storage to be read before fetching with the filters
    const [showFilterModal, setShowFilterModal] = useState<boolean>(false);         // Allows showing and controlling of the filter modal. TODO: change this to new confirmable approach
    const [searchParams, setSearchParams] = useSearchParams();                      // Query Parameters to check for our dynamic filters to apply

    const changeFilter = () : void => {
        setIsReady(_ => false); // might as-well reset this..

        // try and pull the value out of session storage if we have applied the "store"
        const sessionFiltersString = filterStore ? sessionStorage.getItem(`filters.${filterStore}`) : null;
        const sessionFilters = sessionFiltersString != null ? JSON.parse(sessionFiltersString) : defaultFilters;

        // check if we have parameter filters that we want to override the defaults with
        const searchParamFilters = !hasFilteredOnSearch && searchParams && searchParams.size > 0 ? convertSearchParamsToFilter(searchParams) : {};

        // update state and update the ready status
        setFilter(() => ({ ...sessionFilters, ...searchParamFilters }));
        setIsReady(_ => true);
        setHasFilteredOnSearch(_ => true);
    };

    /**
     * Clear the filters back to either the default or a specific set of filters
     */
    const clearFilter = (e: any, filters: any | null = null) : void => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }

        if (filterStore && sessionStorage.getItem(`filters.${filterStore}`)) {
            sessionStorage.removeItem(`filters.${filterStore}`);
        }

        setFilter(() => filters ?? defaultFilters);
    };

    /**
     * Updates the current filter with a new set of filters that need to be applied.
     * @param {*} param0 
     */
    const setNewFilter = ({ newFilter }: any) : void => {
        setFilter(() => newFilter);
    }

    /** Updates the filter store in the session storage whenever it gets updated.  */
    React.useEffect(() => {
        if (!filterStore || _.isEqual(filter, defaultFilters)) {
            return;
        }
        sessionStorage.setItem(`filters.${filterStore}`, JSON.stringify(filter));
    }, [filter]);

    /**
     * Run on component/content/provider mount. Will decide what to set the filters
     */
    React.useEffect(changeFilter, [ defaultFilters, searchParams ]);

    return (
        <FilterContext.Provider
            value={{
                clearFilter,
                defaultFilters,
                filter,
                isFiltersApplied: !_.isEqual(filter, defaultFilters),
                isReady,
                setFilter,
                setNewFilter,
                showFilterModal,
                setShowFilterModal
            }}
        >
            {children}
        </FilterContext.Provider>
    );
};

export const AssetManagementDefaultFilters = {
    assetName: null,
    sedol: null,
    provider: null,
    citicode: null
};


export const ReportManagementDefaultFilters = {
    reportStatuses: [],
    efficacy: [],
    masterAccountIds: [],
    masterAccounts: [],
    reportTypes: [],
    reportCategories: [],
    reportProductAreas: [],
    serviceTermIds: [],
    adviserIds: [],
    authorUserIds: [],
    complianceUserIds: [],
    mediaTypes: [],
    authorAssignedStartDate: null,
    authorAssignedEndDate: null,
    passedComplianceStartDate: null,
    passedComplianceEndDate: null,
    sentToClientStartDate: null,
    sentToClientEndDate: null,
    reportFeedbackFilters: [],
    searchTerm: ""
};

//Filter reviews from Jan 1st of the current year to the last day of the current month

export const ReviewManagementDefaultFilters = {
    reviewStatuses: [0, 3, 4],
    reviewDateStart: moment().startOf('month').format('YYYY/MM/DD'),
    reviewDateEnd: moment().endOf('month').format('YYYY/MM/DD'),
    serviceTermIds: [],
    adviserIds: [],
    completedTasks: [],
    overdueTasks: [],
    searchTerm: "",
    includeDelayed: true,
    excludeDelayed: false,
    onlyDelayed: false
};

export const AdministrationDashboardDefaultFilters = {
    reference: null,
    accountName: null,
    statuses: [],
    startDate: null,
    endDate: null,
    organisationId: null,
    divisionId: null,
    adviserIds: []
};

export const AdministrationRemindersDefaultFilters = {
    message: null,
    reminderDateStart: null,
    reminderDateEnd: null,
    completedByIds: [],
    completedDateStart: null,
    completedDateEnd: null
};

export const AdvicePendingDefaultFilters = {
    reference: null,
    accountName: null,
    statuses: [],
    startDate: null,
    endDate: null,
    organisationId: null,
    divisionId: null,
    adviserIds: [],
    filterModel: []
};

export const CorporateActionPairFilters = {
    description: null,
    sedolIn: null,
    sedolOut: null,
    startDate: null,
    endDate: null
};

export const FeeManagementDefaultFilters = {
    organisationId: null,
    divisionId: null,
    adviserIds: [],
    advisers: [],
    introducerIds: [],
    introducers: [],
    providerIds: [],
    providers: [],
    feeTypes: [],
    masterAccountIds: [],
    masterAccounts: [],
    madeUpThings: [],
    startDate: moment().startOf('month').format('YYYY/MM/DD'),
    endDate: null,
    debtOnDate: moment().toISOString()//for Debtors
};

export const DebtorManagementDefaultFilters = {
    //organisationId: null,
    //divisionId: null,
    adviserIds: [],
    advisers: [],
    //introducerIds: [],
    //introducers: [],
    providerIds: [],
    providers: [],
    feeTypes: [],
    masterAccountIds: [],
    masterAccounts: [],
    startDate: moment().startOf('month').toISOString(),
    endDate: null
};

export const IntroducerDashboardDefaultFilters = {
    introducer: "",
    typeIds: [],
    statusesIds: [],
    divisionIds: [],
    teamIds: [],
    adviserIds: []
};

export const InvestmentCommitteeFundDefaultFilters = {
    // Shared between Analysis/Management
    assetName: null,
    monitoring: [0],
    standardPortfolio: [],
    // Analysis only
    volatilityProfile: [],
    assetClass: [],
    management: [],
    region: [],
    style: [],
    // Management only
    controlStatus: [],
    opinion: [],
    guidance: [],
    analystId: [],
    publishedStartDate: null,
    publishedEndDate: null,
    opinionModifiedStartDate: null,
    opinionModifiedEndDate: null,
    publishedById: [],
};

export const InvestmentTransactionDefaultFilters = {
    designationIds: [],
    investmentOperationIds: [],
    providerIds: [],
    wrapperIds: [],
    productIds: [],
    assetIds: [],
    assetIdObjects: [],
    investmentPartnerIds: [],
    statusIds: [],
    custodianReference: null,
    providerReference: null,
    quantity: null,
    value: null,
    dateFrom: null,
    dateTo: null,
    keyTransactionsOnly: true,
    minimumAmount: 0,
    includeInValuation: true,
    isActive: true
};

export const ProviderDefaultFilters = {
    custodianCode: null,
    fundManagerCode: null,
    providerName: null
};

export const NewMoneyDefaultFilters = {
    orgId: null,
    // divId: null,
    adviserIds: [],
    statuses: [],
    isOutflow: null,
    startDate: null,
    endDate: null,
}

export const ProjectManagementDefaultFilters = {
    divId: null,
    adviserIds: [],
    masterAccountIds: [],
    targetStartDate: moment().startOf('month').format("YYYY-MM-DD"),
    targetEndDate: moment().endOf('month').format("YYYY-MM-DD"),
    createdStartDate: null,
    createdEndDate: null,
    campaignId: null,
    campaignStatusIds: [],
    projectName: null,
    projectStatuses: [0], // Default to In Progress only
    adviceTypes: []
};

export const ApplicationDocumentsDefaultFilters = {
    fileName: null,
    userId: null,
    documentType: null,
    uploadedDateFrom: null,
    uploadedDateTo: null
};

export const UserDelegateSearchDefaultFilters = {
    userIds: [],
    delegateUserIds: [],
    createdByIds: [],
    readCalendar: false,
    writeCalendar: false,
    readMail: false,
    writeMail: false,
    sendMail: false,
    readMessages: false,
    writeMessages: false,
    readReminders: false,
    writeReminders: false,
    hasClientAccess: false
};

export const UsersGridDefaultFilters = { };

export const ClientEventsDefaultFilters = {
    showCancelled: true,
    projectId: null,
    userIds: [],
    deadlineDateFrom: null,
    deadlineDateTo: null,
    description: null,
    priorities: [],
    typeIds: []
};

export const PortfolioTestResultDefaultFilters = {
    masterAccountIds: [],
    serviceLevelAgreementIds: [],
    noServiceTerms: false,
    minPortfolioValue: null,
    maxPortfolioValue: null,
    targetProfileIds: [],
    reviewClientsOnly: false,
    volatilityScoreStatus: null,
    actualProfileIds: [],
    failedVolatilityTest: false,
    includeSlightFailVolatilityTest: false,
    flaggedStatuses: [],
    excludeFlagged: false,
    excludeUnflagged: false
}

export const AssetsUnderAdviceDefaultFilters = {
    divisionId: null,
    adviserIds: [],
    minPortfolioValue: null,
    maxPortfolioValue: null,
    serviceLevelAgreementIds: [],
    targetProfileIds: [],
    standardPortfolioIds: [],
    wrapperIds: [],
    productProviders: [],
    productProviderIds: [],
    productIds: [],
    assetGroupIds: [],
    assets: [],
    assetIds: [],
    minHoldingValue: null,
    maxHoldingValue: null,
    monitoring: [],
    assetClass: []
};