import React from "react";
import Button from "../../../components/buttons";
import DocumentSelect from "../../../components/forms/selects/DocumentSelect";
import { useDropzone } from "react-dropzone";
import { Modal } from "react-bootstrap";
import { ThemedIcon } from "../../../components/utilities";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { confirmable, createConfirmation } from "../../../hooks/DialogMountPoint";
import { niceBytes } from "../../../helpers/fileHelpers";
import useFetchAmlEvidence from "../hooks/useFetchAmlEvidence";
import classNames from "classnames";
import GridWrapper from "../../../components/GridWrapper";
import { ThemedSpan } from "../../../components/utilities";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { Spinner } from "../../../components/loaders";
import { FormLabel } from "../../../components/forms";

export type UploadEvidenceModalOptions = {
    businessSheetId: string,
    masterAccountId: string,
    status: number
};

export type UploadEvidenceModalProps = UploadEvidenceModalOptions & {
    cancel: () => void,
    proceed: (data: UploadEvidenceForm) => void,
    show: boolean
};

export type UploadEvidenceFormDocument = {
    id: string | null,
    fileName: string,
    description: string | null,
    fileSize: number,
    documentType: number | null
};

export type UploadEvidenceForm = UploadEvidenceModalOptions & {
    file: File | null,
    documentId: string | null,
    document: UploadEvidenceFormDocument | null
};

const EvidenceFileDisplay = ({ file, onDelete } : Readonly<{ file: UploadEvidenceFormDocument, onDelete: () => void }>) => (
    <div className="d-flex p-3 border border-3 border-opacity-10">
		<div className="d-flex justify-content-center align-items-center">
			<ThemedIcon icon={"fa-file"} size="2xl" variant="primary" />
		</div>
		<p className="flex-fill m-0 px-3 lh-1">
			<span>{file.fileName}</span>
			<br />
			<span className="text-muted fs-smallest">{niceBytes(file.fileSize)}</span>
		</p>
		<div className="d-flex justify-content-center align-items-center">
			<ThemedIcon className="has-pointer" icon="fa-xmark" size="2xl" variant="danger" onClick={onDelete} />
		</div>
	</div>
);

const AntiMoneyLaunderingStatus = ({ masterAccountId }) => {
    const { clientStatuses, error, isFetching, refetch } = useFetchAmlEvidence(masterAccountId);

    return <React.Fragment>
        <FormLabel className="mt-3">
            AML Status:
        </FormLabel>
        <React.Fragment>
            {error != null && <ErrorMessageWrapper>
                <ErrorMessage
                    error={error}
                    retryCallback={refetch}
                />
            </ErrorMessageWrapper>}
            {!error && isFetching && <Spinner />}
            {!isFetching && !error && clientStatuses && clientStatuses.map((status) => <div className="d-flex" key={status.clientAccountId}>
                <FormLabel className={classNames(
                    { "fw-bold": !status.isValid }
                )}>
                    {`${status.clientName}:`}
                </FormLabel>
                <ThemedSpan className={classNames(
                    "ms-3",
                    { "fw-bold": !status.isValid }
                )} variant={!status.isValid ? 'danger' : ''}>
                    {status.status}
                </ThemedSpan>
            </div>)}
        </React.Fragment>
    </React.Fragment>;
}

const UploadEvidenceFileForm = () => {
    const [parent] = useAutoAnimate();
    const { control, setValue, watch } = useFormContext();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: (file: File[]) => {
        setValue('file', file[0], { shouldDirty: true });
        setValue('document', { id: '', fileName: file[0].name, fileSize: file[0].size, }, { shouldDirty: true });
    }});
    const document = watch('document');
    const masterAccountId = watch('masterAccountId');

    const onRemoveFile = () => {
        setValue('file', null, { shouldDirty: true });
        setValue('document', null, { shouldDirty: true });
        setValue('documentId', null, { shouldDirty: true });
    };

    return (
        <React.Fragment>
            {!document && (
                <div>
                    <Controller
                        name="documentId"
                        control={control}
                        render={({ field: { ref, value, onChange, ...rest } }) => (
                            <DocumentSelect
                                label="Select Existing Document:"
                                className="col mb-1"
                                masterAccountId={masterAccountId}
                                slug="business-sheets"
                                value={value}
                                setValue={(e: any) => {
                                    setValue('document', e.data, { shouldDirty: true });
                                    setValue('documentId', e.value, { shouldDirty: true });
                                }}
                            />
                        )}
                    />
                    <div {...getRootProps({ className: 'dropzone d-flex px-3 py-2 border border-3 border-opacity-10' })}>
                        <input {...getInputProps()} />
                        <div className="d-flex flex-fill justify-content-center align-items-center py-4">
                            <p className="m-0">Drag and drop a file here, or click to browse for files.</p>
                        </div>
                    </div>
                </div>
            )}
            {document && <EvidenceFileDisplay file={document} onDelete={onRemoveFile} />}
            {masterAccountId && <AntiMoneyLaunderingStatus masterAccountId={masterAccountId} />}
        </React.Fragment>
    );
};

const UploadEvidenceModal = ({
    businessSheetId,
    cancel,
    proceed,
    masterAccountId,
    show,
    status
}: Readonly<UploadEvidenceModalProps>) => {
    const methods = useForm<UploadEvidenceForm>();
    const { reset, watch } = methods;

    const onClose = () => cancel();

    const onSubmit = (form: UploadEvidenceForm) => proceed(form);

    React.useEffect(() => {
        reset({
            businessSheetId,
            masterAccountId,
            status,
            file: null,
            documentId: null
        });
    }, [businessSheetId, masterAccountId, reset, status]);

    const form = watch();
    return (
        <Modal size="lg" show={show} onHide={onClose} centered>
            <Modal.Header>
                <Modal.Title>Upload Evidence</Modal.Title>
            </Modal.Header>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Modal.Body>
                        {form.masterAccountId && form.businessSheetId && <UploadEvidenceFileForm />}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" type="submit" disabled={!form.file && !form.documentId}>Upload</Button>
                        <Button variant="light" onClick={onClose}>Close</Button>
                    </Modal.Footer>
                </form>
            </FormProvider>
        </Modal>
    );
};

export function showUploadEvidenceModal(options: UploadEvidenceModalOptions) {
    return createConfirmation(confirmable(UploadEvidenceModal))({ ...options } as UploadEvidenceModalProps);
}