import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { DateDisplay, IconButton } from "../../../components";
import { DateInput } from "../../../components/forms";
import { StandardTable } from "../../../components/tables";
import { AutoFitCell, FixedCell, ThemedTableHeaderActionColumn } from "../../../components/tables/StandardTable";
import { AccordionSection, ThemedIcon, ThemedSpan } from "../../../components/utilities";
import { MessagePriorities } from "../../../helpers/constants";
import { toNString } from "../../../helpers/guids";
import CreatePersonalMessageModal from "../../createpersonalmessagemodal";
import { MessageThreadModal } from "../../messagethread";
import useProjectMessagesList from "../hooks/useProjectMessagesList";

const ProjectMessagesEmptyRow = ({ openCreate, columns }) => <tr>
    <td colSpan={columns.length}>
        There are no messages available for this Project.
        You can click <button className="anchor" onClick={openCreate}>here</button> to create and send a message attached to this project.
    </td>
</tr>;

const ProjectMessagesRow = ({ data, index, columns, ...rest }) => <tr key={`row-${index}`}>
    {columns.map(({
        property,
        valueFormatter = (val) => val,
        CellComponent = null,
        className,
        fitContent = false,
        ...col
    }, i) => {
        if (CellComponent)
            return <CellComponent
                key={`row-col-${i}`}
                {...rest}
                {...data}
                {...col} />;

        const value = data[property];
        const formattedValue = valueFormatter(value);

        const props = {
            key: `row-col-${i}`,
            className: classNames(className, {
                "text-truncate": !fitContent
            }),
            ...col
        };

        return fitContent
            ? <AutoFitCell {...props}>{formattedValue}</AutoFitCell>
            : <FixedCell {...props}>{formattedValue}</FixedCell>;
    })}
</tr>;

const ProjectMessagesDoneDateCol = ({ doneDate, doneByName }) => <AutoFitCell key={'row-col-done'}>
    {doneDate && <p className="m-0 lh-1">
        <DateDisplay>{doneDate}</DateDisplay>
        <br />
        <ThemedSpan variant="muted" className="fs-smallest">
            {doneByName}
        </ThemedSpan>
    </p>}
</AutoFitCell>

const ProjectMessagesPriorityCol = ({ recipients, messagePriority, userId, user = false }) => {
    const priority = user
        ? recipients.find(({ userId: recipientUserId }) => recipientUserId === toNString(userId))?.recipientPriority
        : messagePriority

    return <AutoFitCell>
        <span className={classNames("badge py-1 px-2", {
            'bg-priority-high text-priority-high': priority === MessagePriorities.High,
            'bg-priority-medium text-priority-medium': priority === MessagePriorities.Medium,
            'bg-priority-low text-priority-low': priority === MessagePriorities.Low
        })}>
            {{
                [MessagePriorities.Low]: "Low",
                [MessagePriorities.Medium]: "Medium",
                [MessagePriorities.High]: "High"
            }[priority]}
        </span>
    </AutoFitCell>
}

const ReadMessageActionMenuItem = ({ showCheck, read, unread, readOptions, unreadOptions, label }) => {
    const onHandleClick = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        if (!showCheck && (!read || typeof (read) !== 'function')) {
            return;
        }

        if (showCheck && (!unread || typeof (unread) !== 'function')) {
            return;
        }

        showCheck ? unread(unreadOptions) : read(readOptions);
    };

    return <Dropdown.Item className="d-flex justify-content-between align-items-center" onClick={onHandleClick}>
        {label}
        {showCheck && <FontAwesomeIcon
            icon="check"
            className="text-success ms-2"
        />}
    </Dropdown.Item>;
};

const UpdateReminderDateActionMenuItem = ({ userReminderDate, update }) => {

    const [showModal, setShowModal] = useState(false);

    return <>
        <Dropdown.Item onClick={() => setShowModal(true)}>Set Reminder Date</Dropdown.Item>
        <Modal show={showModal} centered backdrop="static">
            <Modal.Header>
                <Modal.Title>Update Reminder Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row px-4">
                    <DateInput
                        value={userReminderDate}
                        onBlur={(value) => update("reminderDate", value)}
                        label="Remind On"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowModal(false)} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
    </>
};

const ProjectMessagesActionCol = ({
    messageId,
    threadId,
    reportId = null,
    recipients,
    isActionRequired,
    isDone,
    openThread,
    openReport,
    readMessage,
    unreadMessage,
    updateRecipient
}) => {
    const { user: { profile: { sub: userId } } } = useAuth();
    const userRecipient = recipients.find(({ userId: recipientUserId }) => recipientUserId === toNString(userId));
    const isRecipient = userRecipient != null;
    const { noFurtherActionTime, recipientPriority, reminderDate } = userRecipient || {};

    const update = (property, value) => updateRecipient(messageId, userId, property, value);
    const read = (options) => readMessage(messageId, options);
    const unread = (options) => unreadMessage(messageId, options);

    return <AutoFitCell key={'row-col-action'}>
        <Dropdown className="w-100 text-center">
            <Dropdown.Toggle as={ThemedIcon} icon="fa-ellipsis" className="has-pointer" />
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => openThread(threadId)}>Open Conversation</Dropdown.Item>
                {reportId != null && <Dropdown.Item onClick={() => openReport(reportId)}>Open Report</Dropdown.Item>}
                {isRecipient && <>
                    <Dropdown.Divider />
                    <Dropdown.Header>Actions</Dropdown.Header>
                    {isActionRequired && <ReadMessageActionMenuItem
                        showCheck={isDone === true}
                        read={read}
                        unread={unread}
                        readOptions={{ isDone: true, noFurtherActionRequired: true }}
                        unreadOptions={{ undoRead: false, undoDone: true, undoNoFurtherActionRequired: false }}
                        label={"Task Complete, no further action required"}
                    />}
                    <ReadMessageActionMenuItem
                        showCheck={noFurtherActionTime != null}
                        read={read}
                        unread={unread}
                        readOptions={{ isDone: false, noFurtherActionRequired: true }}
                        unreadOptions={{ undoRead: false, undoDone: true, undoNoFurtherActionRequired: true }}
                        label={"No further action required"}
                    />
                    <UpdateReminderDateActionMenuItem
                        userReminderDate={reminderDate}
                        update={update}
                    />
                    <Dropdown.Divider />
                    <Dropdown.Header>Priority</Dropdown.Header>
                    {Object.keys(MessagePriorities).map((key) => {
                        const priority = MessagePriorities[key];

                        const onClick = () =>
                            update("recipientPriority", priority).then(
                                _ => toast.success("Priority updated"),
                                error => toast.error(`Failed to update priority: ${error?.data ?? "Unknown error"}`)
                            );

                        return <Dropdown.Item key={key} className="d-flex justify-content-between align-items-center" onClick={onClick}>
                            {key}
                            {priority === recipientPriority && <FontAwesomeIcon icon="check" className="text-success ms-2" />}
                        </Dropdown.Item>
                    })}
                </>}
            </Dropdown.Menu>
        </Dropdown>
    </AutoFitCell>;
}

const ProjectMessagesColumns = [
    {
        label: "Sent Date",
        property: "sentOnDate",
        valueFormatter: (value) => value && <DateDisplay>{value}</DateDisplay>,
        fitContent: true
    },
    {
        label: "Sender",
        property: "senderName",
        fitContent: true
    },
    {
        label: "Recipient(s)",
        property: "recipients",
        valueFormatter: (arr) => arr.map(({ displayName }) => displayName)
            .filter(Boolean) // Discard falsey values to prevent blank entries
            .join(", "),
        fitContent: true,
        maxWidth: "20vw",
        className: "text-truncate"
    },
    {
        label: "Subject",
        property: "threadSubject"
    },
    {
        label: "Status",
        property: "statusText",
        fitContent: true
    },
    {
        label: "Message",
        property: "message"
    },
    {
        label: "Priority",
        CellComponent: ProjectMessagesPriorityCol
    },
    {
        label: "User Priority",
        CellComponent: ProjectMessagesPriorityCol,
        user: true
    },
    {
        label: "Deadline",
        property: "deadlineDate",
        valueFormatter: (value) => value && <DateDisplay>{value}</DateDisplay>,
        fitContent: true
    },
    {
        label: "Done",
        CellComponent: ProjectMessagesDoneDateCol
    },
    // TODO: More/different columns?
    {
        CellComponent: ProjectMessagesActionCol,
        headerRenderer: ({ openCreate }) => <ThemedTableHeaderActionColumn>
            <IconButton
                buttonSize="sm"
                icon="fa-plus"
                onClick={openCreate}
                variant="success"
            />
        </ThemedTableHeaderActionColumn>,
        fitContent: true
    }
];

const ProjectMessagesList = ({ selectedProject }) => {
    const { user: { profile: { sub: userId } } } = useAuth();

    const { projectId, masterAccountId } = selectedProject
    const {
        selectedThreadId,
        setSelectedThreadId,
        clear,
        data,
        isClearing,
        error,
        isLoading,
        readMessage,
        unreadMessage,
        updateRecipient
    } = useProjectMessagesList(projectId);

    const [showCreateMessage, setShowCreateMessage] = useState(false);

    const handleCreateOpen = () => setShowCreateMessage(true);
    const handleCreateClose = () => setShowCreateMessage(false);
    const handleCreate = () => {
        setShowCreateMessage(false);
        clear();
    }

    const navigate = useNavigate();

    ProjectMessagesColumns[ProjectMessagesColumns.length - 1].openCreate = handleCreateOpen;

    return <>
        <CreatePersonalMessageModal
            show={showCreateMessage}
            onHide={handleCreateClose}
            onCreated={handleCreate}
            masterAccountId={masterAccountId}
            projectId={projectId}
        />
        <MessageThreadModal
            show={selectedThreadId !== null}
            threadId={selectedThreadId}
            handleClose={() => setSelectedThreadId(null)}
            userId={userId}
        />
        <AccordionSection label="Project Messages" defaultActiveKey="1">
            <StandardTable
                className="table-borderless"
                isTableResponsive={false} // Needed to keep overflow visible, to show the action column's dropdown outwith the accordion
                columns={ProjectMessagesColumns}
                data={data}
                error={error}
                isLoading={isLoading || isClearing}
                EmptyRowComponent={ProjectMessagesEmptyRow}
                emptyRowComponentProps={{ openCreate: handleCreateOpen }}
                hideHeaderOnEmpty
                RowComponent={ProjectMessagesRow}
                rowComponentProps={{
                    openThread: (threadId) => setSelectedThreadId(threadId),
                    openReport: (reportId) => navigate(`../reports?masterAccountId=${masterAccountId}&reportId=${reportId}`),
                    readMessage,
                    unreadMessage,
                    updateRecipient,
                    userId
                }}
            />
        </AccordionSection>
    </>
}

export default ProjectMessagesList;