import React, { useEffect } from "react";
import { useMemo } from "react";
import styled from "styled-components";
import { Button, DateDisplay } from "../../../components";
import { FormCheck } from "../../../components/forms";
import { ThemedParagraph, ThemedSpan } from "../../../components/utilities";
import MessageBox from "./MessageBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Overlay, OverlayTrigger, Tooltip } from "react-bootstrap";

const HighPriorityIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.danger};
`;

const ReadOnlyMessage = ({
    dateSent,
    message,
    recipients,
    onReply,
    sender,
    onDone,
    doneByName,
    isDone,
    doneDate,
    subject,
    priority,
    page,
    isActionRequired
}) => {

    const recipientsString = useMemo(() => recipients.reduce(
        (totalString, currentRecipient) => totalString + (totalString.length === 0 ? "" : ", ") + currentRecipient.displayName, ""),
        [recipients]);

    return <MessageBox className="mb-3 rounded">
        <div className="d-flex align-items-center mb-1">
            <div className="d-flex">
                <b>
                    <ThemedSpan>
                        from {sender} to {recipientsString} at <DateDisplay format="HH:mm DD/MM/YY">{dateSent}</DateDisplay>
                    </ThemedSpan>
                </b>
                {priority === 30 && <div className="ms-2">
                    <OverlayTrigger overlay={
                        <Tooltip>High Priority</Tooltip>
                    }>
                        <HighPriorityIcon icon={"exclamation-circle"} size={"lg"} />
                    </OverlayTrigger>
                </div>}
            </div>
            <div className="d-flex align-items-center ms-auto">
                {subject && <div className="me-5">
                    <ThemedSpan variant="muted">{subject}</ThemedSpan>
                </div>}
                <div>
                    <Button
                        onClick={onReply}
                        variant="success"
                        className="border-dark"
                    >
                        Reply
                    </Button>
                </div>
            </div>

        </div>
        <div className="row">
            <ThemedParagraph
                variant="primary"
            >
                {message}
            </ThemedParagraph>
        </div>
        {isActionRequired && <div className={`row d-flex justify-content-between mt-auto`}>
            <div className={`col-auto d-flex ${!isDone && "text-danger"}`}>
                <FormCheck
                    onChange={() => onDone(!isDone, { page })}
                    label={"Done"}
                    isChecked={isDone}
                />
                {
                    isDone && <ThemedSpan className="ms-2">
                        ({doneByName} at <DateDisplay format="HH:mm DD/MM/YYYY">{doneDate}</DateDisplay>)
                    </ThemedSpan>

                }
            </div>
        </div>}
    </MessageBox>;
};

export default ReadOnlyMessage;