import React from "react";
import styled from "styled-components";
import useOpenDocument from "../../../hooks/useOpenDocument";
import { ApplicationType } from "../types/ApplicationType";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ActionColumnParent = styled.td`
    width: ${({ width }) => width}%;
    cursor: pointer;
    vertical-align: middle;
    text-align: ${props => props.textAlign ?? "left"};
`;

const ActionColumnParentToggle = React.forwardRef<HTMLDivElement>(({ ...rest }, ref) => (
    <div ref={ref} {...rest}>
        <FontAwesomeIcon icon={"fa-ellipsis" as any} />
    </div>
));

export const AdviceGridRowActionColumn = ({ 
    width = 2.5, 
    data 
} : Readonly<{ width?: number, data?: ApplicationType | null }>) => {
    const menuId = React.useId();
    const { openDocument, isFetching: isOpening } = useOpenDocument();
    
    const handleOpenDocument = (documentId: string) => openDocument(documentId);

    if (!data) {
        return (
            <ActionColumnParent width={width}>
                <span>-</span>
            </ActionColumnParent>
        )
    }

    const { clientApprovalPdfDocumentId, qualifiedReference } = data;
    return (
        <ActionColumnParent width={width}>
            <Dropdown className="caret-off">
                <Dropdown.Toggle id={menuId} as={ActionColumnParentToggle} />
                <Dropdown.Menu>
                    <Dropdown.Header>{qualifiedReference}</Dropdown.Header>
                    <Dropdown.Item disabled={!clientApprovalPdfDocumentId || isOpening} onClick={() => handleOpenDocument(clientApprovalPdfDocumentId!)}>
                        <FontAwesomeIcon icon={"fas fa-file-signature" as any} size="sm" /> <span className="ms-1">Open Evidence</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ActionColumnParent>
    )
};