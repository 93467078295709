import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import GridWrapper from "../../../components/GridWrapper";
import { FormLabel } from "../../../components/forms";
import { Spinner } from "../../../components/loaders";
import ErrorMessage from "../../../components/messages/ErrorMessage";
import ErrorMessageWrapper from "../../../components/messages/ErrorMessageWrapper";
import { CenterArea } from "../../../components/utilities";
import useFundManagementGrid from "../hooks/useFundManagementGrid";
import FundManagementRow from "./FundManagementRow";

const WrappedLabel = styled(FormLabel)`
    white-space: normal !important;
`;

const SortableHeader = ({ key, sort, direction = null, children, className, ...rest }) => {
    return <span key={key} className="d-flex mx-auto has-pointer" onClick={sort}>
        <WrappedLabel className={`has-pointer ${className}`} {...rest}>
            {children}
        </WrappedLabel>
        {direction != null && <FontAwesomeIcon
            icon={direction.toLowerCase() === "asc" ? "sort-up" : "sort-down"}
            className="my-auto ps-1"
        />}
    </span>
}

const FundManagementGrid = () => {
    const {
        assetGroups,
        assetGroupsIsUninitialised,
        assetGroupsIsLoading,
        assetGroupsIsFetching,
        assetGroupsIsError,
        assetGroupsError,
        sort,
        currentSort,
        refetch,
    } = useFundManagementGrid();

    const currentLength = useRef(assetGroups?.length ?? 3);

    useEffect(() => {
        currentLength.current = assetGroups?.length ?? currentLength.current;
    }, [assetGroups?.length]);

    if (assetGroupsIsUninitialised || assetGroupsIsLoading)
        return <CenterArea>
            <Spinner />
        </CenterArea>;

    if (assetGroupsIsError)
        return <CenterArea>
            <ErrorMessageWrapper>
                <ErrorMessage
                    error={assetGroupsError}
                    retryCallback={refetch}
                />
            </ErrorMessageWrapper>
        </CenterArea>

    return <GridWrapper className="p-4" gridTemplateColumns={`auto minmax(auto, 1fr) minmax(auto, 1fr) minmax(auto, 1.2fr) minmax(auto, 0.8fr) repeat(5, 15ch)`}>
        {/* Header Row */}
        <span className="invisible" />
        <FormLabel>Asset Name</FormLabel>
        <FormLabel>Fund Status</FormLabel>
        <FormLabel>Guidance</FormLabel>
        <FormLabel>Control</FormLabel>
        {[{
            field: "lastPublishedDate",
            header: "Last Published",
        }, {
            field: "currentOpinionModifiedDate",
            header: "Opinion",
        }, {
            field: "rationaleModifiedDate",
            header: "Rationale",
        }, {
            field: "fundManagerModifiedDate",
            header: "Fund Manager",
        }, {
            field: "forDiscussionModifiedDate",
            header: "Discussion",
        }].map(({ field, header }, index) => {

            let direction;
            if (currentSort?.toLowerCase().includes(field.toLowerCase()))
                direction = currentSort.toLowerCase().endsWith("desc") ? "desc" : "asc";

            const sortField = `${field.slice(0, 1).toUpperCase()}${field.slice(1)}`;

            return <SortableHeader className="has-pointer" key={index} sort={() => sort(sortField)} direction={direction}>{header}</SortableHeader>
        })}
        {assetGroupsIsFetching
            ? new Array(currentLength.current).fill(undefined).map((_, index) => <React.Fragment key={index}>
                <span className="invisible" />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </React.Fragment>)
            : assetGroups?.map((assetGroup) =>
                <FundManagementRow
                    key={assetGroup?.assetGroupId}
                    fund={assetGroup}
                />)}
    </GridWrapper>;
}

export default FundManagementGrid;