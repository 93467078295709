import React from "react";

const AdministrationFeesContext = React.createContext();

export const useAdministrationFeesProvider = () => React.useContext(AdministrationFeesContext);

export const AdministrationFeesProvider = ({ children }) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const [filters, setFilters] = React.useState(null);
    const currentSearchType = tabIndex===0 ? "fees" : tabIndex===1 ? "cash" : tabIndex===2 ? "debtors" : tabIndex===3 ? "ageddebtors" : null;
    return (
        <AdministrationFeesContext.Provider value = {{ 
            currentSearchType,
            tabIndex, 
            setTabIndex,
            filters,
            setFilters
        }}>
            {children}
        </AdministrationFeesContext.Provider>
    );
}
