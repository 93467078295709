import { applyPatch } from 'fast-json-patch';
import { wealthProApi } from '../../redux/api';
import { convertSearchParamsToFilter } from '../../hooks/FilterContext';

const hoAdminFeeTransactionsApiEndpoints = wealthProApi
    .injectEndpoints({
        endpoints: (builder) => ({
            createFeeTransaction: builder.mutation({
                query: ({ currentSearchType, request }) => ({
                    url: `api/headofficeadmin/feetransactions`,
                    method: 'POST',
                    body: request
                }),
                async onQueryStarted({ currentSearchType, request, filter }, { dispatch, queryFulfilled }) {
                    const result = (currentSearchType==="fees" || currentSearchType==="cash") ?  dispatch(wealthProApi.util.updateQueryData('searchFeeGroups', filter, ({ results }) => {
                        let item = results.find(x => x.groupId === request.groupId);
                        
                        if(currentSearchType === "fees"){
                            if (request.feeTransactionType?.debtorMultiplier > 0) {
                                item.totalFeeValuePounds += request.totalFeeAmount;
                                item.totalVatValuePounds += request.totalVatAmount;
                            }
                        }
                        else if(currentSearchType === "cash"){
                            if (request.feeTransactionType?.debtorMultiplier === -1 && request.feeTransactionType?.salesMultiplier === 0) {
                                item.totalFeeValuePounds += request.totalFeeAmount;
                                item.totalVatValuePounds += request.totalVatAmount;
                            }
                        }
                        //item.totalDebtValuePounds += (request.totalFeeAmount + request.totalVatAmount) * request.feeTransactionType?.debtorMultiplier;
                    })) : 
                    dispatch(wealthProApi.util.updateQueryData('searchDebtors', filter, ({ results }) => {
                        let item = results.find(x => x.groupId === request.groupId);
                        
                        if(currentSearchType === "debtors"){
                            let totalVatAmount = (request.totalVatAmount === null || request.totalVatAmount === undefined || isNaN(request.totalVatAmount)) ? 0 : request.totalVatAmount;
                            if(!isNaN(request.feeTransactionType.salesMultiplier)){
                                item.totalFeeValuePounds += (request.totalFeeAmount+totalVatAmount)*request.feeTransactionType?.salesMultiplier;
                                //console.log('what will be added to fee',(request.totalFeeAmount+totalVatAmount)*request.feeTransactionType?.salesMultiplier);
                                //console.log('updatedTotalFeeValuePounds', item.totalFeeValuePounds);
                            }
                            if(!isNaN(request.feeTransactionType.debtorMultiplier)){
                                item.debt += (request.totalFeeAmount+totalVatAmount)*request.feeTransactionType?.debtorMultiplier;
                            }
                        }  
                    }));
                    try {
                        await queryFulfilled;
                    }
                    catch {
                        result.undo();
                    }
                },
                invalidatesTags: ["applicationFees", "applicationDebtors"]
            }),
            deleteFeeTransaction: builder.mutation({
                query: ({ feeTransactionId }) => ({
                    url: `api/headofficeadmin/feetransactions/${feeTransactionId}`,
                    method: 'DELETE'
                }),
                async onQueryStarted({ currentSearchType, previousValues, feeTransactionId, filter, groupId }, { dispatch, queryFulfilled }) {
                    if (!previousValues || !filter || !groupId) return;
                    
                    const result = (currentSearchType==="fees" || currentSearchType==="cash") ?   dispatch(wealthProApi.util.updateQueryData('searchFeeGroups', filter, ({ results }) => {
                        var newFeeAllocations = previousValues.filter(x => x.feeTransactionId !== feeTransactionId);
                        var group = results.find(x => x.groupId === groupId);
                        
                        if(currentSearchType === "fees"){
                            group.totalFeeValuePounds = newFeeAllocations.reduce((acc, curr) => acc + (curr.debtorMultiplier === 1 ? curr.feeValuePounds : 0), 0);
                            group.totalVatValuePounds = newFeeAllocations.reduce((acc, curr) => acc + (curr.debtorMultiplier === 1 ? curr.vatValuePounds : 0), 0);
                        }
                        else if(currentSearchType === "cash"){
                            group.totalFeeValuePounds = newFeeAllocations.reduce((acc, curr) => acc + ((curr.debtorMultiplier === -1 && curr.salesMultiplier === 0) ? curr.feeValuePounds : 0), 0);
                            group.totalVatValuePounds = newFeeAllocations.reduce((acc, curr) => acc + ((curr.debtorMultiplier === -1 && curr.salesMultiplier === 0) ? curr.vatValuePounds : 0), 0);
                        }
                        //group.totalDebtValuePounds = newFeeAllocations.reduce((acc, curr) => acc + ((curr.feeValuePounds + curr.vatValuePounds) * curr.debtorMultiplier), 0);

                    })) :             
                    dispatch(wealthProApi.util.updateQueryData('searchDebtors', filter, ({ results }) => {
                        var newFeeAllocations = previousValues.filter(x => x.feeTransactionId !== feeTransactionId);
                        var group = results.find(x => x.groupId === groupId);
                        if(currentSearchType === "debtors"){
                            group.totalFeeValuePounds = newFeeAllocations.reduce((acc, curr) => acc + ((curr.feeValuePounds+curr.vatValuePounds)*curr.salesMultiplier),0);
                            group.debt = newFeeAllocations.reduce((acc, curr) => acc + ((curr.feeValuePounds+curr.vatValuePounds)*curr.debtorMultiplier),0);
                        }
                        //group.totalDebtValuePounds = newFeeAllocations.reduce((acc, curr) => acc + ((curr.feeValuePounds + curr.vatValuePounds) * curr.debtorMultiplier), 0);

                    }));
                    try {
                        await queryFulfilled;
                    }
                    catch {
                        result.undo();
                    }
                },
                invalidatesTags: ["applicationFees", "applicationDebtors"]
            }),
            getFeeTransactionById: builder.query({
                query: ({ feeTransactionId }) => `api/headofficeadmin/feetransactions/${feeTransactionId}`
            }),
            updateFeeTransaction: builder.mutation({
                query: ({ feeTransactionId, currentSearchType, request }) => ({
                    url: `api/headofficeadmin/feetransactions/${feeTransactionId}`,
                    //url: `api/headofficeadmin/feetransactions/${feeTransactionId}${currentSearchType? `?searchType=${currentSearchType}` :""}`,
                    method: 'PATCH',
                    body: request
                }),
                async onQueryStarted({ feeTransactionId, currentSearchType, request, previousValues, filter }, { dispatch, queryFulfilled }) {
                    const result = (currentSearchType==="fees" || currentSearchType==="cash") ? dispatch(wealthProApi.util.updateQueryData('searchFeeGroups', filter, ({ results }) => {
                        let item = results.find(x => x.groupId === request.groupId);
                        if(currentSearchType === "fees"){
                            if (request.feeTransactionType?.debtorMultiplier > 0) {
                                item.totalFeeValuePounds += (request.totalFeeAmount - previousValues.totalFeeAmount);
                                item.totalVatValuePounds += (request.totalVatAmount - previousValues.totalVatAmount);
                            }
                        }
                        else if(currentSearchType === "cash"){
                            if (request.feeTransactionType?.debtorMultiplier === -1 && request.feeTransactionType?.salesMultiplier === 0) {
                                item.totalFeeValuePounds += (request.totalFeeAmount - previousValues.totalFeeAmount);
                                item.totalVatValuePounds += (request.totalVatAmount - previousValues.totalVatAmount);
                            }
                        }
                        //var totalDifference = (request.totalFeeAmount + request.totalVatAmount) - (previousValues.totalFeeAmount + previousValues.totalVatAmount);
                        //item.totalDebtValuePounds += totalDifference * request.feeTransactionType?.debtorMultiplier;
                    })) :
                    dispatch(wealthProApi.util.updateQueryData('searchDebtors', filter, ({ results }) => {
                        let item = results.find(x => x.groupId === request.groupId);
                        if(currentSearchType === "debtors"){
                            if(!isNaN(request.feeTransactionType?.salesMultiplier)){
                                item.totalFeeValuePounds += ((request.totalFeeAmount+request.totalVatAmount) - (previousValues.totalFeeAmount+previousValues.totalVatAmount))*request.feeTransactionType?.salesMultiplier;
                            }
                            if(!isNaN(request.feeTransactionType?.debtorMultiplier)){
                                item.debt += ((request.totalFeeAmount+request.totalVatAmount) - (previousValues.totalFeeAmount+previousValues.totalVatAmount))*request.feeTransactionType?.debtorMultiplier;
                            }
                        }
                    }));

                    try {
                        await queryFulfilled;
                    }
                    catch {
                        result.undo();
                    }
                },
                invalidatesTags: ["applicationFees", "applicationDebtors"]
            })
        })
    });

export const {
    useCreateFeeTransactionMutation,
    useDeleteFeeTransactionMutation,
    useGetFeeTransactionByIdQuery,
    useLazyGetFeeTransactionByIdQuery,
    useUpdateFeeTransactionMutation
} = hoAdminFeeTransactionsApiEndpoints;