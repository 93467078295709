import styled from "styled-components";
import { ThemedSpan } from "../../../components/utilities";
import useDebtorTotals from "../hooks/useDebtorTotals";
import { NumericFormat } from "react-number-format";
import { Spinner } from "../../../components/loaders";
import { ErrorMessage } from "../../../components/messages";
import React from "react";
import { FilterProvider, DebtorManagementDefaultFilters } from "../../../hooks/FilterContext";
import { current } from "@reduxjs/toolkit";

const DebtorTotalsWrapper = styled.div`
    display: flex;
    font-size: 16px;
    & > span:not(:last-child) {
        margin-right: 4rem;
    }
    color: #fff !important;
`;

const RetryClickFont = styled.a`
    color: #ddd !important;
`;

const DebtorTotals = (currentSearchType) => { 
   
    const { data, isFetching, isLoading, isError, error, retryGetDebtorTotals } = useDebtorTotals();
    {/*const { data, isFetching, isLoading, isError, error, retryGetDebtorTotals } = {};*/}
    {/*const { totalFees, totalDebt  } = data || {};*/}
    
    //console.log('debtorTotal', data);

    const { totalDebt  } = data === null ? {totalDebt:0} : data || {};
    {/*const { totalFees, totalDebt  } = data === null ? {totalFees:0,totalDebt:0} : data || {};*/}
    {/*const { totalFees, totalDebt  } = 0.00;*/}

    ///testing
    const {searchType} = currentSearchType.currentSearchType;

    if (isError) {
        return <DebtorTotalsWrapper>
            <span>Sorry! We were unable to load the debtor totals. <RetryClickFont href="#" onClick={retryGetDebtorTotals}>Click here to retry.</RetryClickFont></span>
        </DebtorTotalsWrapper>
    }
    return <DebtorTotalsWrapper>
        {isFetching && <Spinner className="me-3"/>}
        {!isLoading && <React.Fragment>
            {/*<span>Total Fees: <NumericFormat value={totalFees} decimalScale={2} prefix="£" thousandSeparator displayType="text" /></span>*/}
            <span>Total Debt: <NumericFormat value={totalDebt} decimalScale={2} prefix="£" thousandSeparator displayType="text" /></span>
            {/*<span>Total {searchType !== "cash" ? "Fees" : "Cash"}: <NumericFormat value={totalFees} decimalScale={2} prefix="£" thousandSeparator displayType="text" /></span>
            <span>Total VAT: <NumericFormat value={totalVat} decimalScale={2} prefix="£" thousandSeparator displayType="text" /></span>*/}
        </React.Fragment>}
    </DebtorTotalsWrapper>;
};
//export default (FilterProvider, { defaultFilters: DebtorManagementDefaultFilters })(DebtorTotals);
export default DebtorTotals;