import { Fragment, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DateInput, FormInput } from "../../../components/forms";
import AnalystIconDisplay from "./AnalystIconDisplay";

const HighlightedInput = styled(FormInput)`
    ${props => props.colour != null && `color: ${props.colour}`};
    ${props => props.colour != null && `border-color: ${props.colour}`};
`;

const DisabledDateInput = (props) => <DateInput {...props} disabled ButtonComponent={null} />;

const HighlightedDateInput = styled(DisabledDateInput)`
    ${props => props.isHighlighted ? `color: ${props.colour}` : ''};
    ${props => props.isHighlighted ? `border-color: ${props.colour}` : ''};
    border-radius: 0.25rem !important;
`;

const FundManagementRow = ({ fund }) => {
    const commentaryUnpublishedMap = useMemo(() => ({
        manager: fund?.fundManagerIsUnpublished,
        opinion: fund?.opinionIsUnpublished,
        rationale: fund?.rationaleIsUnpublished,
        discussion: fund?.forDiscussionEnabled && fund?.forDiscussionIsUnpublished
    }), [fund?.forDiscussionEnabled, fund?.forDiscussionIsUnpublished, fund?.fundManagerIsUnpublished, fund?.opinionIsUnpublished, fund?.rationaleIsUnpublished]);

    const controlStatusColour = useMemo(() => {
        switch (fund?.controlStatus) {
            case 0: // Draft
                return "blue";
            case 1: // Await Approval
                return "purple";
            case 2: // Approved
                return "green";
            case 3: // Published
                if (fund?.opinionIsStale)
                    return "red";
                break;
            default:
                return null;
        }
        return null;
    }, [fund?.controlStatus, fund?.opinionIsStale]);

    const highlightedCells = useMemo(() => {
        if (fund?.controlStatus !== 3) // Unpublished
            return Object.keys(commentaryUnpublishedMap).filter(key => commentaryUnpublishedMap[key]);

        if (fund?.opinionIsStale)
            return ['opinion'];

        return [];
    }, [commentaryUnpublishedMap, fund?.controlStatus, fund?.opinionIsStale]);

    const navigate = useNavigate();

    return <Fragment key={fund.assetGroupId}>
        <AnalystIconDisplay analystId={fund.analystId} />
        <button className="anchor text-start text-truncate" onClick={() => navigate(`../${fund.assetGroupId}`)}>{fund.assetName ?? "Not Mapped"}</button>
        <FormInput className="pt-2" value={fund.opinionDisplayText ?? "Not Set"} disabled />
        <FormInput className="pt-2" value={fund.guidanceDisplayText ?? "Not Set"} disabled />
        <HighlightedInput
            value={fund.controlStatusDisplayText}
            disabled
            colour={controlStatusColour}
        />
        {fund.lastPublishedDate == null
            ? <FormInput className="pt-2" disabled value={"Never Published"} />
            : <DisabledDateInput className="rounded-2" value={fund.lastPublishedDate} />}

        {fund.currentOpinionModifiedDate == null
            ? <FormInput className="pt-2" value={"Never Modified"} disabled />
            : <HighlightedDateInput
                colour={controlStatusColour}
                isHighlighted={highlightedCells.some(cell => cell === 'opinion')}
                value={fund.currentOpinionModifiedDate} />}

        {fund.rationaleModifiedDate == null
            ? <FormInput className="pt-2" value={"Never Modified"} disabled />
            : <HighlightedDateInput
                colour={controlStatusColour}
                isHighlighted={highlightedCells.some(cell => cell === 'rationale')}
                value={fund.rationaleModifiedDate} />}

        {fund.fundManagerModifiedDate == null
            ? <FormInput className="pt-2" value={"Never Modified"} disabled />
            : <HighlightedDateInput
                colour={controlStatusColour}
                isHighlighted={highlightedCells.some(cell => cell === 'manager')}
                value={fund.fundManagerModifiedDate} />}

        {fund.forDiscussionEnabled
            ? fund.forDiscussionModifiedDate == null
                ? <FormInput className="pt-2" value={"Never Modified"} disabled />
                : <HighlightedDateInput
                    colour={controlStatusColour}
                    isHighlighted={highlightedCells.some(cell => cell === 'discussion')}
                    value={fund.forDiscussionModifiedDate} />
            : <FormInput value={"N/A"} disabled />}
    </Fragment>
}

export default FundManagementRow;