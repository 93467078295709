import { useMemo } from "react";
import { DateDisplay } from "../../../components";
import styled from "styled-components";
import { Badge } from "../../../components/utilities";
import { useAssetsManagement } from "../hooks/AssetsManagementContext";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import AssetsTableRowActionCol from "./AssetsTableRowActionCol";
import { NumericFormat } from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

const TableCell = styled.td`
    width: ${props => props.width}%;
    max-width: 50px;
    vertical-align: middle;
`;

const StyledBadge = styled(Badge)`
    background-color: ${props => props.isInc ? "#8c68cd" : props.theme.primary};
    width: 36px
`;

const IncomeOrAccumulationBadge = ({ value }) => {

    const label = useMemo(() => {
        switch (value) {
            case 0:
                return "Inc";
            case 1:
                return "Acc";
            case 2:
                return "-";
        }
    }, [value]);

    if (label === "-") return <span className="px-2">{label}</span>;

    return <StyledBadge isInc={value === 0}>{label}</StyledBadge>;
};

const PriceIndexBadge = ({ value }) => {

    return <FontAwesomeIcon
        size="xl"
        icon={value ? 'fa-circle-check' : 'fa-circle-xmark'}
        className={classnames({
            'text-success': value,
            'text-danger': !value
        })}
    />;
}

const AssetsTableRow = ({ data, index, style: { height, width } }) => {

    const { columns, setAssetIdToDelete, setAssetIdToEdit } = useAssetsManagement();

    const asset = data[index];

    const {
        assetId,
        assetName,
        longAssetName,
        providerName,
        finexFundCode,
        citicode,
        sedol,
        currency,
        subcategory,
        sectorCode,
        incomeOrAccumulation,
        finexSubcategory,
        midPrice,
        bidPrice,
        offerPrice,
        priceDate,
        priceIndex
    } = asset || {};

    if (!asset) {
        return (
            <tr style={{ height, width }}>
                <td colSpan={columns.length}>
                    <Skeleton height={32} />
                </td>
            </tr>
        );
    }

    return <tr style={{ height, width }}>
        <TableCell width={17.5} className="text-truncate">{longAssetName}</TableCell>
        <TableCell width={12.5} className="text-truncate">{providerName}</TableCell>
        <TableCell width={7.5} className="text-truncate">{finexFundCode}</TableCell>
        <TableCell width={7.5} className="text-truncate">{citicode}</TableCell>
        <TableCell width={7.5} className="text-truncate">{sedol}</TableCell>
        <TableCell width={5} className="text-truncate">{sectorCode}</TableCell>
        <TableCell width={3.5}><IncomeOrAccumulationBadge value={incomeOrAccumulation} /></TableCell>
        <TableCell width={5} className="text-truncate">{finexSubcategory}</TableCell>
        <TableCell width={5} className="text-truncate">{currency}</TableCell>
        <TableCell width={5} className={classnames("text-center")}><PriceIndexBadge value={priceIndex} /></TableCell>
        <TableCell width={5}>{midPrice != null ? <NumericFormat displayType={"text"} value={midPrice} /> : <span className="px-2">-</span>}</TableCell>
        <TableCell width={5}>{bidPrice != null ? <NumericFormat displayType={"text"} value={bidPrice} /> : <span className="px-2">-</span>}</TableCell>
        <TableCell width={5}>{offerPrice != null ? <NumericFormat displayType={"text"} value={offerPrice} /> : <span className="px-2">-</span>}</TableCell>
        <TableCell className="text-truncate" width={8}> {moment(priceDate).isValid() ? <DateDisplay>{priceDate}</DateDisplay> : <span className="px-2">-</span>}</TableCell>
        <TableCell width={1} align="right">
            <AssetsTableRowActionCol
                deleteAsset={() => setAssetIdToDelete(assetId)}
                editAsset={() => setAssetIdToEdit(assetId)}
            />
        </TableCell>
    </tr>;
};

export default AssetsTableRow;