import React, { } from "react";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import DebtorTableRowActionCol from "./DebtorTableRowActionCol";
import Skeleton from "react-loading-skeleton";
import { FixedCell } from "../../../components/tables/StandardTable";
import { DateDisplay } from "../../../components";

const DebtorTableRow = ({
    index,
    data,
    style: { height, width }
}) => {

    const {
        isClearing,
        setChosenGrouping,
        results
    } = data;

    const {
        groupId,
        feeDate,
        feeTypeDescription,
        ///providerId,
        providerName,
        totalFeeValuePounds,
        debt,
        masterAccountDisplayName,
        applicationReferenceId,
        applicationDescription
    } = results[index] || {};

    if (!results[index] || isClearing) {
        return <tr style={{ height, width }}>
            <td colSpan={9}>
                <Skeleton height={32} />
            </td>
        </tr>
    }

    return (
        <tr style={{ height, width }}>
            <FixedCell width={7.5} className="text-truncate">{moment(feeDate).isValid() ? <DateDisplay>{feeDate}</DateDisplay> : <span className="px-2">-</span>}</FixedCell>
            <FixedCell width={10} className="text-truncate">{feeTypeDescription}</FixedCell>
            {/*<FixedCell width={15} className="text-truncate">{providerName}</FixedCell>*/}
            <FixedCell width={10} className="text-truncate">
                <NumericFormat
                    displayType="text"
                    value={totalFeeValuePounds}
                    thousandSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="£"
                />
            </FixedCell>
            <FixedCell width={10} className="text-truncate">
                <NumericFormat
                    displayType="text"
                    value={debt}
                    thousandSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="£"
                />
            </FixedCell>
            <FixedCell width={15} className="text-truncate">{masterAccountDisplayName}</FixedCell>
            <FixedCell width={10} className="text-truncate">{applicationReferenceId}</FixedCell>
            <FixedCell width={20} className="text-truncate">{applicationDescription}</FixedCell>
            <FixedCell width={2.5} className="text-truncate">
                <DebtorTableRowActionCol
                    setChosenGrouping={() => setChosenGrouping(groupId)}
                />
            </FixedCell>
        </tr >
    );
};

export default DebtorTableRow;