import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { readableColor } from "polished";
import { Button, DateDisplay } from "../../../components";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BasicToast } from "../../../components/toasts";
import { useOverwriteReview } from "../../revieweditmodal/hooks/OverwriteReviewContext";
import { StyledNavigationTab, StyledNavigationTabIconContainer } from "../../../components/navigation/NavigationTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, TabScreen } from "react-tabs-scrollable";
import OverwriteReview from "./OverwriteReview";
import OverwriteLogs from "./OverwriteLogs";

const ModalBody = styled(Modal.Body)`
    min-height: 66.67vh;
    max-height: 66.67vh;
    display: flex;
    flex-direction: column;
    padding: 0;
`;

const NavigationContainer = styled.div`
    padding: 1rem 1rem 0 1rem;
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};
`;

const NavigationTab = React.forwardRef(({ icon, label, ...rest }, ref) => (
    <StyledNavigationTab ref={ref} {...rest}>
        <StyledNavigationTabIconContainer>
            <FontAwesomeIcon icon={icon} />
        </StyledNavigationTabIconContainer>
        <span>{label}</span>
    </StyledNavigationTab>
));

const EditReviewModal = () => {

    const [activeTab, setActiveTab] = React.useState(0);

    const onTabClick = (e, i) => {
        e.preventDefault();
        setActiveTab(i);
    };

    const {
        clear,
        isLoading,
        isPatching,
        isEditReviewModalActive,
        overwriteReview : review,
        overwriteLogs,
        saveChanges,
      } = useOverwriteReview();

    const formDetails = useForm({ defaultValues: {overviewComment: "", ...review, }});
    
    const onSubmit = (data) => {
        const { dirtyFields } = formDetails.formState;
        if (!dirtyFields || Object.keys(dirtyFields).length === 0) {
            clear();
        }
        
        const operations = Object.keys(dirtyFields).map((el) => {
            return { op: "replace", path: `/${el}`, value: formDetails.getValues(el) };
        });
        
        if (!operations || operations.length === 0) {
            clear();
        }
        else {   

            saveChanges(operations, data.reviewOverwriteComment).then(
                r => {
                    toast.custom((t) => <BasicToast 
                    control={t} 
                    title="Review Admin Overwrite" 
                    message={`Review has been overwritten.`}
                    />, { duration: 8000 });
                },
                e => {
                    toast.error(e?.data);
                }
            )
            .finally(clear);
        }
    };
    
    React.useEffect(() => {
        if (!review) {
            return;
        }
        formDetails.reset(review);
    }, [review]);
    
    return (
        <Modal  size="xl" centered backdrop="static" className="overflow-visible" show={isEditReviewModalActive}>
            <Modal.Header>
                <Modal.Title>Review Admin Override - {review?.masterAccountName} (<DateDisplay>{review?.reviewDate}</DateDisplay>)</Modal.Title>
            </Modal.Header>
            <form onSubmit={formDetails.handleSubmit(onSubmit)}>
                <ModalBody>
                    <NavigationContainer>
                        <Tabs 
                            activeTab={activeTab} 
                            onTabClick={onTabClick}
                            leftBtnIcon={<FontAwesomeIcon icon="fa-chevron-left" />}
                            rightBtnIcon={<FontAwesomeIcon icon="fa-chevron-right" />}
                        >
                            <NavigationTab icon="fa-pencil" label="Edit" />
                            <NavigationTab icon="fa-list" label="Logs" />
                        </Tabs>
                    </NavigationContainer>
                    <TabScreen activeTab={activeTab} index={0} className="overflow-auto">
                        <OverwriteReview formDetails={formDetails} />
                    </TabScreen>
                    <TabScreen activeTab={activeTab} index={1} className="overflow-auto">
                        <OverwriteLogs overwriteLogs={overwriteLogs} />
                    </TabScreen>
                </ModalBody>
                <Modal.Footer>
                    <Button variant="success" type="submit" disabled={isLoading || isPatching || activeTab !== 0}>
                        Save Changes
                    </Button>
                    <Button variant="light" onClick={clear}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default EditReviewModal;