import React, { useCallback } from "react";
import { FormLabel, FormTextArea } from "../../../components/forms";
import { Button, DateDisplay } from "../../../components";
import ReviewAppointmentStatusSelect from "./ReviewAppointmentStatusSelect";
import { usePersonalOutlookContext } from "../../../hooks/PersonalOutlookContext";
import useDiaryEvent from "../hooks/useDiaryEvent";
import toast from "react-hot-toast";
import { IconButton } from "../../../components";
import { useClientContext } from "../../../hooks/ClientContext";
import TargetDate from "./TargetDate";
import { CenterFormColumnComponent } from "../../../components/utilities";

const ReviewAppointment = ({ review, onFieldChanged, rearrange }) => {

    const { id, account } = useClientContext();

    const {
        eventModal: { setProps },
        selectedCalendarUserId,
        setSelectedCalendarUserId,
    } = usePersonalOutlookContext();

    const {
        getEventById
    } = useDiaryEvent();

    const mapToFormDataModel = useCallback((eventModel) => {
        return {
            id: eventModel.id,
            organizer: eventModel.organizerUserId,
            attendees:
                eventModel.attendees.map(({ emailAddress }) => ({
                    label: emailAddress.name ?? emailAddress.address,
                    value: emailAddress.address,
                })) ?? [],
            description: eventModel.description ?? null,
            startDate: eventModel.startDate ?? null,
            duration: eventModel.duration ?? null,
            masterAccount:
                eventModel.masterAccountId && eventModel.masterAccountName
                    ? {
                        label: eventModel.masterAccountName,
                        value: eventModel.masterAccountId,
                    }
                    : null,
            project: eventModel.projectId,
            meetingType: eventModel.meetingType ?? null,
            meetingLocation: eventModel.meetingLocation ?? null,
            body: eventModel.body ?? null
        };
    }, []);

    const onEditEventClicked = (e) => {
        if (typeof e?.preventDefault === "function") {
            e.preventDefault();
        }

        setProps((prev) => ({ show: true, isNew: false, isLoading: true, initialId: review.appointmentId }));
        getEventById(review.appointmentOrganiserId, review.appointmentId)
            .then(
                (res) => {
                    if (!res)
                        return toast.error("Unable to get event.");

                    // when an event is successfully loaded
                    // if the event loaded is not the same as the current event, don't update update the form
                    setProps((prev) => ({
                        ...prev,
                        initialData: res?.id !== prev?.initialId ? prev.initialData : mapToFormDataModel(res),
                        isLoading: false
                    }));

                    setSelectedCalendarUserId(review.appointmentOrganiserId);
                },
                (err) => toast.error("Unable to get event.")
            );
    };

    return (
        <React.Fragment>

            <div className="row mt-4">
                <div className="col-2">
                    <ReviewAppointmentStatusSelect
                        label="Review Appointment"
                        defaultValue={review.appointmentStatus}
                        onBlur={(selection) =>
                            onFieldChanged(
                                "appointmentStatus",
                                selection.value
                            )
                        }
                        isDisabled={!review.appointmentRequired}
                    />
                </div>

                <div className="col">
                    <FormTextArea
                        label="Comment"
                        value={review.appointmentStatusComment}
                        onBlur={(value) =>
                            onFieldChanged("appointmentStatusComment", value)
                        }
                        disabled={!review.appointmentRequired}
                    />
                </div>

                <div className="col-2 align-items-end">
                    <FormLabel>Review Appointment</FormLabel>
                    {review.appointmentId == null && (
                        <Button
                            variant="primary"
                            className="w-100"
                            onClick={() =>
                                setProps({
                                    show: true,
                                    isNew: true,
                                    initialData: {
                                        meetingType: 0,
                                        description: "Review Appointment",
                                        masterAccount: {
                                            label: account.accountName,
                                            value: id,
                                        },
                                        project: review.projectId,
                                        reviewId: review?.reviewId
                                    },
                                })
                            }
                            disabled={!review.appointmentRequired || review?.reviewStatus === 1 || review?.reviewStatus === 2}>
                            Schedule
                        </Button>
                    )}
                    {review.appointmentId != null && (
                        <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => onEditEventClicked()}
                            disabled={!review.appointmentRequired}
                        >
                            Edit
                        </Button>
                    )}
                </div>

                <div className="col-md-auto d-flex align-items-end mb-1">
                    <CenterFormColumnComponent>
                        <IconButton
                            className="col-auto"
                            icon={
                                review.appointmentRequired ? "fa-x" : "fa-check"
                            }
                            variant={
                                review.appointmentRequired
                                    ? "danger"
                                    : "success"
                            }
                            onClick={(_) =>
                                onFieldChanged(
                                    "appointmentRequired",
                                    !review.appointmentRequired
                                )
                            }
                        />
                    </CenterFormColumnComponent>
                </div>
            </div>

            <div className="row">
                <div className="col-3">
                    {review.appointmentScheduledByClientDate && (
                        <FormLabel>
                            Scheduled by client on
                            <DateDisplay
                                className="ps-1"
                                format="DD/MM/YYYY HH:mm"
                            >
                                {review.appointmentScheduledByClientDate}
                            </DateDisplay>
                        </FormLabel>
                    )}
                    {review.appointmentScheduledByClientDate == null &&
                        review.appointmentScheduledDate && (
                            <FormLabel>
                                Scheduled by {review.appointmentScheduledBy} on
                                <DateDisplay
                                    className="ps-1"
                                    format="DD/MM/YYYY HH:mm"
                                >
                                    {review.appointmentScheduledDate}
                                </DateDisplay>
                            </FormLabel>
                        )}
                </div>

                <div className="col-6">
                    {review.appointmentStyleText && review.appointmentDate && (
                        <FormLabel>
                            {review.appointmentStyleText} scheduled for
                            <DateDisplay
                                className="ps-1"
                                format="DD/MM/YYYY HH:mm"
                            >
                                {review.appointmentDate}
                            </DateDisplay>
                        </FormLabel>
                    )}
                </div>

                <div className="col-3 d-flex justify-content-end">
                    {review.appointmentScheduledTargetDate && (
                        <TargetDate
                            date={review.appointmentScheduledDate}
                            targetDate={review.appointmentScheduledTargetDate}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReviewAppointment;
