import { applyPatch } from 'fast-json-patch';
import { ControllerFetchType } from '../../helpers/fetchTypes';
import { wealthProApi } from '../../redux/api';

const productsApiEndpoints = wealthProApi.enhanceEndpoints({

}).injectEndpoints({
    endpoints: (builder) => ({
        createProduct: builder.mutation({
            query: ({ request }) => ({
                url: `api/products/product`,
                method: 'POST',
                body: request
            }),
            async onQueryStarted({ request, fetchType, sort }, { dispatch, queryFulfilled }) {
                try {
                    console.log(request)
                    var result = await queryFulfilled;
                    var { data } = result;
                    var { id } = data;

                    dispatch(wealthProApi.util.updateQueryData('fetchProducts', { fetchType, sort }, (data) => {
                        return [...data, {
                            value: id,
                            label: request.productName
                        }];
                    }));

                    dispatch(wealthProApi.util.updateQueryData('fetchProductsForGrid', {}, ({ results, totalCount, ...rest }) => {
                        return {
                            results: [data, ...results],
                            totalCount: totalCount + 1,
                            ...rest
                        };
                    }));
                }
                catch { }
            }
        }),
        deleteProduct: builder.mutation({
            query: ({ productId }) => ({
                url: `api/products/product/${productId}`,
                method: 'DELETE',
            }),
            async onQueryStarted({ productId }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('fetchProductsForGrid', {}, ({ results, totalCount, ...rest }) => {
                    return {
                        results: results.filter(x => x.productId !== productId),
                        totalCount: totalCount - 1,
                        ...rest
                    };
                }));

                try {
                    await queryFulfilled;
                }
                catch {
                    result.undo();
                }
            }
        }),
        fetchProducts: builder.query({
            query: ({ fetchType, sort = "ProductName" }) => `api/products/product?fetchType=${fetchType}&sort=${sort}`,
        }),
        fetchProductsForGrid: builder.query({
            query: ({ next, request }) => ({
                url: `api/products/product/grid${next ? "?next=" + next : ""}`,
                method: 'POST',
                body: request
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                const { next: currentNext, previous: currentPrevious } = currentCache || {};
                const { next, previous } = newItems || {};

                const isSamePage = next === currentNext;
                const isPageOne = !previous;

                if (isSamePage || isPageOne) {
                    return newItems;
                }

                currentCache.next = next;
                currentCache.previous = newItems.previous;
                currentCache.totalCount = newItems.totalCount;
                currentCache.results.push(...newItems.results);
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                const { next: currPage } = currentArg || { next: 1 };
                const { next: prevPage } = previousArg || { next: 0 };
                return currPage !== prevPage;
            },
        }),
        searchProducts: builder.query({
            // refetches on search term change so manual cache updates may not be needed to update results for this?
            query: ({ searchTerm, page, limit, fetchType = ControllerFetchType.Object, sort = "ProductName" }) => {
                if (page || limit) {
                    return `api/products/product/search?searchTerm=${searchTerm}&page=${page}&limit=${limit}&fetchType=${fetchType}&sort=${sort}`;
                }

                return `api/products/product/search?searchTerm=${searchTerm}&fetchType=${fetchType}&sort=${sort}`;
            },
        }),
        getProductById: builder.query({
            query: ({ productId }) => `api/products/product/${productId}`,
        }),
        patchProduct: builder.mutation({
            query: ({ productId, operations }) => ({
                url: `api/products/product/${productId}`,
                method: 'PATCH',
                body: operations
            }),
            async onQueryStarted({ productId, operations, assignableObject }, { dispatch, queryFulfilled }) {
                const result = dispatch(wealthProApi.util.updateQueryData('getProductById', { productId }, (data) => {
                    applyPatch(data, operations, true);
                }));

                const gridResult = dispatch(wealthProApi.util.updateQueryData('fetchProductsForGrid', {}, ({ results, totalCount, ...rest }) => {
                    var item = results.find(x => x.productId === productId);
                    applyPatch(item, operations, true);

                    if (assignableObject) {
                        Object.assign(item, assignableObject);
                    }
                }));

                console.log(gridResult);
                try {
                    await queryFulfilled;
                }
                catch {
                    gridResult.undo();
                    result.undo();
                }
            }
        })
    })
});

export const {
    useDeleteProductMutation,
    useGetProductByIdQuery,
    useLazyGetProductByIdQuery,
    useFetchProductsQuery,
    useLazyFetchProductsForGridQuery,
    useLazyFetchProductsQuery,
    useLazySearchProductsQuery,
    usePatchProductMutation,
    useCreateProductMutation
} = productsApiEndpoints;