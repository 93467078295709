import toast from "react-hot-toast";
import { patchReplace } from "../../../helpers/patchDoc";
import { showUploadEvidenceModal, UploadEvidenceForm } from "../components/UploadEvidenceModal";
import { useHoPatchRecommendationMutation, useHoTriggerAdministrationMutation } from "../../../services/headofficeadmin";

export type UseUpdateBusinessSheetOptions = {
    updateStatus: (businessSheetId: string, status: number) => Promise<boolean>,
    uploadEvidence: (masterAccountId: string, businessSheetId: string, status: number) => Promise<boolean>
};

export const useUpdateBusinessSheet = () : UseUpdateBusinessSheetOptions => {
    const [patchRecommendation] = useHoPatchRecommendationMutation();
    const [triggerAdministration] = useHoTriggerAdministrationMutation();

    const onUpdateStatus = (businessSheetId: string, status: number) : Promise<boolean> => new Promise<boolean>((resolve) => {
        let operations = [patchReplace('status', status)];
        return toast.promise(patchRecommendation({ businessSheetId, operations }).unwrap(), {
            loading: 'Updating Recommendation Status',
            success: () => {
                resolve(true)
                return `Recommendation updated.`;
            },
            error: (err: Error) => {
                resolve(false);
                return `There was a problem updating the Recommendation. ${err.message}`
            }
        });
    });

    const onUploadEvidence = (masterAccountId: string, businessSheetId: string, status: number) : Promise<boolean> => new Promise<boolean>((resolve) => {
        return showUploadEvidenceModal({ businessSheetId, masterAccountId, status }).then(
            (r) => {
                const { file, document } = r as UploadEvidenceForm;
                if (!file && !document) {
                    toast.error('Could not send to administration. Invalid evidence file.');
                    resolve(false);
                    return;
                }

                // setup the form that lets us post the file data (if we need too!)
                var request = new FormData();
                try {
                    if (file) {
                        request.append(file.name, file, file.name);
                    }
                    if (document) {
                        for (var key in document) {
                            request.append(key, document[key]);
                        }
                    }
                }
                catch {
                    resolve(false);
                    return;
                }
                
                // make the request to update and upload the file
                toast.promise(triggerAdministration({ businessSheetId, request }).unwrap(), {
                    loading: 'Sending to administration',
                    success: () => {
                        resolve(true)
                        return `Successfully sent though to administration`;
                    },
                    error: (err: Error) => {
                        resolve(false);
                        return `There was a problem sending through to administration. ${err.message}`
                    }
                })
            },
            e => resolve(false)
        );
    });

    return {
        updateStatus: onUpdateStatus,
        uploadEvidence: onUploadEvidence
    };
};

export default useUpdateBusinessSheet;