import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, DateDisplay, ProfileDisplay } from "../../../components";
import { FormCheck } from "../../../components/forms";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { ThemedParagraph } from "../../../components/utilities";
import { MessagePriorities, ReminderStatuses } from "../../../helpers/constants";
import { useClientContext } from "../../../hooks/ClientContext";
import EventsTableActionColumn from "../../clienteventstable/components/EventsTableActionColumn";
import { CreateReminderModal } from "../../createreminderform";
import useReminder from "../hooks/useReminder";
import EditReminderModal from "./EditReminderModal";

const ReminderListItemWrapper = styled(Card)`
    background-color: ${({ cancelled }) => cancelled ? '#e9ecef' : 'transparent'};
    opacity: ${({ cancelled }) => cancelled ? 0.5 : 1};
    position: relative;
  
    .dropdown-menu {
        opacity: 1;
        z-index: 1051;
    }
`;

const ReminderPriorityCell = ({ value, isInfoOnly, className }) => {

    if (!value || isInfoOnly) return null;;

    const priorityClassName = {
        'bg-priority-high text-priority-high': value === MessagePriorities.High,
        'bg-priority-medium text-priority-medium': value === MessagePriorities.Medium,
        'bg-priority-low text-priority-low': value === MessagePriorities.Low
    };

    return <div className={classNames("badge py-1 px-2 mx-1", className, priorityClassName)}>
        {{
            [MessagePriorities.Low]: "Low",
            [MessagePriorities.Medium]: "Medium",
            [MessagePriorities.High]: "High"
        }[value]}
    </div>;
};

export const ReminderDisplayRecord = ({ selectReminder, record, displayProject = false, fetchQuery }) => {
    const navigate = useNavigate();

    const {
        patchReminder
    } = useReminder(record.id, fetchQuery?.cacheKey);

    const handleOpenProject = (e) => {
        if (e && typeof (e.preventDefault) === 'function') {
            e.preventDefault();
        }
        navigate(`/client/${record.masterAccountId}/projects?projectId=${record.projectId}`, { relative: 'route' });
    };

    const getDateData = (date) => {
        const dateMoment = moment(date);

        return {
            date: dateMoment,
            format: dateMoment.hours() === 0 && dateMoment.minutes() === 0
                ? "DD/MM/YYYY"
                : "HH:mm DD/MM/YYYY"
        };
    }

    const eventDate = useMemo(() => getDateData(record.deadlineDate), [record.deadlineDate]);
    const createdDate = useMemo(() => getDateData(record.createdDate), [record.createdDate]);

    const cardTitle = useMemo(() => {
        const type = record.type?.trim();
        const description = record.description?.trim();

        return [type, description]
            .filter(Boolean)
            .join(" - ");
    }, [record.description, record.type]);

    return (
        <ReminderListItemWrapper cancelled={record.status === ReminderStatuses.Cancelled}>
            <div className="card-header">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="me-3">{cardTitle} @ <DateDisplay format={eventDate.format}>{eventDate.date}</DateDisplay></div>
                    <span className="d-flex">
                        <ReminderPriorityCell value={record.priority} />
                        <EventsTableActionColumn
                            className="ms-2"
                            data={record}
                            updateReminder={patchReminder}
                            setSelectedReminder={selectReminder}
                        />
                    </span>
                </div>
                {displayProject === true && record.projectId != null && (
                    <div className="font-size-sm">
                        <button className="anchor" onClick={handleOpenProject}>{record.project}</button>
                    </div>
                )}
            </div>
            {record.notes && record.notes.length > 0 && (
                <ThemedParagraph className="font-size-sm card-body" variant="muted">{record.notes}</ThemedParagraph>
            )}
            <div className={classNames("d-flex justify-content-between font-size-sm card-footer", { "border-top-0": !record.notes?.length })}>
                <ProfileDisplay
                    userId={record.userId}
                />
                <DateDisplay format={createdDate.format}>{createdDate.date}</DateDisplay>
            </div>
        </ReminderListItemWrapper>
    );
};

const ReminderDisplayList = ({ data, displayProject = false, error, isLoading, projectId, originalArgs, showCancelled, setShowCancelled, refetch }) => {
    const { id: masterAccountId } = useClientContext();
    const { results } = data || { results: [] };
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [selectedReminder, setSelectedReminder] = useState(null);

    const showEditModal = useMemo(() => selectedReminder !== null, [selectedReminder]);

    const onSelectReminderRecord = (record) => {
        setSelectedReminder(record);
    }

    const onHideEditModal = () => setSelectedReminder(null);

    if (isLoading === true) {
        return <Skeleton height={32} count={3} />
    }

    if (error) {
        return (
            <ErrorMessageWrapper margin="0px auto" maxWidth="500px">
                <ErrorMessage error={error} retryCallback={() => refetch(originalArgs)} />
            </ErrorMessageWrapper>
        );
    }

    return (
        <React.Fragment>
            <CreateReminderModal
                show={showCreateModal}
                showUserSelect={true}
                masterAccountId={masterAccountId}
                projectId={projectId}
                onHide={() => setShowCreateModal(false)}
            />
            <EditReminderModal
                show={showEditModal}
                onHide={onHideEditModal}
                reminder={selectedReminder}
            />
            <div>
                <div className="d-flex justify-content-end mb-3">
                    <FormCheck
                        groupClassName="me-3"
                        type="checkbox"
                        label="Show Cancelled"
                        isChecked={showCancelled}
                        onChange={() => setShowCancelled(!showCancelled)}
                        disableAnimations
                    />
                    <Button size={"sm"} variant="success" onClick={() => setShowCreateModal(true)}>
                        <FontAwesomeIcon icon="fa-plus" size={"sm"} /> Create new Event
                    </Button>
                </div>
                <div className="d-flex flex-column row gy-2 gx-0 px-0">
                    {(!results || results.length < 1) && (
                        <p className="m-0">There are currently no events available.</p>
                    )}
                    {results && results.length > 0 && results.map((el, i) => (
                        <ReminderDisplayRecord
                            key={i}
                            record={el}
                            selectReminder={onSelectReminderRecord}
                            displayProject={displayProject}
                            fetchQuery={originalArgs}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReminderDisplayList;