import React from "react";
import {
    FormLabel,
    FormTextArea,
    CurrencyInput,
    DateInput
} from "../../../components/forms";
import PortfolioValuationStatusSelect from "../../../components/forms/selects/PortfolioValuationStatusSelect";
import { DateDisplay, IconButton } from "../../../components";
import _ from "lodash"
import TargetDate from "./TargetDate";
const PortfolioValuation = ({ review, onFieldChanged, amberWarning = 5 }) => {

    return (
        <React.Fragment>
            <div className="row mt-4">
                <div className="col-3">
                    <PortfolioValuationStatusSelect
                        label="Portfolio Valuation"
                        defaultValue={review.verificationStatus}
                        onBlur={(selection) =>
                            onFieldChanged(
                                "verificationStatus",
                                selection.value
                            )
                        }
                        isDisabled={!review.verificationRequired}
                    />
                </div>
                <div className="col-2">
                    <DateInput
                        label="Verification Date"
                        value={review.verifiedDate}
                        onBlur={(value) => onFieldChanged('verifiedDate', value)}
                        disabled={!review.verificationRequired}
                    />
                </div>
                <div className="col">
                    <FormTextArea
                        label="Comment"
                        value={review.verificationStatusComment}
                        onBlur={(value) =>
                            onFieldChanged("verificationStatusComment", value)
                        }
                        disabled={!review.verificationRequired}
                    />
                </div>

                <div className="col-md-auto d-flex align-items-end mb-1">
                        <IconButton 
                            className="col-auto"
                            icon={review.verificationRequired ? "fa-x" : "fa-check"}
                            variant={review.verificationRequired ? "danger" : "success"}
                            onClick={(_) =>
                                onFieldChanged(
                                    "verificationRequired",
                                    !review.verificationRequired
                                )
                            }
                        />
                </div>
               
                
            </div>
            <div className="row mt-2">
                <div className="col">
                    <CurrencyInput
                        label="New Cash"
                        value={review.newCashInvested}
                        readOnly={review.verificationStatus == 1}
                        onBlur={(value) =>
                            onFieldChanged("newCashInvested", value)
                        }
                        disabled={!review.verificationRequired}
                    />
                </div>
                <div className="col">
                    <CurrencyInput
                        label="New Assets"
                        value={review.newAssetsRegistered}
                        readOnly={review.verificationStatus == 1}
                        onBlur={(value) =>
                            onFieldChanged("newAssetsRegistered", value)
                        }
                        disabled={!review.verificationRequired}
                    />
                </div>
                <div className="col">
                    <CurrencyInput
                        label="Income"
                        value={review.incomeTaken}
                        readOnly={review.verificationStatus == 1}
                        onBlur={(value) => onFieldChanged("incomeTaken", value)}
                        disabled={!review.verificationRequired}
                    />
                </div>
                <div className="col">
                    <CurrencyInput
                        label="Withdrawn"
                        value={review.withdrawnAmount}
                        onBlur={(value) =>
                            onFieldChanged("withdrawnAmount", value)
                        }
                        readOnly={review.verificationStatus == 1}
                        disabled={!review.verificationRequired}
                    />
                </div>
                <div className="col">
                    <CurrencyInput
                        label="Gifted"
                        value={review.giftedAmount}
                        readOnly={review.verificationStatus == 1}
                        onBlur={(value) =>
                            onFieldChanged("giftedAmount", value)
                        }
                        disabled={!review.verificationRequired}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <FormTextArea
                        label="Verification Notes"
                        value={review.verificationNotes}
                        onBlur={(value) =>
                            onFieldChanged("verificationNotes", value)
                        }
                        readOnly={review.verificationStatus == 1}
                        minRows={1.65}
                        maxRows={1.65}
                        disabled={!review.verificationRequired}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    {review.verifiedBy && review.verifiedDate && (
                        <FormLabel>
                            Portfolio verified by {review.verifiedBy} on
                            <DateDisplay
                                className="ps-1"
                                format="DD/MM/YYYY HH:mm"
                            >
                                {review.verifiedDate}
                            </DateDisplay>
                        </FormLabel>
                    )}
                </div>
                <div className="col-6 d-flex justify-content-end">
                    {review.verificationTargetDate && (
                       <TargetDate date={review.verifiedDate} targetDate={review.verificationTargetDate}/>                        
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default PortfolioValuation;
