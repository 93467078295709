import { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { IconButton } from "../../../components";
import { FormSelect } from "../../../components/forms";
import { ThemedTableHeader } from "../../../components/tables/StandardTable";
import { patchReplace } from "../../../helpers/patchDoc";
import { useHouseRules } from "../../../hooks/HouseRulesContext";
import { useCreateClassGroupMutation, useCreateClassificationRangeMutation, useDeleteClassGroupRuleMutation, useDeleteClassificationRangeMutation, useLazyFetchGeneralRulesQuery, usePatchClassGroupRuleMutation, usePatchClassificationRangeMutation, usePatchGeneralRulesMutation } from "../../../services/assets";

const classificationOptions = [
    { label: "Asset Classification", value: 0 },
    { label: "Regional Classification", value: 1 },
    { label: "Volatility Classification", value: 2 },
    { label: "Management Style", value: 3 }
];

// Some utility components for fixing the width of smaller columns
const FixedWidthHeader = styled(ThemedTableHeader)`
    max-width: ${props => props.width}px;
`;

const percentageHeaderRenderer = ({ label, ...props }) => <ThemedTableHeader className="col-1 text-center" {...props} >{label}</ThemedTableHeader>;
const numberHeaderRenderer = ({ label, ...props }) => <ThemedTableHeader className="col-1 text-center" {...props}>{label}</ThemedTableHeader>;
const classificationHeaderRenderer = ({ label, ...props }) => <ThemedTableHeader className="col" {...props}>{label}</ThemedTableHeader>;

// Custom cell component to prevent duplicate Classifications for the same Client Profile
const ClassificationSelectCell = ({ data, fullData, patchFn, options, ...props }) => {
    // Allow options that are EITHER:
    //      a. Not in an existing score range row 
    //          OR
    //      b. The selected option of the current row
    const allowedOptions = useMemo(() =>
        options.filter(classification => classification.value === data.classificationCategory || !fullData.some(range => range.classificationCategory === classification.value)),
        [data.classificationCategory, fullData, options])

    return <FormSelect
        {...props}
        defaultValue={data.classificationCategory}
        options={allowedOptions}
        onBlur={(selection) => patchFn(data.rangeId, "classificationCategory", selection.value)}
    />
}

// Column definitions for the bottom two accordions
const scoreRangeDataColumns = [
    {
        label: "Classification",
        property: "classificationCategory",
        isEditable: true,
        headerRenderer: classificationHeaderRenderer,
        CustomCellComponent: ClassificationSelectCell,
        options: classificationOptions
    },
    {
        label: "Min. Score",
        property: "minScore",
        isEditable: true,
        type: 'number',
        allowNegative: false,
        decimalScale: 0,
        headerRenderer: ({ label, ...props }) => <ThemedTableHeader className="col-2 col-xl-1 text-center" {...props}>{label}</ThemedTableHeader>
    },
    {
        label: "Max. Score",
        property: "maxScore",
        isEditable: true,
        type: 'number',
        allowNegative: false,
        decimalScale: 0,
        headerRenderer: ({ label, ...props }) => <ThemedTableHeader className="col-2 col-xl-1 text-center" {...props}>{label}</ThemedTableHeader>
    },
    { label: "Last Modified", property: "lastModifiedInfoText" }
]

const classGroupDataColumns = [
    { label: "Group Name", property: "name", isEditable: true },
    {
        label: "Min. Group Allocation",
        property: "minGroupAllocation",
        isEditable: true,
        type: 'percent',
        allowNegative: false,
        decimalScale: 1,
        min: 0,
        max: 100,
        headerRenderer: percentageHeaderRenderer
    },
    {
        label: "Max. Group Allocation",
        property: "maxGroupAllocation",
        isEditable: true,
        type: 'percent',
        allowNegative: false,
        decimalScale: 1,
        min: 0,
        max: 100,
        headerRenderer: percentageHeaderRenderer
    },
    {
        label: "Max. Fund Allocation",
        property: "maxFundAllocation",
        isEditable: true,
        type: 'percent',
        allowNegative: false,
        decimalScale: 1,
        min: 0,
        max: 100,
        headerRenderer: percentageHeaderRenderer
    },
    {
        label: "Weighting",
        property: "weighting",
        isEditable: true,
        allowNegative: false,
        type: 'number',
        decimalScale: 2,
        headerRenderer: numberHeaderRenderer
    },
    { label: "Description", property: "description", isEditable: true }
];

const addCreateDeleteColumn = (columns, createFn, deleteFn) => [
    ...columns,
    {
        headerRenderer: () => <FixedWidthHeader width={32}>
            <IconButton icon="plus" variant="success" onClick={createFn} />
        </FixedWidthHeader>,
        deleteFn
    }
]

const useGeneralRules = () => {
    const { currentClientProfile } = useHouseRules();

    // First accordion data management
    const [fetchGeneralRules, { currentData: generalRules, isLoading, isFetching, isError }] = useLazyFetchGeneralRulesQuery({ refetchOnFocus: true });

    useEffect(() => {
        if (!isError)
            fetchGeneralRules({ clientProfileId: currentClientProfile }, true);
    }, [currentClientProfile, fetchGeneralRules, isError]);

    const refetch = useCallback(() =>
        fetchGeneralRules({ clientProfileId: currentClientProfile }).unwrap(),
        [currentClientProfile, fetchGeneralRules])

    const [patchGeneralRulesTrigger] = usePatchGeneralRulesMutation();

    const patchGeneralRules = useCallback((property, value) =>
        patchGeneralRulesTrigger({ clientProfileId: currentClientProfile, operations: [patchReplace(property, value)] }).unwrap(),
        [currentClientProfile, patchGeneralRulesTrigger]);

    // Second accordion data management
    const [createClassificationRangeTrigger, { isLoading: isCreatingClassificationRange }] = useCreateClassificationRangeMutation();

    const createClassificationRange = useCallback(() =>
        createClassificationRangeTrigger({ clientProfileId: currentClientProfile }).unwrap(),
        [currentClientProfile, createClassificationRangeTrigger]);

    const [patchClassificationRangeTrigger] = usePatchClassificationRangeMutation();

    const patchClassificationRange = useCallback((rangeId, property, value) =>
        patchClassificationRangeTrigger({ clientProfileId: currentClientProfile, rangeId, operations: [patchReplace(property, value)] }).unwrap(),
        [currentClientProfile, patchClassificationRangeTrigger]);

    const [deleteClassificationRangeTrigger] = useDeleteClassificationRangeMutation();

    const deleteClassificationRange = useCallback((rangeId) =>
        deleteClassificationRangeTrigger({ clientProfileId: currentClientProfile, rangeId }).unwrap(),
        [currentClientProfile, deleteClassificationRangeTrigger]);

    const classificationRangeColumns = addCreateDeleteColumn(scoreRangeDataColumns, createClassificationRange, deleteClassificationRange);

    // Third accordion data management
    const [createClassGroupRuleTrigger, { isLoading: isCreatingClassGroup }] = useCreateClassGroupMutation();

    const createClassGroupRule = useCallback(() =>
        createClassGroupRuleTrigger({ clientProfileId: currentClientProfile }).unwrap(),
        [currentClientProfile, createClassGroupRuleTrigger]);

    const [patchClassGroupRuleTrigger] = usePatchClassGroupRuleMutation();

    const patchClassGroupRule = useCallback((groupId, property, value) =>
        patchClassGroupRuleTrigger({ clientProfileId: currentClientProfile, groupId, operations: [patchReplace(property, value)] }).unwrap(),
        [currentClientProfile, patchClassGroupRuleTrigger]);

    const [deleteClassGroupRuleTrigger] = useDeleteClassGroupRuleMutation();

    const deleteClassGroupRule = useCallback((groupId) =>
        deleteClassGroupRuleTrigger({ clientProfileId: currentClientProfile, groupId }).unwrap(),
        [currentClientProfile, deleteClassGroupRuleTrigger]);

    const classGroupColumns = addCreateDeleteColumn(classGroupDataColumns, createClassGroupRule, deleteClassGroupRule);

    return {
        // All three Accordions
        generalRules,
        refetch,
        isLoading,
        isFetching,
        isError,

        // First Accordion
        patchGeneralRules,

        // Second Accordion
        createClassificationRange,
        isCreatingClassificationRange,
        patchClassificationRange,
        classificationRangeColumns,

        // Third Accordion
        createClassGroupRule,
        isCreatingClassGroup,
        patchClassGroupRule,
        classGroupColumns
    };
}

export default useGeneralRules;