import { useNavigate } from "react-router-dom";
import { ProfileDisplay } from "../../../components";
import { FixedCell, StandardTableSortableHeader, ThemedTableHeader } from "../../../components/tables/StandardTable";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";

export const ClientsSummaryHeader = ({ getSortProps }) => (
    <thead>
        <tr>
            <StandardTableSortableHeader {...getSortProps("adviserName")}>
                Adviser
            </StandardTableSortableHeader>
            <ThemedTableHeader className="text-truncate sticky-header">
                Ref
            </ThemedTableHeader>
            <StandardTableSortableHeader {...getSortProps("masterAccountName")}>
                Account Name
            </StandardTableSortableHeader>
            <StandardTableSortableHeader {...getSortProps("targetProfileId")}>
                Target Profile
            </StandardTableSortableHeader>
            <StandardTableSortableHeader {...getSortProps("portfolioId")}>
                Portfolio
            </StandardTableSortableHeader>
            <StandardTableSortableHeader {...getSortProps("totalValue")} className="text-end">
                Value
            </StandardTableSortableHeader>
        </tr>
    </thead>
);

const ClientsSummaryRow = ({ data, index, style }) => {
    const { height } = style;

    const navigate = useNavigate();

    if (index >= data.length)
        return <TableLoadingRow style={{ height }} />;

    const {
        adviserId,
        masterAccountId,
        masterAccountReference,
        masterAccountName,
        targetProfileName,
        portfolioName,
        totalValue
    } = data[index];

    const adviserColumn = adviserId == null
        ? "Not Set"
        : ProfileDisplay({ userId: adviserId }) == null
            ? "Unknown Adviser"
            : <ProfileDisplay userId={adviserId} />;

    return <tr style={{ height }}>
        <FixedCell className="text-truncate align-middle" key={`row-col-${index}-adviser`} width={20}>
            {adviserColumn}
        </FixedCell>
        <FixedCell className="text-truncate align-middle" key={`row-col-${index}-reference`} width={10}>
            {masterAccountReference}
        </FixedCell>
        <FixedCell className="text-truncate align-middle" key={`row-col-${index}-masterAccountName`} width={30}>
            <button className="anchor" onClick={() => navigate(`/client/${masterAccountId}`)}>{masterAccountName}</button>
        </FixedCell>
        <FixedCell className="text-truncate align-middle" key={`row-col-${index}-targetProfile`} width={15}>
            {targetProfileName}
        </FixedCell>
        <FixedCell className="text-truncate align-middle" key={`row-col-${index}-standardPortfolio`} width={15}>
            {portfolioName}
        </FixedCell>
        <FixedCell className="text-truncate align-middle text-end" key={`row-col-${index}-totalValue`} width={10}>
            {totalValue.toLocaleString("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 })}
        </FixedCell>
    </tr>;
}

export default ClientsSummaryRow;