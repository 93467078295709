import { useNavigate } from "react-router-dom";

export type OpenApplicationSearchProps = {
    buildUrl: (reference: string) => string,
    open: (reference: string) => void
};

export const useOpenApplicationSearch = () : OpenApplicationSearchProps => {
    const navigate = useNavigate();
    
    const buildUrl = (reference: string) : string => {
        return `/administration/applications?reference=${reference}`;
    };

    const handleOpen = (reference: string) : void => {
        let url = buildUrl(reference);
        navigate(url);
    };

    return {
        buildUrl,
        open: handleOpen
    };
};

export default useOpenApplicationSearch;