export const BusinessSheetStatuses = {
    AwaitApproval: 0,
    AdviserChasing: 1,
    OnHold: 2,
    Administration: 3,
    CloseLoop: 4,
    NotTakenUp: 5,
};

export type BusinessSheetStatusType = {
    label: string,
    value: string
};