import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom"; 
import { useIntroducers } from "../hooks/IntroducersContext";
import { DateDisplay } from "../../../components";

const IntroducersTableRowBaseCell = styled.td`
    vertical-align: middle;
    text-align: ${(props) => props.textAlign ?? "left"};
`;

const IntroducersTableRowActionColParent = styled.td`
    cursor: pointer;
    vertical-align: middle;
    text-align: ${(props) => props.textAlign ?? "left"};
`;

const TableRowActionColToggle = React.forwardRef(
    ({ ...rest }, ref) => {
        return (
            <div ref={ref} {...rest}>
                <FontAwesomeIcon icon="fa-ellipsis" />
            </div>
        );
    }
);

const EditActionMenuItem = ({ introducer}) => {
    const { editIntroducer } = useIntroducers();
    return <Dropdown.Item onClick={() => {
        editIntroducer(introducer)
    }}>Edit</Dropdown.Item>;
};

const IntroducersTableRowActionCol = ({ introducer }) => {
    const menuId = React.useId();
    return (
        <IntroducersTableRowActionColParent>
            <Dropdown className="caret-off text-center">
                <Dropdown.Toggle
                    id={menuId}
                    as={TableRowActionColToggle}
                />
                <Dropdown.Menu>
                    <EditActionMenuItem introducer={introducer} />
                </Dropdown.Menu>
            </Dropdown>
        </IntroducersTableRowActionColParent>
    );
};

const IntroducerTableRow = ({ data, index, style, ...rest }) => {
    const { height } = style;
    const navigator = useNavigate();

    // do not return a data row when the data has not yet been collected from the backend
    if (index >= data.length) {
		return <tr>
            <td style={{ height }} colSpan={7}>Loading...</td>
        </tr>
	}

    const {
        introducerId,
        profileGroupName, 
        contact,
        profession,
        company,
        status,
        statusModifiedDate,
        adviser
    } = data[index] || {};

    
    return (
        <tr>
            <IntroducersTableRowBaseCell>
                <span>{contact}</span>
            </IntroducersTableRowBaseCell>
            <IntroducersTableRowBaseCell>
                <span>{company}</span>
            </IntroducersTableRowBaseCell>
            <IntroducersTableRowBaseCell>
                <span>{profession}</span>
            </IntroducersTableRowBaseCell>
            <IntroducersTableRowBaseCell>
                <span>{profileGroupName}</span>
            </IntroducersTableRowBaseCell>
            {/* <IntroducersTableRowBaseCell>
                <span>{team}</span>
            </IntroducersTableRowBaseCell> */}
            <IntroducersTableRowBaseCell>
                <span>{adviser}</span>
            </IntroducersTableRowBaseCell>
            <IntroducersTableRowBaseCell>
                <span>{status}</span>
            </IntroducersTableRowBaseCell>
            <IntroducersTableRowBaseCell>
                <DateDisplay>{statusModifiedDate}</DateDisplay>
            </IntroducersTableRowBaseCell>
            <IntroducersTableRowActionCol
                introducer={data[index]}
            />
        </tr>
    );
};

export default IntroducerTableRow;