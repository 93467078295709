import { useSearchFeeGroupTotalsQuery } from "../../../services/headofficeadmin";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useAdministrationFeesProvider } from "../../../hooks/AdministrationFeesContext";

const useFeeTotals = (searchType) => {

    const {filters, currentSearchType} = useAdministrationFeesProvider();
    const { data, isLoading, isFetching, isError, error, refetch } = useSearchFeeGroupTotalsQuery({
        searchType:currentSearchType,
        filter:{
        ...filters,
        adviserIds: filters?.advisers?.map(x => x.value) ?? [],
        introducerIds: filters?.introducers?.map(x => x.value) ?? [],
        masterAccountIds: filters?.masterAccounts?.map(x => x.value) ?? [],
        providerIds: filters?.providers?.map(x => x.value) ?? [],
    }});

    const retryGetFeeTotals = (e) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }

        refetch();
    }

    return {
        data,
        isFetching,
        isLoading,
        isError,
        error,
        retryGetFeeTotals
    }
};

export default useFeeTotals;