import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { useForm } from "react-hook-form";
import { Button } from "../../components";
import { ThemedSpan } from "../../components/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmModal } from "../../components/modals";
import { usePersonalOutlookContext } from "../../hooks/PersonalOutlookContext";
import EventForm from "./components/EventForm";
import useEvents, { MeetingLocations, MeetingTypes } from "./hooks/useEvents";
import toast from "react-hot-toast";
import { BasicToast } from "../../components/toasts";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

export {
    EventForm
};

const EventModal = ({
    isNew = true,
    initialData,
    isLoading,
    queryProps,
    show,
    sendSuccessCallbackFn = () => { }
}) => {

    const {
        eventModal: {
            setProps
        },
        selectedCalendarUserId
    } = usePersonalOutlookContext();

    const {
        createEvent,
        createResult: { isLoading: isCreating },
        cancelEvent,
        cancelResult,
        getMasterAccountEventPreferences,
        accountEventPreferencesResult: { isError: isAccountEventPreferencesError },
        updateEvent,
        updateResult: { isLoading: isUpdating }
    } = useEvents(queryProps);

    // we want a different state for the update as we only send down properties that have changed...
    const [updateRequest, setUpdateRequest] = useState({});

    const [showConfirmCancelEventModal, setShowConfirmCancelEventModal] = useState(false);
    const [showConfirmCancelChangesModal, setShowConfirmCancelChangesModal] = useState(false);
    const [showConfirmSaveChangesModal, setShowConfirmSaveChangesModal] = useState(false);
    const [showVideoMeetingWarning, setShowVideoMeetingWarning] = useState(false);

    const formDefaultValues = useMemo(() => ({
        organizer: initialData?.organizer ?? selectedCalendarUserId,
        attendees: initialData?.attendees ?? [],
        description: initialData?.description ?? null,
        startDate: initialData?.startDate ?? null,
        endDate: initialData?.endDate ?? null,
        masterAccount: initialData?.masterAccount ?? null,
        project: initialData?.project ?? null,
        meetingType: initialData?.meetingType ?? null,
        meetingLocation: initialData?.meetingLocation ?? null,
        body: initialData?.body ?? null,
        duration: initialData?.duration ?? 60,
        reviewId: initialData?.reviewId ?? null
    }), [initialData, selectedCalendarUserId]);

    const { setValue, ...formProperties } = useForm({ values: formDefaultValues });

    const { watch, handleSubmit, reset, formState: { errors } } = formProperties;

    const currentMeetingLocation = watch("meetingLocation");
    const currentMeetingType = watch("meetingType");
    const currentStartDate = watch("startDate");
    const currentDuration = watch("duration");

    const calculateEndDate = useCallback((start, duration) => {
        if (!start && !duration) return null;
        let end = moment(start ?? currentStartDate).add(duration ?? currentDuration, "minutes");
        return end;
    }, [moment, currentDuration, currentStartDate]);

    const handleClose = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }

        setShowConfirmCancelChangesModal(false);
        setShowConfirmCancelEventModal(false);
        setShowConfirmSaveChangesModal(false);
        setShowVideoMeetingWarning(false);

        setProps({ show: false });
        setUpdateRequest({});

        reset({
            organizer: selectedCalendarUserId,
            attendees: [],
            description: null,
            startDate: null,
            masterAccount: null,
            project: null,
            meetingType: null,
            meetingLocation: null,
            body: null,
            reviewId: null,
        });
    };

    const handleMasterAccountChange = (masterAccount) => {
        getMasterAccountEventPreferences(masterAccount.value)
            .then(
                r => {
                    const { emailContacts, location, adviserId, reviewAdviserId } = r || {};
                    setValue("meetingLocation", location);
                    setValue("attendees", emailContacts);

                    if (currentMeetingType === MeetingTypes.Review) {
                        setValue("organizer", reviewAdviserId ?? adviserId);
                        return;
                    }

                    setValue("organizer", adviserId);
                }
            );
    };

    /**
     * Map recipient from the ReactSelect list view model to the outlook request model
     */
    const mapRecipeint = useCallback((listViewModel) => ({
        contactName: listViewModel.data?.contactName ?? listViewModel.label,
        contactEmailAddress: listViewModel.data?.contactEmailAddress ?? listViewModel.value,
        isNew: listViewModel["__isNew__"]
    }), []);

    /**
     * Map form values to api request model
     */
    const mapToRequestModel = useCallback((data) => ({
        masterAccountId: data.masterAccount?.value,
        organizerId: data.organizer?.value,
        projectId: data.project?.value ?? data.project,
        description: data.description,
        attendees: data.attendees?.map(mapRecipeint),
        meetingType: data.meetingType,
        meetingLocation: data.meetingLocation,
        startDate: data.startDate,
        endDate: data.endDate,
        duration: data.duration,
        body: data.body
    }), []);

    const handleCancel = () => {

        toast.promise(cancelEvent(initialData.id), {
            loading: "Cancelling event...",
            success: () => {
                handleClose();
                return "Event cancelled!";
            },
            error: (err) => {
                const { message } = err?.data;
                const display = message ? `Error: ${message}` : ``;

                return `There was a problem cancelling the event. ${display}`;
            }
        });
    };

    const handleSave = () => {
        toast.promise(updateEvent(initialData.id, updateRequest), {
            loading: "Updating event...",
            success: (res) => {
                handleClose();
                sendSuccessCallbackFn(res);
                return "Event updated!";
            },
            error: (err) => {
                const { message } = err?.data;
                const display = message ? `Error: ${message}` : ``;

                return `There was a problem updating the event. ${display}`;
            }
        });
    };

    const onSubmit = (data) => {
        const request = {
            masterAccountId: data.masterAccount?.value,
            organizerId: data.organizer?.value ?? data.organizer,
            projectId: data.project?.value ?? data.project,
            description: data.description,
            attendees: data.attendees?.map(mapRecipeint),
            meetingType: data.meetingType,
            meetingLocation: data.meetingLocation,
            startDate: data.startDate,
            duration: data.duration,
            body: data.body,
            reviewId: data.reviewId
        };

        toast.promise(createEvent(request, data), {
            loading: "Creating event...",
            success: (res) => {
                handleClose();
                sendSuccessCallbackFn(res);
                return "Event created!";
            },
            error: (err) => {
                const { message } = err?.data;
                const display = message ? `Error: ${message}` : ``;

                return `There was a problem creating the event. ${display}`;
            }
        });
    };

    const updateValue = (propertyName, newValue) => {
        if (isNew === false) {
            // modify the update request to be sent
            setUpdateRequest(prev => ({
                ...prev,
                ...Object.fromEntries(Object.entries(mapToRequestModel({ [propertyName]: newValue })).filter(([_, v]) => v != null))
            }));
        }

        setValue(propertyName, newValue);
    };

    useEffect(() => {
        if (currentMeetingLocation === MeetingLocations.Video) {
            setShowVideoMeetingWarning(true);
        }
        else {
            setShowVideoMeetingWarning(false);
        }
    }, [currentMeetingLocation])

    useEffect(() => {
        if (show === false) {
            setProps({ show: false })
        }
    }, [show]);

    return <Modal key={initialData?.id} size="xl" centered backdrop="static" show={show} onHide={isNew !== true ? handleClose : undefined}>
        <Modal.Header closeButton={isNew !== true}>{isNew ? "Create" : "Update"} Event</Modal.Header>
        {isLoading && <div className="p-4">
            <Skeleton height={32} count={3} />
            <br />
            <Skeleton height={32} count={5} />
        </div>}
        {!isLoading && <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
                <EventForm
                    isNew={isNew}
                    allowAnimationsAndRetry={!isNew}
                    setValue={updateValue}
                    masterAccountChangeFn={handleMasterAccountChange}
                    errors={errors}
                    data={initialData}
                    {...formProperties}
                />
                {isAccountEventPreferencesError && <Alert variant="danger">
                    Unable to load event preferences for chosen client. Please enter manually.
                </Alert>}
                {showVideoMeetingWarning && <Alert variant="warning">
                    Video Meeting is selected. You will not be able to change the event location if you proceed.
                </Alert>}
            </Modal.Body>
            {!isNew && <Modal.Footer className="ms-auto">
                <Button variant="light" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="danger" onClick={() => setShowConfirmCancelEventModal(true)}>
                    <ThemedSpan variant="light" className="me-2">Delete Appointment</ThemedSpan>
                    <FontAwesomeIcon icon="calendar-xmark" />
                </Button>
                <Button onClick={() => setShowConfirmSaveChangesModal(true)} disabled={isUpdating}>
                    <ThemedSpan variant="light" className="me-2">Update Appointment</ThemedSpan>
                    <FontAwesomeIcon icon="floppy-disk" />
                </Button>
            </Modal.Footer>}
            {isNew && <Modal.Footer className="ms-auto">
                <Button variant="danger" onClick={() => setShowConfirmCancelChangesModal(true)}>
                    <ThemedSpan variant="light" className="me-2">Cancel</ThemedSpan>
                    <FontAwesomeIcon icon="trash" />
                </Button>
                <Button type="submit" disabled={isCreating}>
                    <ThemedSpan variant="light" className="me-2">Send</ThemedSpan>
                    <FontAwesomeIcon icon="paper-plane" />
                </Button>
            </Modal.Footer>}
        </form>}

        <ConfirmModal
            message={<div>
                <ThemedSpan>Are you sure you want to delete the event?</ThemedSpan>
                <br />
                <br />
                <ThemedSpan>All attendees will be notified via email.</ThemedSpan>
            </div>}
            confirmButtonLabel="Delete Appointment"
            confirmButtonVariant="danger"
            show={showConfirmCancelEventModal}
            handleClose={() => setShowConfirmCancelEventModal(false)}
            handleConfirm={handleCancel}
        />
        <ConfirmModal
            message={<>
                <ThemedSpan>Are you sure you want to cancel changes?</ThemedSpan>
                <br />
                <br />
                <ThemedSpan>This will lose any unsaved progress!</ThemedSpan>
            </>}
            show={showConfirmCancelChangesModal}
            handleClose={() => setShowConfirmCancelChangesModal(false)}
            handleConfirm={handleClose}
        />
        <ConfirmModal
            message={<>
                <ThemedSpan>Are you sure you want to save changes?</ThemedSpan>
                <br />
                <br />
                <ThemedSpan>All attendees will be notified by email!</ThemedSpan>
            </>}
            show={showConfirmSaveChangesModal}
            handleClose={() => setShowConfirmSaveChangesModal(false)}
            handleConfirm={handleSave}
            isUpdating={isUpdating}
        />
    </Modal>
};

export default EventModal;