import { useClientContext } from "../../../hooks/ClientContext";
import { useClientMessaging } from "./ClientMessagingContext";
import { useCreateClientConversationMutation, useFetchMessageClientAccountsQuery, useLazyFetchAccountConversationThreadsQuery } from "../../../services/messages";
import { useEffect, useState } from "react";

const useThreadPreviews = () => {

    const { filters, navigateToThread, searchTerm, setSearchTerm } = useClientMessaging();
    const { active, clientMessagesOnly, feedbackOnly, hideSystemMessages, showMyMessages, unreadMessages } = filters;
    const limit = 25;
    const { id: masterAccountId } = useClientContext();
    const [isClearing, setIsClearing] = useState(false);
    const [createConversationTrigger, createThreadResult] = useCreateClientConversationMutation();
    const [fetchThreadPreviews, { data, isError, error, isUninitialized }] = useLazyFetchAccountConversationThreadsQuery();
    const { results, pagination: { page, totalCount } } = data || { results: [], pagination: { page: 0, totalCount: limit } };
    const { data: clientAccounts } = useFetchMessageClientAccountsQuery(masterAccountId);

    const clear = (e) => {
        if (typeof (e?.preventDefault) === "function") {
            e.preventDefault();
        }
        setIsClearing(true);
        return new Promise((res, rej) => {
            return fetchThreadPreviews({ page: 1, limit, masterAccountId, ...filters, searchTerm })
                .unwrap()
                .then(r => {
                    setIsClearing(false);
                    res(r);
                });
        });
    };

    const createThread = () => {
        return new Promise((resolve, reject) => {
            return createConversationTrigger({
                masterAccountId,
                recipientIds: filters.clientMessagesOnly ? clientAccounts?.map(x => x.userId) ?? [] : [],
                ...filters
            })
                .unwrap()
                .then(
                    r => {
                        clear().then(_ => {
                            navigateToThread(r.messageId);
                        });

                        resolve(r);
                    },
                    e => reject(e)
                );
        })
    }

    const isItemLoaded = (index) => results[index] || index < results.length;

    const loadMoreItems = () => fetchThreadPreviews({
        page: page + 1,
        limit,
        masterAccountId,
        ...filters,
        searchTerm
    });

    useEffect(() => {
        !isUninitialized && clear();
    }, [active, clientMessagesOnly, feedbackOnly, hideSystemMessages, showMyMessages, unreadMessages]);

    // Debounce searchTerm
    useEffect(() => {
        const searchTimeout = setTimeout(() => {
            clear();
        }, 250);

        return () => clearTimeout(searchTimeout);
    }, [searchTerm]);

    return {
        createThreadResult,
        error,
        isClearing,
        isError,
        results,
        totalCount: results.length === totalCount ? totalCount : results.length + 15,
        createThread,
        isItemLoaded,
        loadMoreItems,
        retry: clear,
        searchTerm,
        setSearchTerm
    };
};

export default useThreadPreviews;