import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "../../../components";
import { FormCheck, FormInput, FormLabel, FormSelect } from "../../../components/forms";
import { AttachmentsToggle } from "../../../components/utilities";
import { ClientMessagingViewTypes, useClientMessaging, viewTypeOptions } from "../hooks/ClientMessagingContext";
import useThread from "../hooks/useThread";
import { useId } from "react";
import Skeleton from "react-loading-skeleton";

const ClientMessagesHeader = () => {
    const subjectInputId = useId();

    const {
        createMessage,
        createMessageResult: { isLoading: isCreating },
        feedbackOnly,
        hideSystemMessages,
        selectedViewType,
        setFeedbackOnly,
        setHideSystemMessages,
        setSelectedViewType,
        setShowMyMessages,
        showMyMessages,
        showThreads,
        threadId,
        searchTerm,
        setSearchTerm
    } = useClientMessaging();

    const { data, isFetching: isLoadingThread, patchThread } = useThread(threadId);
    const { subject, threadAttachments } = data || { threadAttachments: [] };

    return <div className="row flex-fill mx-0 align-items-center">
        <div className="col-3 d-flex align-items-center">
            <FormLabel className={"me-4 mb-0"}>View</FormLabel>
            <div className="flex-grow-1">
                <FormSelect
                    defaultValue={selectedViewType}
                    options={viewTypeOptions}
                    onChange={({ value }) => setSelectedViewType(value)}
                />
            </div>
        </div>
        <div className="col-auto ms-3 d-flex">
            <FormCheck
                label={"Show My Messages"}
                isChecked={showMyMessages}
                onChange={(value, _) => setShowMyMessages(value)}
                disableAnimations
            />
        </div>
        <div className="col-auto ms-3 d-flex">
            <FormCheck
                label={"Hide System Messages"}
                isChecked={hideSystemMessages}
                onChange={(value, _) => setHideSystemMessages(value)}
                disableAnimations
            />
        </div>
        {/* <div className="col-auto ms-3 d-flex align-items-center">
            <FormCheck
                label={"Feedback Only"}
                isChecked={feedbackOnly}
                onChange={(value, _) => setFeedbackOnly(value)}
                disableAnimations
            />
        </div> */}
        {threadId && showThreads && !isLoadingThread && <div className="col">
            <FormInput
                // can't pass ref
                id={subjectInputId}
                placeholder="No subject"
                value={subject ?? ""}
                onBlur={(value) => patchThread("subject", value)}
            />
        </div>}
        {isLoadingThread && (
            <div className="col">
                <Skeleton height={32} />
            </div>
        )}
        {Array.isArray(threadAttachments) && !isLoadingThread && threadAttachments.length > 0 && (
            <div className="col-auto">
                <AttachmentsToggle attachments={threadAttachments} menuDirection="start" />
            </div>
        )}
        {/* Show search bar when view type is one of the Messages types */}
        {viewTypeOptions[selectedViewType].viewType === ClientMessagingViewTypes.Messages && (
            <div className="col-auto ms-auto">
                <FormInput
                    parentClassName="my-1"
                    label="Search:"
                    horizontal
                    placeholder="Start typing to search messages..."
                    value={searchTerm ?? ""}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    disableAnimations
                />
            </div>
        )}
        <div className="col-auto">
            {(!showThreads || (showThreads && threadId)) && (
                <Button disabled={isCreating} variant="success" onClick={() => createMessage({ threadId })}>
                    <span className="me-2">New Message</span>
                    <FontAwesomeIcon icon="plus" />
                </Button>
            )}
        </div>
    </div>;
};

export default ClientMessagesHeader;