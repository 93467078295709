import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useNavigationRoutes from "./useNavigationRoutes";
import { darken, lighten, readableColor } from "polished";
import { Tabs, Tab } from "react-tabs-scrollable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";

// TODO: handle variant so that we can style the navigation tabs depending on colour/style we need

export const StyledNavigationTab = styled(Tab)`
    color: ${props => readableColor(props.theme.primary, props.theme.dark, props.theme.light)};
    background-color: ${props => props.theme.primary};

    &:hover {
        color: ${props => lighten(0.55, props.theme.primary)};
    }

    &.rts___tab___selected {
        color: ${props => props.hasSubNavigation === true
            ? readableColor(props.theme.primary, props.theme.dark, props.theme.light)
            : readableColor(darken(0.04, props.theme.light), props.theme.dark, props.theme.light)
        };
        background-color: ${props => props.hasSubNavigation === true
            ? darken(0.075, props.theme.primary)
            : darken(0.04, props.theme.light)
        };
    }
`;

export const StyledNavigationTabIconContainer = styled.span`
    margin-right: .5rem;
`;

export const NavigationTab = React.forwardRef(({ data, ...rest }, ref) => {
    const { icon, label, children } = data || {};
    const hasSubNavigation = children && Array.isArray(children) && children.length > 0;
    return <StyledNavigationTab ref={ref} hasSubNavigation={hasSubNavigation} {...rest}>
        {icon && (
            <StyledNavigationTabIconContainer>
                <FontAwesomeIcon icon={icon} />
            </StyledNavigationTabIconContainer>
        )}
        {label}
    </StyledNavigationTab>
});

const NavigationTabs = ({ pathBase, options, variant = "primary", onTabChanged = () => { }, ...rest }) => {
    const location = useLocation();
    const navigator = useNavigate();
    const { defaultRoute, children } = useNavigationRoutes(options);
    const [activeTab, setActiveTab] = useState(defaultRoute ? children.findIndex(el => el.path === defaultRoute || el.route === defaultRoute) : 0);

    const onTabClick = (_, index) => {
        navigator(children[index].path ?? children[index].route);
        onTabChanged(children[index],index);
    }

    useEffect(() => {
        const indexOfRoute = children.findIndex(el => {
            let path = el.path ?? el.route;
            let match = path.length === 0 || location.pathname == `${pathBase}/${path}`
                ? matchRoutes([{ path: `${pathBase}/${path}` }], location)
                : matchRoutes([{ path: `${pathBase}/${path}/*` }], location);
            return match ? true : false
        });

        if (indexOfRoute >= 0 && indexOfRoute !== activeTab) {
            setActiveTab(indexOfRoute);
        }
    }, [ location ]);

    return <Tabs 
        activeTab={activeTab} 
        onTabClick={onTabClick}
        leftBtnIcon={<FontAwesomeIcon icon="fa-chevron-left" />}
        rightBtnIcon={<FontAwesomeIcon icon="fa-chevron-right" />}
        {...rest}
    >
        {children.map((el, i) => <NavigationTab key={i} variant={variant} data={el} />)}
    </Tabs>
};

export default NavigationTabs;