import React from "react";
import toast, { Renderable, Toast } from "react-hot-toast";
import DateDisplay from "../DateDisplay";
import { useNavigate } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type BasicToastProps = {
    action?: () => void | string | undefined,
    actionLabel?: string | undefined,
    control: Toast,
    date?: string | undefined,
    isClearable?: boolean,
    isDismissable?: boolean,
    icon?: IconProp | undefined,
    message: string,
    title?: Renderable | undefined
};

const BasicToast = React.forwardRef<React.ReactNode, BasicToastProps>(({ 
    action, 
    actionLabel = "Open", 
    control, 
    date, 
    isClearable = true, 
    isDismissable = false, 
    icon, 
    message = "", 
    title = ""
}, ref) => {
    const navigate = useNavigate();

    const handleAction = () => {
        if (!action) {
            return;
        }
        if (typeof (action) === 'function') {
            action();
            return;
        }
        navigate(action);
    };

    return (
        <div className="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-header">
                {icon && (
                    <FontAwesomeIcon className="me-2" icon={icon} size="lg" />
                )}
                <strong className="me-auto">{title}</strong>
                {date && (
                    <small><DateDisplay fromNow={true}>{date}</DateDisplay></small>
                )}
                {isClearable === true && (
                    <button 
                        type="button" 
                        className="btn-close" 
                        data-bs-dismiss="toast" 
                        aria-label="Close" 
                        onClick={() => toast.dismiss(control.id)}>
                    </button>
                )} 
            </div>
            <div className="toast-body">{message}</div>
            <div className="toast-footer">
                {action && (
                    <button 
                        type="button" 
                        className="btn btn-md flex-fill"
                        onClick={handleAction}>
                        {actionLabel}
                    </button>
                )} 
                {isDismissable === true && (
                    <button 
                        type="button" 
                        className="btn btn-md flex-fill"
                        data-bs-dismiss="toast" 
                        aria-label="Close"
                        onClick={() => toast.dismiss(control.id)}>
                        Dismiss
                    </button>
                )}
            </div>
        </div>
    );
});

export default BasicToast;