import React, { useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "react-oidc-context";
import { Button } from "../../../components";
import { DateInput } from "../../../components/forms";
import { useBuildAllClientValuationsMutation, usePersistAllClientValuationsBuildMutation } from "../../../services/valuations";
import { useAssetsUnderAdvice } from "../hooks/AssetsUnderAdviceContext";
import ValuationBuildSelect from "./ValuationBuildSelect";
import ValuationDateConfirmModal from "./ValuationDateConfirmModal";

export const ValuationDatePicker = ({ value, setValue }) => (
    <DateInput
        label={"Valuation Date"}
        value={value}
        onBlur={(date) => setValue(date)}
        maxDate={new Date()}
        disableAnimations
    />
);

const ValuationBuildPicker = () => {
    const { valuationDate, setValuationDate, buildId, setBuildId } = useAssetsUnderAdvice();

    // Retrieve user name for passing to cache on build start
    const auth = useAuth();
    const { user: { profile: { name: userName } } } = auth;

    const [buildIsPersisted, setBuildIsPersisted] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const [buildTrigger] = useBuildAllClientValuationsMutation();
    const [persistTrigger] = usePersistAllClientValuationsBuildMutation();

    const build = (selectedDate) =>
        toast.promise(buildTrigger({ valuationDate: selectedDate, userName }).unwrap(), {
            loading: "Building...",
            success: "Build created. You will be notified when it is finished.",
            error: (error) => `Failed to create build: ${error?.data} `
        });

    const persist = () =>
        toast.promise(persistTrigger({ buildId }).unwrap(), {
            loading: "Saving...",
            success: () => {
                setBuildIsPersisted(true);
                return "Build saved. You will now be able to view this build in future."
            },
            error: (error) => `Failed to save build: ${error?.data} `
        });

    const onSelectBuild = (selection = null) => {
        if (selection == null)
            return setBuildId(null);

        if (selection.value === -1)
            return setShowConfirm(true);

        setBuildId(selection.value);
        setBuildIsPersisted(selection.data.isPersistent);
    };

    return <React.Fragment>
        <div className={"d-flex row gx-2 ms-3"}>
            <ValuationDateConfirmModal
                show={showConfirm}
                setShow={setShowConfirm}
                defaultValue={valuationDate}
                build={build}
            />
            <span className="col-3">
                <ValuationDatePicker
                    value={valuationDate}
                    setValue={setValuationDate}
                />
            </span>
            <span className={(buildIsPersisted || buildId == null) ? "col-9" : !buildIsPersisted ? "col-7" : ""}>
                <ValuationBuildSelect
                    label="Select Build"
                    valuationDate={valuationDate}
                    buildId={buildId}
                    onSelectBuild={onSelectBuild}
                    placeholder="Select/Commence a Valuation Build"
                    defaultMostRecent
                    disableAnimations
                />
            </span>
            {!buildIsPersisted && buildId &&
                <span className="col-2 mt-auto mb-1">
                    <Button variant="success" onClick={persist}>
                        Save Build
                    </Button>
                </span>
            }
        </div>
    </React.Fragment>
}

export default ValuationBuildPicker;