import { Modal } from "react-bootstrap";
import styled from "styled-components";
import useTransactionFeeAllocations from "../hooks/useTransactionFeeAllocations"
import TransactionFeeAllocations from "../../administrationfees/components/TransactionFeeAllocations";
import React, { useState } from "react";
import CreateTransactionFeeModalWrapper from "./CreateTransactionFeeModalWrapper";
import EditTransactionFeeModalWrapper from "./EditTransactionFeeModalWrapper";
import { Button, DateDisplay } from "../../../components";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { useAdministrationFeesProvider } from "../../../hooks/AdministrationFeesContext";

const TransactionFeeAllocationsWrapper = styled.div`
    background-color: #fff;
`;

const FeeGroupingBreakdownModal = ({ show, onHide, data, groupId, }) => {

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [feeTransactionId, setFeeTransactionId] = useState(null);
    const {currentSearchType} = useAdministrationFeesProvider();
    const {
        feeTypeDescription,
        providerName,
        masterAccountDisplayName,
        feeDate,
        totalFeeValuePounds,
        totalVatValuePounds,
        debt,
        adviserName,
        description
    } = data || {};

    const handleModalStateChange = (e, setter, newValue) => {
        if (e && typeof (e.preventDefault) === "function") {
            e.preventDefault();
        }
        //console.log('e',e);
        //console.log('setter',setter);
        //console.log('newValue',newValue);
        //console.log('feeGroupingModal-Edit Fee',data);
        setter(newValue);
    };

    return (
        <React.Fragment>
            <Modal size="xl" backdrop="static" onHide={onHide} centered show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Fee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row h5">
                        <span className="col-auto">{feeTypeDescription}</span>
                        <span className="col-auto">{providerName}</span>
                        <span className="col-auto">{masterAccountDisplayName}</span>
                        <span className="col-auto">{moment(feeDate).isValid() ? <DateDisplay>{feeDate}</DateDisplay> : "No valid date"}</span>
                        <span className="col-auto">
                            Fees:
                            <NumericFormat className="ms-1" value={totalFeeValuePounds} displayType="text" prefix="£" thousandSeparator decimalScale={2} fixedDecimalScale />
                        </span>
                        {(currentSearchType !== null && currentSearchType !== "debtors") && (<span className="col-auto">
                            VAT:
                            <NumericFormat className="ms-1" value={totalVatValuePounds} displayType="text" prefix="£" thousandSeparator decimalScale={2} fixedDecimalScale />
                        </span>)}
                        {(currentSearchType !== null && currentSearchType !== "fees" && currentSearchType !== "cash") && (<span className="col-auto">
                            Debt:
                            <NumericFormat className="ms-1" value={debt} displayType="text" prefix="£" thousandSeparator decimalScale={2} fixedDecimalScale />
                        </span>)}
                        <span className="col-auto">{adviserName}</span>
                    </div>
                    <TransactionFeeAllocationsWrapper>
                        {groupId && <TransactionFeeAllocations
                            groupId={groupId}
                            openCreateModal={(e) => handleModalStateChange(e, setShowCreateModal, true)}
                            openEditModal={(e, id) => handleModalStateChange(e, setFeeTransactionId, id)}
                            loadingLines={2}
                            useAllocations={useTransactionFeeAllocations}
                        />}
                    </TransactionFeeAllocationsWrapper>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            {showCreateModal && <CreateTransactionFeeModalWrapper
                show={showCreateModal}
                handleClose={(e) => handleModalStateChange(e, setShowCreateModal, false)}
                group={data}
            />}
            {feeTransactionId !== null && <EditTransactionFeeModalWrapper
                feeTransactionId={feeTransactionId}
                show={feeTransactionId !== null}
                handleClose={(e) => handleModalStateChange(e, setFeeTransactionId, null)}
                group={data}
            />}
        </React.Fragment>

    );
};

export default FeeGroupingBreakdownModal;