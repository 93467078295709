import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams } from "react-router-dom";
import { patchReplace } from "../../../helpers/patchDoc";
import { useApproveCommentariesMutation, useAwaitApprovalCommentariesMutation, useLazyFetchAssetGroupQuery, useLazyFetchAssetSectorsForSelectQuery, useLazyFetchFactSheetPdfQuery, useLazyFetchSectorByCodeQuery, useLazyLookupAssetForICQuery, useLazySearchAssetSectorsForSelectQuery, usePatchAssetGroupCommentaryMutation, usePatchAssetGroupMutation, usePatchAssetGroupVolatilityProfileMutation, usePublishCommentariesMutation, useRemoveAssetMonitoringMutation } from "../../../services/assets";

const options = {
    volatility: [
        { label: "Cash", value: 0 },
        { label: "Low", value: 1 },
        { label: "Low To Moderate", value: 2 },
        { label: "Moderate", value: 3 },
        { label: "Moderate To High", value: 4 },
        { label: "High", value: 5 },
        { label: "Structured Product", value: 6 }
    ],
    monitoring: [
        { label: "Actively Monitored Core", value: 0 },
        { label: "Actively Monitored", value: 1 },
        { label: "Passively Monitored", value: 2 }
    ],
    management: [
        { label: "Active", value: 0 },
        { label: "Passive", value: 1 },
        { label: "Hybrid", value: 2 }
    ],
    assetClass: [
        { label: "Multi Asset", value: 0 },
        { label: "Equity", value: 1 },
        { label: "Equity Income", value: 2 },
        { label: "Fixed Interest", value: 3 },
        { label: "Property", value: 4 },
        { label: "Alternatives", value: 5 },
        { label: "Targeted Absolute Return", value: 6 },
        { label: "Structured Product", value: 7 },
        { label: "Cash", value: 8 },
        { label: "Investment Trust", value: 9 }
    ],
    region: [
        { label: "Default", value: 0 },
        { label: "Global", value: 1 },
        { label: "United Kingdom", value: 2 },
        { label: "Europe", value: 3 },
        { label: "North America", value: 4 },
        { label: "Japan", value: 5 },
        { label: "China", value: 6 },
        { label: "Asia Pacific", value: 7 },
        { label: "Emerging Markets", value: 8 },
        { label: "Other", value: 9 }
    ],
    style: [
        { label: "Value", value: 0 },
        { label: "Growth", value: 1 },
        { label: "Flexible", value: 2 }
    ],
    opinion: [
        { label: "Satisfied", value: 0 },
        { label: "Under Review", value: 1 },
        { label: "Change Recommended", value: 2 },
        { label: "Not Applicable", value: 3 }
    ],
    guidance: [
        { label: "Appropriate for New Investments", value: 0 },
        { label: "Appropriate for Disposals Proceeds", value: 1 }
        // TODO: More guidance options, still to be defined
    ],
    controlStatus: [
        { label: "Draft", value: 0, requiredRoles: { set: "", update: "ic_write" } },
        { label: "Await Approval", value: 1 },
        { label: "Approved", value: 2, requiredRoles: { set: "ic_approve", update: "ic_publish" } },
        { label: "Published", value: 3, requiredRoles: { set: "ic_publish", update: "" } }
    ],
    publishStatus: [
        { label: "Draft", value: 0 },
        { label: "Approved", value: 1 },
        { label: "Published", value: 2 }
    ]
};

const commentaryTypes = {
    volatilityRationale: 0,
    fundManager: 1,
    rationale: 2,
    currentOpinion: 3,
    forDiscussion: 4
};

const unauthorisedError = {
    message: "Unauthorised",
    errorAllowRetry: false
}

const useFundDetails = () => {
    const { assetGroupId } = useParams();
    const auth = useAuth();
    const canEditDetails = ["ic_details_write", "sys_admin_access"].some(role => auth.user.profile.roles.includes(role));
    const canEditCommentary = ["ic_write", "sys_admin_access"].some(role => auth.user.profile.roles.includes(role));
    const canApprove = ["ic_approve", "sys_admin_access"].some(role => auth.user.profile.roles.includes(role));
    const canPublish = ["ic_publish", "sys_admin_access"].some(role => auth.user.profile.roles.includes(role));

    // Base RTK Fetch Query
    const [fetchAssetGroup, { currentData: assetGroup, error: assetGroupError, isError: assetGroupIsError, isFetching: assetGroupIsFetching, isLoading: assetGroupIsLoading, isUninitialized: assetGroupIsUninitialized }] = useLazyFetchAssetGroupQuery();

    // Fetch when assetGroupId changes
    useEffect(() => {
        if (assetGroupId != null && parseInt(assetGroupId)) {
            fetchAssetGroup({ assetGroupId }, true)
        }
    }, [assetGroupId, fetchAssetGroup]);

    const assetGroupIsNotReady = useMemo(() =>
        assetGroupIsUninitialized || assetGroupIsLoading || assetGroupIsFetching,
        [assetGroupIsFetching, assetGroupIsLoading, assetGroupIsUninitialized])

    const reload = useCallback(() => {
        fetchAssetGroup({ assetGroupId });
    }, [assetGroupId, fetchAssetGroup]);

    // Fetch Draft/Published PDF Preview
    const [getPdfPreviewTrigger, { isLoading: isGettingPdfPreview, isError: getPdfPreviewIsError, error: getPdfPreviewError }] = useLazyFetchFactSheetPdfQuery();
    const [pdfPreview, setPdfPreview] = useState(null);
    const [shouldShowPdfPreview, setShouldShowPdfPreview] = useState(false);

    const showPreview = (draft) => {
        setShouldShowPdfPreview(true);
        getPdfPreviewTrigger({ assetGroupId, draft }, false).unwrap()
            .then(res => {
                setPdfPreview(res.data);
            }, s => setPdfPreview(s.data));
    };

    const showDraftPreview = () => showPreview(true);

    const hidePreview = () => {
        setShouldShowPdfPreview(false);
    };

    useEffect(() => {
        if (!shouldShowPdfPreview)
            setPdfPreview(null)
    }, [shouldShowPdfPreview])

    const [patchTrigger] = usePatchAssetGroupMutation();

    const patchAssetGroup = useCallback((operations) =>
        new Promise((res, rej) => canEditDetails
            ? patchTrigger({ assetGroupId, operations }).unwrap()
                .then(res, rej)
            : rej(unauthorisedError)),
        [assetGroupId, canEditDetails, patchTrigger]);

    const patchAssetGroupSingle = useCallback((property, value) =>
        patchAssetGroup([patchReplace(property, value)]),
        [patchAssetGroup]);

    const [patchVolatilityProfileTrigger, { isLoading: isUpdatingVolatilityProfile }] = usePatchAssetGroupVolatilityProfileMutation();

    const patchVolatilityProfile = useCallback((newValue) =>
        new Promise((res, rej) => canEditDetails
            ? patchVolatilityProfileTrigger({ assetGroupId, operations: [patchReplace("volatilityProfile", newValue)] }).unwrap()
                .then(res, rej)
            : rej(unauthorisedError)),
        [assetGroupId, canEditDetails, patchVolatilityProfileTrigger]);

    const [patchCommentaryTrigger, { isLoading: isUpdatingCommentary, originalArgs }] = usePatchAssetGroupCommentaryMutation();

    const commentaryTypeUpdating = useMemo(() =>
        isUpdatingCommentary
            ? Object.keys(commentaryTypes).find(type => commentaryTypes[type] === originalArgs?.commentaryType)
            : null,
        [isUpdatingCommentary, originalArgs?.commentaryType])

    const patchCommentary = useCallback((commentaryType, newCommentary) =>
        new Promise((res, rej) => canEditCommentary
            ? patchCommentaryTrigger({ assetGroupId, commentaryType: commentaryTypes[commentaryType], commentaryTextField: `${commentaryType}${commentaryType === "volatilityRationale" ? "" : "Text"}`, newCommentary }).unwrap()
                .then(res, rej)
            : rej(unauthorisedError)),
        [assetGroupId, canEditCommentary, patchCommentaryTrigger]);

    const [awaitApprovalCommentariesTrigger, { isLoading: isAwaitingApproval }] = useAwaitApprovalCommentariesMutation();

    const awaitApproval = useCallback(() =>
        new Promise((res, rej) => canEditCommentary
            ? awaitApprovalCommentariesTrigger({ assetGroupId }).unwrap()
                .then(res, rej)
            : rej(unauthorisedError)),
        [assetGroupId, canEditCommentary, awaitApprovalCommentariesTrigger]);

    const [approveCommentariesTrigger, { isLoading: isApprovingCommentaries }] = useApproveCommentariesMutation();

    const approveCommentaries = useCallback(() =>
        new Promise((res, rej) => canApprove
            ? approveCommentariesTrigger({ assetGroupId }).unwrap()
                .then(res, rej)
            : rej(unauthorisedError)),
        [assetGroupId, canApprove, approveCommentariesTrigger]);

    const [publishCommentariesTrigger, { isLoading: isPublishingCommentaries }] = usePublishCommentariesMutation();

    const publishCommentaries = useCallback(() =>
        new Promise((res, rej) => canPublish
            ? publishCommentariesTrigger({ assetGroupId }).unwrap()
                .then(res, rej)
            : rej(unauthorisedError)),
        [assetGroupId, canPublish, publishCommentariesTrigger]);

    const selectControlStatus = useCallback((selection) =>
        new Promise((resolve, reject) => {
            switch (selection.value) {
                case 1: // Await Approval
                    awaitApproval().then(resolve, reject);
                    break;
                case 2: // Approved
                    approveCommentaries().then(resolve, reject);
                    break;
                case 3: // Published
                    publishCommentaries().then(resolve, reject);
                    break;
                case 0: // Draft, cannot be set manually
                default:
                    reject("Invalid Control Status");
            }
        }), [approveCommentaries, awaitApproval, publishCommentaries]);

    // Displays for FundInfoHeader, to be updated real-time
    const [assetDisplayName, setAssetDisplayName] = useState();
    const [monitoringDisplayText, setMonitoringDisplayText] = useState();
    const [controlStatusDisplayText, setControlStatusDisplayText] = useState();

    useEffect(() => {
        setAssetDisplayName(assetGroup?.assetName);
        setMonitoringDisplayText(assetGroup?.monitoringDisplayText);
        setControlStatusDisplayText(assetGroup?.controlStatusDisplayText);
    }, [assetGroup?.assetName, assetGroup?.controlStatusDisplayText, assetGroup?.monitoringDisplayText]);

    const [removeAssetMonitoringTrigger] = useRemoveAssetMonitoringMutation();

    const navigate = useNavigate();

    const makeAssetPassive = useCallback(() =>
        new Promise((res, rej) => canEditDetails
            ? removeAssetMonitoringTrigger({ assetGroupId }).unwrap()
                .then(() => {
                    navigate(-1);
                    res();
                }, rej)
            : rej(unauthorisedError)),
        [assetGroupId, canEditDetails, navigate, removeAssetMonitoringTrigger]);

    // Sector Code options are paged, and fetched from the server
    const [fetchSectorsTrigger] = useLazyFetchAssetSectorsForSelectQuery();
    const [searchSectorsTrigger] = useLazySearchAssetSectorsForSelectQuery();

    const fetchSectors = useCallback((args) =>
        fetchSectorsTrigger(args).unwrap(),
        [fetchSectorsTrigger]);

    const searchSectors = useCallback((args) =>
        searchSectorsTrigger(args).unwrap(),
        [searchSectorsTrigger]);

    // Lookup the entered Sedol in the Asset database when it changes, for populating volatility tables
    const [assetData, setAssetData] = useState();
    const [lookupAssetTrigger, { isError: assetLookupIsError, error: assetLookupError, isFetching: assetLookupIsFetching }] = useLazyLookupAssetForICQuery();

    useEffect(() => {
        if (assetGroupIsNotReady)
            return;

        if (assetGroup?.sedol != null) {
            lookupAssetTrigger({ lookupCode: assetGroup.sedol }, true)
                .unwrap()
                .then(setAssetData);
        } else {
            setAssetData(null);
        }
    }, [assetGroup?.sedol, assetGroupIsNotReady, lookupAssetTrigger]);

    const reloadAsset = useCallback(() => {
        lookupAssetTrigger({ lookupCode: assetGroup.sedol });
    }, [assetGroup?.sedol, lookupAssetTrigger]);

    // Lookup the selected Sector Code in the Sectors database when it changes, for populating volatility tables
    const [lookupSectorTrigger, { currentData: sectorData, isError: sectorLookupIsError, error: sectorLookupError, isFetching: sectorLookupIsFetching }] = useLazyFetchSectorByCodeQuery();

    // Need a specialised function for PagedSelect to fetch the full object from the server and transform it into the { value, label } format
    const fetchSector = useCallback((value) =>
        lookupSectorTrigger({ sectorCode: value }, true).unwrap().then((res) =>
            res == null
                ? null
                : { value: res.sectorCode, label: res.sectorName }),
        [lookupSectorTrigger]);

    useEffect(() => {
        if (assetGroup?.sectorCodeOverride != null) {
            lookupSectorTrigger({ sectorCode: assetGroup.sectorCodeOverride }, true);
        }
    }, [assetGroup?.sectorCodeOverride, lookupSectorTrigger]);

    const reloadSector = useCallback(() => {
        lookupSectorTrigger({ sectorCode: assetGroup?.sectorCodeOverride });
    }, [assetGroup?.sectorCodeOverride, lookupSectorTrigger]);

    // Filter control status options based on user roles
    const filteredOptions = useMemo(() => ({
        ...options,
        controlStatus: options.controlStatus.filter(status =>
            status.requiredRoles == null
            || auth.user.profile.roles.includes(status.requiredRoles.set)
            || status.value === assetGroup?.controlStatus)
    }), [assetGroup?.controlStatus, auth.user.profile.roles]);

    // Prevent changing control status depending on existing status
    // eg Approved status can only be updated by a user with ic_publish role
    const selectedControlStatus = useMemo(() =>
        filteredOptions.controlStatus.find(option => option.value === assetGroup?.controlStatus),
        [assetGroup?.controlStatus, filteredOptions.controlStatus]);
    const canChangeControlStatus = useMemo(() => selectedControlStatus?.requiredRoles == null
        || auth.user.profile.roles.includes(selectedControlStatus.requiredRoles.update),
        [auth.user.profile.roles, selectedControlStatus?.requiredRoles]);

    return [{
        reload,
        showPreview,
        showDraftPreview,
        hidePreview,
        patchAssetGroup,
        patchAssetGroupSingle,
        patchVolatilityProfile,
        reloadAsset,
        fetchSectors,
        searchSectors,
        fetchSector,
        reloadSector,
        patchCommentary,
        makeAssetPassive,
        selectControlStatus,
    }, {
        assetGroup,
        assetGroupIsNotReady,
        assetGroupIsError,
        assetGroupError,
        pdfPreview,
        isGettingPdfPreview,
        shouldShowPdfPreview,
        getPdfPreviewIsError,
        getPdfPreviewError,
        isUpdatingVolatilityProfile,
        assetData,
        assetLookupIsError,
        assetLookupError,
        assetLookupIsFetching,
        sectorData,
        sectorLookupIsError,
        sectorLookupError,
        sectorLookupIsFetching,
        commentaryTypeUpdating,
        isApprovingCommentaries,
        isPublishingCommentaries
    }, {
        canEditDetails,
        canEditCommentary,
        canChangeControlStatus,
        options: filteredOptions,
        assetDisplayName,
        monitoringDisplayText,
        controlStatusDisplayText,
        setAssetDisplayName,
        setMonitoringDisplayText,
        setControlStatusDisplayText
    }]
}

export default useFundDetails;