import { useNavigate } from "react-router-dom";

// TODO: add options for changing the default tab to open for the user

const useOpenMasterAccount = () => {
    const navigate = useNavigate();
    return (masterAccountId, opts) => {
        const { suffix } = opts || { suffix: null };

        // build the url
        let url = `/client/${masterAccountId}`;
        if (suffix && typeof (suffix) === 'string' && suffix.length > 0) {
            url += `/${suffix}`;
        }
        else {
            url += `/details`; // NOTE: this is to stop the summary default redirect (meaning back will work)
        }

        navigate(url);
    };
};

export default useOpenMasterAccount;