import React, { useMemo } from "react";
import { InfoBubble, SectionHeader } from ".";
import { DateDisplay } from "../../../components";
import { IconButton } from "../../../components/buttons";
import { FormLabel } from "../../../components/forms";
import useOpenDocument from "../../../hooks/useOpenDocument";
import Skeleton from "react-loading-skeleton";

const AntiMoneyLaunderingEvidenceRow = ({ evidence, isHeader = false, onIconClick, documentTypeOptions, proofOfOptions }) => {
    const {
        clientName, documentId, documentType, proofOf, expiryDate, status, isRemoving
    } = evidence ?? {};

    const documentTypeDescription = useMemo(() => isHeader
        ? null
        : documentTypeOptions.find(opt => opt.value === documentType)?.label ?? "",
        [documentType, documentTypeOptions, isHeader]);
    const proofOfDescription = useMemo(() => isHeader
        ? null
        : proofOfOptions[proofOf] ?? "",
        [isHeader, proofOf, proofOfOptions]);

    const { openDocument, isFetching: isOpening } = useOpenDocument();

    if (isRemoving) {
        return <div className="row mt-2">
            <Skeleton height={42} containerClassName="w-100" />
        </div>
    }

    return <div className="row mt-2">
        <div className="col-3 my-auto">
            {isHeader
                ? <FormLabel className="m-0">
                    Client
                </FormLabel>
                : <span>
                    {clientName}
                </span>}
        </div>
        <div className="col-3 my-auto">
            {isHeader
                ? <FormLabel className="m-0">
                    Document Type
                </FormLabel>
                : documentType === 4    // Home Visit evidence has no document uploaded
                    ? <span>
                        {documentTypeDescription}
                    </span>
                    : <button className="anchor" disabled={isOpening} onClick={() => openDocument(documentId, documentTypeDescription)}>
                        {documentTypeDescription}
                    </button>}
        </div>
        <div className="col-3 my-auto">
            {isHeader
                ? <FormLabel className="m-0">
                    Proof
                </FormLabel>
                : <span>
                    {proofOfDescription}
                </span>}
        </div>
        <div className="col my-auto">
            {isHeader
                ? <FormLabel className="m-0">
                    Expiry Date
                </FormLabel>
                : <span>
                    {/* E-Verification has no expiry date */}
                    {/* {documentType !== 0 && <DateDisplay format="DD/MM/YYYY" date={expiryDate} />} */}
                    {/* It does now! */}
                    <DateDisplay format="DD/MM/YYYY">{expiryDate}</DateDisplay>
                </span>}
        </div>
        <div className="col my-auto">
            {isHeader
                ? <FormLabel className="m-0">
                    Status
                </FormLabel>
                : <span>
                    {status}
                </span>}
        </div>
        <div className="col-auto my-auto">
            <IconButton
                icon={isHeader ? "plus" : "fa-trash"}
                variant={isHeader ? "success" : "danger"}
                onClick={onIconClick} />
        </div>
    </div>;
};

const AntiMoneyLaunderingEvidence = ({ evidence, openCreateModal, documentTypeOptions, proofOfOptions, remove }) => {

    return <div className="mt-2">
        <SectionHeader className="ms-2">Supporting Evidence<InfoBubble iconClassName="ms-3" showBubble /></SectionHeader>
        <div className="container-fluid mt-2">
            <AntiMoneyLaunderingEvidenceRow isHeader onIconClick={openCreateModal} />

            {evidence.map(e => <AntiMoneyLaunderingEvidenceRow
                key={e.evidenceId}
                evidence={e}
                onIconClick={() => remove(e.evidenceId)}
                documentTypeOptions={documentTypeOptions}
                proofOfOptions={proofOfOptions}
            />)}
        </div>
    </div>;
};

export default AntiMoneyLaunderingEvidence;