import styled from "styled-components";
import { darken } from "polished";

const PageSubHeader = styled.div`
    grid-area: pagesubheader;
    z-index: 4;
    padding: 1rem .5rem;
    background-color: ${props => darken(0.07, props.theme.light)};
    border-bottom: 1px solid ${props => darken(0.15, props.theme.light)};
`;

// NOTE: this is used in the client layout tabbed sub pages
// We need to set this so it can stick below the main header and then the client navigation
// TODO: we want to maybe calculate this at some point?
export const ClientLayoutPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    top: 155px;
    padding: 1.3rem 1rem;
`;

export const ClientTransactionsPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    top: 155px;
    padding: 1.3rem 1rem;
`;

export const ClientReportManagementPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    top: 147px;
    padding: 1.3rem 1rem;
`;

export const ClientReviewManagementPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    top: 147px;
    padding: 1.3rem 1rem;
`;

export const IntroducerManagementPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    padding: 1.3rem 1rem;
`;

export const AdministrationDashboardPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    padding: 1.3rem 1rem;
`;

export const AdministrationRemindersPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    padding: 1.3rem 1rem;
`;

export const AdvicePendingApprovalPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    padding: 1.3rem 1rem;
`;

export const FeeManagementPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    padding: 1.3rem 1rem;
`;

export const DebtorManagementPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    padding: 1.3rem 1rem;
`;

export const UserDelegatsPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    padding: 1.3rem 1rem;
`;

export const UsersGridPageSubHeader = styled(PageSubHeader)`
    position: sticky;
    padding: 1.3rem 1rem;
`;

export default PageSubHeader;