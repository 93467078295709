import { useMemo } from "react";
import { DateDisplay } from "../../../components";
import { useAuth } from "react-oidc-context";
import { useClientContext } from "../../../hooks/ClientContext";
import { useFetchFactFindVerificationStatusQuery, useVerifyFactFindMutation } from "../../../services/clientfactfind";

const useVerifyFactFind = () => {
    const { id: masterAccountId } = useClientContext();
    const { data: verificationStatus, isFetching, error: fetchStatusError, refetch } = useFetchFactFindVerificationStatusQuery({ masterAccountId });
    const [verify, { isLoading: isVerifying, error: verifyError }] = useVerifyFactFindMutation();

    const verified = useMemo(() => verificationStatus != null,
        [verificationStatus]);

    const verifiedInfo = useMemo(() => {
        if (verificationStatus != null) {
            return <span><DateDisplay format="DD/MM/YYYY @ HH:mm">{verificationStatus.verifiedDate}</DateDisplay> by {verificationStatus.verifiedByName}</span>;
        }

        return "Never Verified";
    }, [verificationStatus]);

    const error = useMemo(() => {
        if (fetchStatusError)
            return { data: "Could not fetch verification status", allowRetry: true, isFetchingError: true }

        if (verifyError) {
            if (verifyError.status === 403) // Unauthorized
                return { data: "You do not have permission to verify fact finds.", allowRetry: false, isFetchingError: false };

            return { data: "Could not verify fact find", allowRetry: true, isFetchingError: false };
        }

        return null;
    }, [fetchStatusError, verifyError])

    const { user: { profile: { roles } } } = useAuth();
    const canVerify = roles?.includes("ff_verify") || roles?.includes("sys_admin_access");

    const verifyFactFind = () => verify({ masterAccountId })
        .unwrap();

    return {
        canVerify,
        verifyFactFind,
        isFetching,
        isVerifying,
        error,
        verified,
        verifiedInfo,
        refetch
    };
}

export default useVerifyFactFind;