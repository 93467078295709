import React from "react";
import { ApplicationType } from "../types/ApplicationType";
import { BusinessSheetStatusType } from "../types/BusinessSheetStatusType";
import { useFilterContext } from "../../../hooks/FilterContext";
import { useHoFetchBusinessSheetStatusesQuery, useHoPatchRecommendationMutation, useHoTriggerAdministrationMutation, useLazyHoSearchRecommendationsQuery } from "../../../services/headofficeadmin";

export type AdviceGridDataContextProps = {
    cacheKey: number,
    clear: () => void,
    data: [ApplicationType?],
    error: Error | null,
    isClearing: boolean,
    isError: boolean,
    isFetching: boolean,
    isLoading: boolean,
    isNewRecords: boolean,
    isNextPageLoading: boolean,
    isRowLoaded: (index: number) => boolean,
    limit: number,
    loadMore: () => void,
    newRecordsSinceCache: number,
    page: number,
    reload: (e : any) => void,
    sort: string,
    sortTable: (property: string) => void,
    statuses: [BusinessSheetStatusType?]
    totalCount: number,
    totalPages: number
};

export type AdviceGridDataProviderProps = {
    children: React.ReactNode, 
    initialLimit?: number,
    initialPage?: number,
    initialSort?: string
};

const AdviceGridDataContext = React.createContext<AdviceGridDataContextProps>({
    cacheKey: new Date().valueOf(),
    clear: () => {},
    data: [],
    error: null,
    isClearing: false,
    isError: false,
    isFetching: false,
    isLoading: false,
    isNewRecords: false,
    isNextPageLoading: false,
    isRowLoaded: () => false,
    limit: 50,
    loadMore: () => {},
    newRecordsSinceCache: 0,
    page: 1,
    reload: () => {},
    sort: "",
    sortTable: () => {},
    statuses: [],
    totalCount: 0,
    totalPages: 0
});

export const useAdviceGridData = () => React.useContext(AdviceGridDataContext);

export const AdviceGridDataProvider = ({
    children,
    initialLimit = 50,
    initialPage = 1,
    initialSort = "reference+desc"
} : Readonly<AdviceGridDataProviderProps>) : React.ReactNode => {
    // get the filter information from the overall filter store/context
    const { filter, isReady } = useFilterContext();
    const statusQuery = useHoFetchBusinessSheetStatusesQuery();

    // setup state and our query state (does not load by default (lazy))
    const [sort, setSort] = React.useState<string>(initialSort);
    //const [page, setPage] = React.useState<number>(initialPage);
    const [pageLimit, setPageLimit] = React.useState<number>(initialLimit);
    const [cacheKey, setCacheKey] = React.useState<number>(new Date().valueOf());
    const [isClearing, setIsClearing] = React.useState<boolean>(false);
    const [isLoadingState, setIsLoadingState] = React.useState<boolean>(false);
    const [load, { data, error, isError, isLoading, isFetching }] = useLazyHoSearchRecommendationsQuery();

    // extract the results of the last load to our report search
    // note: we need to have a default set of data in-case we have an undefined response
    const { pagination, results, newRecordsSinceCache } = data || { pagination: { page: 1, pageSize: pageLimit, totalCount: 0, totalPages: 1 }, results: [], newRecordsSinceCache: 0 };
    const { page, totalCount, totalPages } = pagination;
    const hasMore = totalCount > 0 && page < totalPages;
    const isNextPageLoading = isFetching === true || isLoading === true || isLoadingState === true;

    const clear = () : void => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading || !isReady || statusQuery.isLoading) {
            return;
        }

        const newCacheKey = new Date().valueOf();
        setIsLoadingState(_ => true);
        setIsClearing(_ => true);
        
        load({
            ...filter,
            cacheKey: newCacheKey,
            sort,
            page: 1,
            limit: pageLimit
        }).unwrap().then(_ => {
            setCacheKey(_ => newCacheKey);
        }).finally(() => {
            setIsLoadingState(_prev => false);
            setIsClearing(_ => false);
        });
    };

    const reload = (e: any) : void => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }
        clear();
    };

    const isRowLoaded = (index: number) : boolean => {
        return !hasMore || index < results.length;
    }

    const loadMore = () : void => {
        // safety check to make sure we don't reload when already loading!
        if (isNextPageLoading) {
            return;
        }

        load({
            cacheKey,
            sort,
            page: page + 1,
            limit: pageLimit,
            ...filter,
        });
    };

    const sortTable = (property: string) : void => {
        // we want the sort to initially be descending ??
        let newDirection: string | null = "";
        let [sortProperty, direction] = sort.split("+");

        // if the current property is already selected, reverse the sort direction
        if (property === sortProperty) {
            newDirection = direction === "DESC" ? null : "+DESC";
        }

        setSort(property + (newDirection ?? ""));
    };

    React.useEffect(clear, [filter, sort, statusQuery, isReady]);

    return (
        <AdviceGridDataContext.Provider value={{ 
            cacheKey,
            clear,
            data: results,
            error,
            isClearing,
            isError,
            isFetching,
            isLoading,
            isNewRecords: newRecordsSinceCache > 0,
            isNextPageLoading,
            isRowLoaded,
            limit: pageLimit,
            loadMore,
            newRecordsSinceCache,
            page,
            reload,
            sort,
            sortTable,
            statuses: statusQuery?.data ?? [],
            totalCount,
            totalPages
        }}>
            {children}
        </AdviceGridDataContext.Provider>
    );
};

export default AdviceGridDataContext;