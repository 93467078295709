import React from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import AdministrationFeesLayout from "../layouts/AdministrationFeesLayout";
import { NavigationTabs } from "../components/navigation";
import {
    FeeManagementPage,
    CashManagementPage,
    DebtorManagementPage,
    AgedDebtorsManagementPage,
    FeeManagementReportsPage
} from "../pages/administration";

const AdministrationFeesRoutes = {
    path: 'fees/*',
    pathBase: '/administration/fees',
    defaultRoute: 'editfees',
    roles: [],
    element: AdministrationFeesLayout,
    children: [
        {
            path: 'editfees',
            icon: 'fa-table',
            label: 'Edit Fees',
            roles: [],
            element: FeeManagementPage
        },
        {
            path: 'editcash',
            icon: 'fa-table',
            label: 'Edit Cash',
            roles: [],
            element: CashManagementPage
        },
        {
            path: 'debtors',
            icon: 'fa-table',
            label: 'Debtors',
            roles: [],
            element: DebtorManagementPage
        },
        /*{
            path: 'ageddebtors',
            icon: 'fa-table',
            label: 'Aged Debtors',
            roles: [],
            element: AgedDebtorsManagementPage
        },*/
    ]
};
/*
        {
            path: 'reports',
            icon: 'fa-file-signature',
            label: 'Reports',
            roles: [],
            element: FeeManagementReportsPage
        }
*/
//pass down a function onTabChange parameter new index - to AdmninistrationFeesLayoit!!!
export const AdministrationFeeRouteTabs = ({onTabChanged}) => <NavigationTabs pathBase={AdministrationFeesRoutes.pathBase} options={AdministrationFeesRoutes} onTabChanged={onTabChanged} />

export default AdministrationFeesRoutes;