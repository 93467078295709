import { ControllerFetchType } from "../../../helpers/fetchTypes";
import { useFetchWrappersQuery } from "../../../services/products/wrapperApiEndpoints";
import FormSelect from "../FormSelect";

const ProductWrappersSelect = ({
    defaultValue,
    label,
    onBlur,
    onChange,
    ...rest
}) => {

    const { data, isFetching } = useFetchWrappersQuery({ fetchType: ControllerFetchType.List });

    return <FormSelect
        defaultValue={defaultValue}
        isLoadingOptions={isFetching}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        options={data ?? []}
        {...rest}
    />;
};

export default ProductWrappersSelect;