import React from "react";
import { useAdviceGridData } from "./hooks/AdviceGridDataContext";
import { AdviceGridHeader } from "./components/AdviceGridHeader";
import { AdviceGridRow } from "./components/AdviceGridRow";
import { VirtualTable } from "../../components/tables";
import { TableRecordCountDisplayFooter } from "../../components/tables/VirtualTable";

export * from "./hooks/AdviceGridDataContext";

export const PendingApproval = () => {
    const {
        clear: refreshTable,
        data,
        error,
        isNextPageLoading,
        isNewRecords,
        isRowLoaded,
        loadMore,
        newRecordsSinceCache,
        totalCount
    } = useAdviceGridData();

    const isNewRecordsDisplayed = isNewRecords && !isNextPageLoading;
    return (
        <VirtualTable 
            enableScrollTracking={false}
            row={AdviceGridRow}
            header={(
                <thead>
                    <tr>
                        <AdviceGridHeader property="qualifiedReference">Reference</AdviceGridHeader>
                        <AdviceGridHeader property="masterAccountName">Client</AdviceGridHeader>
                        <AdviceGridHeader property="status">Status</AdviceGridHeader>
                        <AdviceGridHeader property="statusDate">Status Date</AdviceGridHeader>
                        <AdviceGridHeader property="description">Description</AdviceGridHeader>
                        <AdviceGridHeader property="adviserName">Adviser</AdviceGridHeader>
                        <th className="sticky-header">&nbsp;</th>
                    </tr>
                </thead>
            )}
            footer={
                <TableRecordCountDisplayFooter 
                    totalCount={totalCount}
                    isNewRecordsDisplayed={isNewRecordsDisplayed}
                    newRecordsSinceCache={newRecordsSinceCache}
                    reload={refreshTable}
                />
            }
            error={error}
            isLoading={isNextPageLoading}
            isItemLoaded={isRowLoaded}
            loadMoreItems={loadMore}
            itemSize={50}
            itemCount={totalCount}
            itemData={data}
        />
    )
};

export default PendingApproval;