import { useMemo } from "react";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";

const DebtorTableHeader = ({ sort, setSort }) => {

    const property = useMemo(() => sort?.split("+")[0], [sort]);
    const direction = useMemo(() => sort?.split("+")[1], [sort]);

    return (
        <thead>
            <tr>
            <StandardTableSortableHeader
                    active={property === "feeDate"}
                    onClick={() => setSort("feeDate")}
                    direction={direction}
                >
                    Fee Date
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "feeType"}
                    onClick={() => setSort("feeType")}
                    direction={direction}
                >
                    Fee Type
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "totalFeeValue"}
                    onClick={() => setSort("totalFeeValue")}
                    direction={direction}
                >
                    Fee
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "debt"}
                    onClick={() => setSort("debt")}
                    direction={direction}
                >
                    Debt
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "masterAccount"}
                    onClick={() => setSort("masterAccount")}
                    direction={direction}
                >
                    Client
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "applicationId"}
                    onClick={() => setSort("applicationId")}
                    direction={direction}
                >
                    Application ID
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "applicationDescription"}
                    onClick={() => setSort("applicationDescription")}
                    direction={direction}
                >
                    Application
                </StandardTableSortableHeader>
                <StandardTableSortableHeader/>
            </tr>
        </thead>
    );
};

export default DebtorTableHeader;