import React from "react";
import { useLazyFetchAssetQuery, useLazySearchAssetsPagedQuery } from "../../../services/assets";
import FormSelectPaged from "../FormSelectPaged";

const AssetSearchPagedSelect = ({ valueObject, setValue, isMulti = false, ...rest }) => {

    return <FormSelectPaged
        setValue={setValue}
        valueObject={valueObject}
        additional={{page: 1, limit: 50, searchType: "either", fetchType: "list", sort:"AssetName" }}
        query={useLazySearchAssetsPagedQuery}
        onOptionsLoaded={(_, results) => results}
        isMulti={isMulti}
        searchTermPropertyName={"searchTerm"}
        fetchQuery={useLazyFetchAssetQuery}
        onStartFetch={(value) => ({ assetId: value })}
        onFetched={(obj) => ({ value: obj.assetId, label: obj.longAssetName })}
        {...rest}
    />;
};

export default AssetSearchPagedSelect;
