import { useNavigate } from "react-router-dom";
import { ProfileDisplay } from "../../../components";
import { FixedCell, StandardTableSortableHeader, ThemedTableHeader } from "../../../components/tables/StandardTable";
import { TableLoadingRow } from "../../../components/tables/VirtualTable";

export const HoldingsSummaryHeader = ({ getSortProps }) => (
    <thead>
        <tr>
            <StandardTableSortableHeader {...getSortProps("adviserName")}>
                Adviser
            </StandardTableSortableHeader>
            <ThemedTableHeader className="text-truncate sticky-header">
                Ref
            </ThemedTableHeader>
            <StandardTableSortableHeader {...getSortProps("designation")}>
                Designation
            </StandardTableSortableHeader>
            <StandardTableSortableHeader {...getSortProps("productName")}>
                Product
            </StandardTableSortableHeader>
            <StandardTableSortableHeader {...getSortProps("assetName")}>
                Share Class
            </StandardTableSortableHeader>
            <StandardTableSortableHeader {...getSortProps("totalValue")} className="text-end">
                Value
            </StandardTableSortableHeader>
        </tr>
    </thead>
);

const HoldingsSummaryRow = ({ data, index, style }) => {
    const { height } = style;

    const navigate = useNavigate();

    if (index >= data.length)
        return <TableLoadingRow style={{ height }} />;

    const {
        adviserId,
        masterAccountId,
        masterAccountReference,
        designation,
        productName,
        assetName,
        totalValue
    } = data[index];

    return <tr style={{ height }}>
        <FixedCell className="text-truncate align-middle" key={`row-col-${index}-adviser`} width={20}>
            {adviserId == null ? "Not Set" : <ProfileDisplay userId={adviserId} />}
        </FixedCell>
        <FixedCell className="text-truncate align-middle" key={`row-col-${index}-reference`} width={10}>
            {masterAccountReference}
        </FixedCell>
        <FixedCell className="text-truncate align-middle" key={`row-col-${index}-designation`} width={20}>
            <button className="anchor" onClick={() => navigate(`/client/${masterAccountId}`)}>{designation}</button>
        </FixedCell>
        <FixedCell className="text-truncate align-middle" key={`row-col-${index}-product`} width={20}>
            {productName}
        </FixedCell>
        <FixedCell className="text-truncate align-middle" key={`row-col-${index}-shareclass`} width={20}>
            {assetName}
        </FixedCell>
        <FixedCell className="text-truncate align-middle text-end" key={`row-col-${index}-value`} width={10}>
            {totalValue.toLocaleString("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 })}
        </FixedCell>
    </tr>
}

export default HoldingsSummaryRow;