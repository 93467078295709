import React from "react";
import styled from "styled-components";
import { NumericFormat } from "react-number-format";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "react-oidc-context";
import { useEditTransaction } from "../../clienttransactionsedit/hooks/EditTransactionContext";
import toast from "react-hot-toast";
import { DateDisplay } from "../../../components";

const ClientTransactionsTableRowBaseCell = styled.td`
    width: ${({ width }) => width}%;
    vertical-align: middle;
    max-width: 50px;
    text-align: ${(props) => props.textAlign ?? "left"};
`;

const ClientTransactionsTableRowActionColParent = styled.td`
    width: ${({ width }) => width}%;
    cursor: pointer;
    vertical-align: middle;
    text-align: ${(props) => props.textAlign ?? "left"};
`;

const ClientTransactionsEditActionMenuItem = ({
    investmentTransactionId,
    setSelectedRow,
    setShowEditModal,
}) => {

    
    const onHandleClick = () => {
        if (!setSelectedRow || typeof setSelectedRow !== "function") {
            return;
        }
        
        if (!setShowEditModal || typeof setShowEditModal !== "function") {
            return;
        }
        setSelectedRow(investmentTransactionId);

        setShowEditModal(true);
    };

    return <Dropdown.Item onClick={onHandleClick}>Edit</Dropdown.Item>;
};

const DuplicateTransactionActionMenuItem = ({
    investmentTransactionId,
    duplicate
}) => {
    const onHandleClick = () => {
        if (!duplicate || typeof duplicate !== "function") {
            return;
        }

        duplicate(investmentTransactionId);
    };

    return <Dropdown.Item onClick={onHandleClick}>Duplicate</Dropdown.Item>;
};

const UpdateTransactionActiveActionMenuItem = ({
    isActive,
    investmentTransactionId,
    updateActive
}) => {
    const onHandleClick = () => {
        if (!updateActive || typeof updateActive !== "function") {
            return;
        }

        updateActive(investmentTransactionId, "isActive", !isActive);
     };

    return <Dropdown.Item onClick={onHandleClick}>{isActive ? "Deactivate" : "Activate"}</Dropdown.Item>;
};

const ClientTransactionsTableRowActionColToggle = React.forwardRef(
    ({ ...rest }, ref) => {
        return (
            <div ref={ref} {...rest}>
                <FontAwesomeIcon icon="fa-ellipsis" />
            </div>
        );
    }
);

const ClientDocumentsTableRowActionCol = ({ transaction }) => {

    const menuId = React.useId();
    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;
    const {setEditTransaction, setDuplicateTransaction, saveChanges, saveExternalChanges } = useEditTransaction();

    const onHandleClickShowEditModal = () => {
        setEditTransaction(transaction);
    };

    const onHandleClickDuplicateTransaction = () => {
        setDuplicateTransaction(transaction);
    };

    const onHandleClickSetActive = () => {
        toast.promise(saveExternalChanges(transaction.investmentTransactionId, transaction.masterAccountId, [{ op: "replace", path: `/isActive`, value: !transaction.isActive }]), { 
            loading: 'Saving transaction...', 
            success: 'Transaction Saved', 
            error: (err) => <div>{err?.data}</div> 
        }); 
       
    };


    const canEdit = () => {

        if(transaction?.custodyPartner?.custodianCode != null && !roles.includes('custodian_transaction_write')){
            return false;
        }

        return true;
    }

    return (
        <ClientTransactionsTableRowActionColParent>
            <Dropdown className="caret-off text-center">
                <Dropdown.Toggle
                    id={menuId}
                    as={ClientTransactionsTableRowActionColToggle}
                />
                <Dropdown.Menu>
                    <Dropdown.Item disabled={!canEdit()} onClick={onHandleClickShowEditModal}>
                        <FontAwesomeIcon icon="fa fa-pencil" className="has-pointer" size="sm" /> <span className="ms-1">Edit</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onHandleClickDuplicateTransaction}>
                        <FontAwesomeIcon icon="fa fa-layer-group" className="has-pointer" size="sm" /> <span className="ms-1">Duplicate</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onHandleClickSetActive}>
                        <FontAwesomeIcon icon={(transaction.isActive) ? "fa fa-xmark" : "fa fa-check"} className="has-pointer" size="sm" /> <span className="ms-1">{transaction.isActive ? "Deactivate" : "Activate"}</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ClientTransactionsTableRowActionColParent>
    );
};

const ClientTransactionsTableRow = ({ data, index, style }) => {

    const auth = useAuth();
    const { user: { profile: { roles } } } = auth;
    const canEdit = roles.includes("transaction_write");

    const { height } = style;

    // do not return a data row when the data has not yet been collected from the backend
    if (index >= data.length) {
        return null;
	}

    const {
        investmentTransactionId,
        designationId,
        designation,
        investmentOperation,
        investmentOperationName,
        defInvestmentOperation,
        providerId,
        provider,
        productId,
        product,
        status,
        statusName,
        asset,
        assetName,
        longAssetName,
        assetId,
        custodyPartner,
        investmentPartnerName,
        custodianAccountReference,
        providerAccountReference,
        quantity,
        value,
        investmentDate,
        includeInValuation,
        isActive
    } = data[index];

   

    const styles = {
        backgroundColor: !includeInValuation ? '#dfdfdf': 'inherit',
        textDecoration: !isActive ? 'line-through': 'none',
        height
    };

    return (
        <tr style={styles}>
            <ClientTransactionsTableRowBaseCell width={15.5} className="text-truncate">
                <span>{designation?.designation}</span>
            </ClientTransactionsTableRowBaseCell>
            <ClientTransactionsTableRowBaseCell width={10} className="text-truncate">
                <span>{investmentOperationName}</span>
            </ClientTransactionsTableRowBaseCell>
            <ClientTransactionsTableRowBaseCell width={13}  className="text-truncate">
                <span>{product?.productName}</span>
            </ClientTransactionsTableRowBaseCell>
            <ClientTransactionsTableRowBaseCell width={15} className="text-truncate">
                <span>{longAssetName}</span>
            </ClientTransactionsTableRowBaseCell>
            <ClientTransactionsTableRowBaseCell width={13} className="text-truncate">
            <span>{custodyPartner?.providerName}</span>
            </ClientTransactionsTableRowBaseCell>
            <ClientTransactionsTableRowBaseCell width={8}  className="text-truncate">
                <span>{statusName}</span>
            </ClientTransactionsTableRowBaseCell>
            <ClientTransactionsTableRowBaseCell width={8} className="text-truncate">
                <span>{custodianAccountReference}</span>
            </ClientTransactionsTableRowBaseCell>
            <ClientTransactionsTableRowBaseCell width={5} className="text-truncate text-end">
                <span>{quantity}</span>
            </ClientTransactionsTableRowBaseCell>
            <ClientTransactionsTableRowBaseCell width={5} className="text-end">
                <NumericFormat
                    value={value}
                    thousandSeparator={true}
                    decimalScale={2}
                    displayType="text"
                    prefix={"£"}
                />
            </ClientTransactionsTableRowBaseCell>
            <ClientTransactionsTableRowBaseCell width={5} className="text-truncate">
                {investmentDate != null &&
                    <DateDisplay>{investmentDate}</DateDisplay>
                }
            </ClientTransactionsTableRowBaseCell>
            {canEdit &&
            <ClientDocumentsTableRowActionCol 
                width={1.5} 
                className="align-middle text-center action-col px-4"
                transaction={data[index]}>
            </ClientDocumentsTableRowActionCol>}
        </tr>
    );
};

export default ClientTransactionsTableRow;