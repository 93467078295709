import React, { useMemo, useState } from "react";
import classNames from "classnames";
//import GridWrapper from "../../../../components/GridWrapper";
import { FormLabel } from "../../../components/forms";
import { CurrencyInput, DateInput, FormInput } from "../../../components/forms";
import styled from "styled-components";
import { Main } from "../../../components/utilities";



export const GridItem = ({ className, data, openModal, ...rest }) => {
    const { feeMonth, totalDebit } = data;
    return (
        <div 
            className={classNames(className, "d-flex justify-content-end border has-pointer")} 
            onClick={() => openModal(feeMonth)} {...rest}
        >
            {totalDebit === "" && (<span>&nbsp;</span>)}
            {totalDebit !== "" && (<CurrencyInput displayType="text" value={totalDebit} />)}
        </div>
    );
};

export const StyledGridItem = styled(GridItem)`
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const AgedDebtorsGridColumnLabel = styled(FormLabel)`
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AgedDebtorsLayoutGrid = styled(Main)`
    display: grid;
    grid-template-columns: 150px auto;
    grid-template-rows: auto auto;
    gap: 0px 0px;
    overflow-y: scroll;
    overflow-x: scroll;
    grid-template-areas:
    "grid-dates-header grid-dates-header "
    "grid-dates-left grid-data";
`;

const GridArea = styled.div`
    grid-area: ${props => props.gridArea};
`;

/*
const AgedDebtorsGridDatesHeader = styled(Main)`
display: grid;
grid-auto-columns: repeat(${columns.length+1}, 5px);
max-height: 5px;
overflow-y: scroll;
overflow-x: scroll;

`;

const AgedDebtorsGridDatesLeft = styled(Main)`
display: grid;
grid-auto-columns: repeat(${columns.length+1}, 5px);
max-height: 5px;
overflow-y: scroll;
overflow-x: scroll;
`;

const AgedDebtorsGridData = styled(Main)`
display: grid;
grid-auto-columns: repeat(${columns.length}, 5px);
overflow-y: scroll;
overflow-x: scroll;

    
    //function transpose(array) {
        //return array.reduce((prev, next) => next.map((item, i) =>
            //(prev[i] || []).concat(next[i])
        //), []);
    //}
    

    //newarray
    //so for 0 < array[0].length
    //I need to be adding an array of all the [0] entries of the array
    //then another array of all the [1] entries 
    //then [n] entries right up to the array[0]. length -1 (140)

`;*/

const AgedDebtorsTable = ({ label = "", data = [], error = null, isLoading = false}) => {
    const [leftDates, setLeftDates] = useState([{}]);
    const [headerDates, setHeaderDates] = useState([{}]);
    //const [debtorsData, setDebtorsData] = useState([{}]);

    const transpose = (array) => {
        var transposedArray = [];
        //var arrayLength = array[0].length;
        var arrayLength = 13;
        for(var i = 0; i < array[0].length; i++){
            var newArray = [];
            for(var j=0; j < array.length; j++){
                newArray.push(array[j][i]);
            }
            transposedArray.push(newArray);
        };
        return transposedArray;
    };

    const columns = useMemo(() => {
        if (data.length < 1 ) {
            //console.log('empty data');
            return [];
        }
        //console.log('data', data);

        let retval = [];
        let theHeaderDates = [];
        let theLeftDates = [];
        let debtorsList = [];
        //console.log(data);
        for(var j=0; j < data[0].agedDebtorsDateGroupedViewModelList.length; j++){
            theLeftDates.push({date:data[0].agedDebtorsDateGroupedViewModelList[j].feeMonth,month:new Date(data[0].agedDebtorsDateGroupedViewModelList[j].feeMonth).toLocaleDateString('en-uk', { month:"short",year:"numeric"})});
        }
        setLeftDates(theLeftDates);

        var blankDebtors = 0;
        var blankDebtor={feeMonth: "1066-01-01T00:00:00", totalDebit: ''};
        for (var i = 0; i <data.length; i++) {
            //console.log(data[i]);
            theHeaderDates.push({fromDate: data[i].fromDate, headerDate: new Date(data[i].fromDate).toLocaleDateString('en-uk')});
            var newArr = [];
            if(i > 0){
                for(var p=1;p<=blankDebtors;p++){
                    newArr.push(blankDebtor);
                }
            }
            newArr.push(...data[i].agedDebtorsDateGroupedViewModelList);
            debtorsList.push(newArr);
           
            retval.push({ fromDate: new Date(data[i].fromDate).toLocaleDateString('en-uk'), fromDateDebtorAmount: data[i].agedDebtorsDateGroupedViewModelList[0].totalDebit, agedDebtorList: data[i].agedDebtorsDateGroupedViewModelList});
           
            for(var k = 0; k < data[k].length; j++){
                //console.log(new Date(data[i][k].feeMonth).toLocaleDateString());
                //console.log(data[i][k].totalDebit);
                //retval.push({ label: new Date(data[i][k].feeMonth).toLocaleDateString(), property: data[i][k].totalDebit });
            }
            blankDebtors++;
        }
        setHeaderDates(theHeaderDates);
        //console.log('debtorsList', debtorsList);
        var transposed = transpose(debtorsList);
        //console.log('transposed',transposed);
        //console.log(data.length);
        return transposed;
    }, [ data ]);

    //console.log('columns', columns);

    if (isLoading) {
        return (
            <div>
                LOADING THE DATA...
            </div>
        );
    }

    return (
        <AgedDebtorsLayoutGrid>
            <GridArea gridArea="grid-dates-header" className="d-flex flex-nowrap justify-content-evenly align-items-center overflow-scroll">
            <AgedDebtorsGridColumnLabel className="text-end"></AgedDebtorsGridColumnLabel>
                {headerDates.map((el, _i) => <AgedDebtorsGridColumnLabel key={el.fromDate} className="text-center border">{el.headerDate}</AgedDebtorsGridColumnLabel>)}  
            </GridArea>
            <GridArea gridArea="grid-dates-left" className="d-flex flex-column">
                {leftDates.map((el, _i) => <AgedDebtorsGridColumnLabel key={el.date} className="">{el.month}</AgedDebtorsGridColumnLabel>)}  
            </GridArea>
            <GridArea gridArea="grid-data">
                {columns.map(((d, i) => (
                    <div key={i} className="d-flex flex-nowrap justify-content-evenly align-items-center overflow-scroll">
                        {d.map((el, _i) => (
                            <StyledGridItem key={el.feeMonth} data={el} openModal={(e) => console.log(e)} />
                        ))}
                    </div>   
                )))}   
            </GridArea>
        </AgedDebtorsLayoutGrid>
    );
};
export default AgedDebtorsTable;

                   {/*<AgedDebtorsGridColumnLabel key={el.feeMonth} className="border">
                                {el.totalDebit === "" && (<span>&nbsp;</span>)}
                                {el.totalDebit !== "" && (
                                    <CurrencyInput displayType="text" className="text-end" value={el.totalDebit} />
                                )}
                            </AgedDebtorsGridColumnLabel>*/}