import { useMemo } from "react";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";
import { useAdministrationFeesProvider } from "../../../hooks/AdministrationFeesContext";
const FeeGroupingTableHeader = ({ sort, setSort }) => {

    const property = useMemo(() => sort?.split("+")[0], [sort]);
    const direction = useMemo(() => sort?.split("+")[1], [sort]);
    const {currentSearchType} = useAdministrationFeesProvider();
  
    return (
        <thead>
            <tr>
                <StandardTableSortableHeader
                    active={property === "feeType"}
                    onClick={() => setSort("feeType")}
                    direction={direction}
                >
                    Fee Type
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "providerName"}
                    onClick={() => setSort("providerName")}
                    direction={direction}
                >
                    Provider
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "masterAccountName"}
                    onClick={() => setSort("masterAccountName")}
                    direction={direction}
                >
                    Client
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "feeDate"}
                    onClick={() => setSort("feeDate")}
                    direction={direction}
                >
                    Fee Date
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "totalFeeValue"}
                    onClick={() => setSort("totalFeeValue")}
                    direction={direction}
                >
                    Total {currentSearchType !== "cash" ? "Fees" : "Cash"} 
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "TotalVatValue"}
                    onClick={() => setSort("TotalVatValue")}
                    direction={direction}
                >
                    Total VAT
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "adviserName"}
                    onClick={() => setSort("adviserName")}
                    direction={direction}
                >
                    Current Adviser
                </StandardTableSortableHeader>
                <StandardTableSortableHeader
                    active={property === "description"}
                    onClick={() => setSort("description")}
                    direction={direction}
                >
                    Description
                </StandardTableSortableHeader>
                <StandardTableSortableHeader/>
            </tr>
        </thead>
    );
};

export default FeeGroupingTableHeader;