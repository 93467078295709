import Skeleton from "react-loading-skeleton"
import { FormLabel } from "../../../components/forms"
import FormSelect from "../../../components/forms/FormSelect"
import GenericUserSelect from "../../../components/forms/selects/GenericUserSelect"
import { AccordionBody, AccordionHeader, AccordionListItem } from "../../../components/utilities"
import CommentaryInput from "../components/CommentaryInput"
import useFundDetails from "../hooks/useFundDetails"

const CommentsAndOpinion = () => {
    const [{ patchAssetGroupSingle, selectControlStatus }, { assetGroup, assetGroupIsNotReady, isApprovingCommentaries, isPublishingCommentaries }, { canEditDetails, canEditCommentary, canChangeControlStatus, options }] = useFundDetails();

    return <AccordionListItem eventKey="comments-and-opinion">
        <AccordionHeader>Investment Committee Comments & Opinion</AccordionHeader>
        <AccordionBody>
            <div className="row">
                <div className="col-3 flex-column">
                    <FormLabel>
                        Analyst
                    </FormLabel>
                    {assetGroupIsNotReady
                        ? <Skeleton />
                        : <GenericUserSelect
                            isDisabled={!canEditDetails}
                            includeInactive={true}
                            roles={["ic_read"]}
                            users={[assetGroup?.investmentAnalystId]}
                            defaultValue={assetGroup?.investmentAnalystId}
                            onBlur={(selection) => patchAssetGroupSingle("investmentAnalystId", selection.value)}
                        />}
                </div>
                <div className="col-3 flex-column">
                    <FormLabel>
                        Opinion
                    </FormLabel>
                    {assetGroupIsNotReady
                        ? <Skeleton />
                        : <FormSelect
                            options={options.opinion}
                            defaultValue={assetGroup?.opinion}
                            onBlur={(selection) => patchAssetGroupSingle("opinion", selection.value)}
                            isDisabled={!canEditDetails}
                        />}
                </div>
                <div className="col-4 flex-column">
                    <FormLabel>
                        Current Guidance
                    </FormLabel>
                    {assetGroupIsNotReady
                        ? <Skeleton />
                        : <FormSelect
                            options={options.guidance}
                            defaultValue={assetGroup?.guidance}
                            onBlur={(selection) => patchAssetGroupSingle("guidance", selection.value)}
                            isDisabled={!canEditDetails}
                        />}
                </div>
                <div className="col-2 flex-column">
                    <FormLabel>
                        Control
                    </FormLabel>
                    {assetGroupIsNotReady
                        ? <Skeleton />
                        : <FormSelect
                            options={options.controlStatus}
                            defaultValue={assetGroup?.controlStatus}
                            onBlur={selectControlStatus}
                            isDisabled={isApprovingCommentaries || isPublishingCommentaries || !canChangeControlStatus}
                        />}
                </div>
            </div>

            <CommentaryInput
                className="pt-2"
                label="Fund Manager"
                value={assetGroup?.fundManagerText}
                statusDisplayText={assetGroup?.fundManagerStatusDisplayText}
                dateDisplayText={assetGroup?.fundManagerDateDisplayText}
                isApproving={isApprovingCommentaries}
                isPublishing={isPublishingCommentaries}
                commentaryType='fundManager'
                readOnly={!canEditCommentary}
            />

            <CommentaryInput
                label="Investment Rationale"
                value={assetGroup?.rationaleText}
                statusDisplayText={assetGroup?.rationaleStatusDisplayText}
                dateDisplayText={assetGroup?.rationaleDateDisplayText}
                isApproving={isApprovingCommentaries}
                isPublishing={isPublishingCommentaries}
                commentaryType='rationale'
                readOnly={!canEditCommentary}
            />

            <CommentaryInput
                label="Current Opinion"
                value={assetGroup?.currentOpinionText}
                statusDisplayText={assetGroup?.currentOpinionStatusDisplayText}
                dateDisplayText={assetGroup?.currentOpinionDateDisplayText}
                isApproving={isApprovingCommentaries}
                isPublishing={isPublishingCommentaries}
                commentaryType='currentOpinion'
                readOnly={!canEditCommentary}
            />

            <CommentaryInput
                label="For Discussion"
                value={assetGroup?.forDiscussionText}
                statusDisplayText={assetGroup?.forDiscussionStatusDisplayText}
                dateDisplayText={assetGroup?.forDiscussionDateDisplayText}
                commentaryType='forDiscussion'
                checkbox
                enabled={assetGroup?.forDiscussionEnabled ?? false}
                readOnly={!canEditCommentary}
            />
        </AccordionBody>
    </AccordionListItem>
}

export default CommentsAndOpinion;