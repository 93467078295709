import { NavigationTabs } from "../components/navigation";
import { AssetViewTypes } from "../features/assetsunderadvicegrid/hooks/AssetsUnderAdviceContext";
import AssetsUnderAdviceLayout from "../layouts/AssetsUnderAdviceLayout";
import { ClientsSummaryPage, FundsSummaryPage, HoldingsSummaryPage } from "../pages/analysis-assets";

const AdministrationAssetAnalysisRoutes = {
    // TODO: Check paths and roles
    path: 'analysis/assets/*',
    pathBase: '/administration/analysis/assets',
    defaultRoute: "funds-summary",
    roles: ["sys_admin_access"],
    element: AssetsUnderAdviceLayout,
    children: [
        {
            path: 'funds-summary',
            icon: 'fa-sterling-sign',
            label: 'Funds Summary',
            element: FundsSummaryPage,
            viewType: AssetViewTypes.FundsSummary
        },
        {
            path: 'clients-summary',
            icon: 'fa-users',
            label: "Client Summary",
            element: ClientsSummaryPage,
            viewType: AssetViewTypes.ClientsSummary
        },
        {
            path: 'holdings-summary',
            icon: 'fa-wallet',
            label: "Holdings Summary",
            element: HoldingsSummaryPage,
            viewType: AssetViewTypes.HoldingsSummary
        }
        // TODO: More views
    ]
};

export const AssetAnalysisRouteTabs = (props) => <NavigationTabs {...props} pathBase={AdministrationAssetAnalysisRoutes.pathBase} options={AdministrationAssetAnalysisRoutes} />

export default AdministrationAssetAnalysisRoutes;