import { DateDisplay } from "../../../components";
import Skeleton from "react-loading-skeleton";
import { useRemindersGrid } from "../hooks/RemindersGridContext";
import styled from "styled-components";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { FormCheck } from "../../../components/forms";

const StyledCell = styled.td`
    width: ${props => props.width}%;
    max-width: 50px;
    vertical-align: middle;
`;

const RemindersGridRow = ({ index, style: { height, width }, data }) => {

    const { columns, completeReminder, isClearing } = useRemindersGrid();

    if (!data[index] || isClearing) {
        return <tr style={{ height, width }}>
            <td colSpan={columns.length}>
                <Skeleton height={height - 10} />
            </td>
        </tr>
    }

    const {
        reminderId,
        applicationId,
        applicationReference,
        message,
        reminderDate,
        completedDate,
        completedBy
    } = data[index];

    const onCompleteReminderClick = () => completeReminder(reminderId, completedDate);

    return <tr style={{ height, width }}>
        <StyledCell width={10} className="text-truncate">
            <Link to={`/administration/applications/${applicationId}`}>{applicationReference}</Link>
        </StyledCell>
        <StyledCell width={37.5} className="text-truncate">{message}</StyledCell>
        <StyledCell width={15} className="text-truncate">
            {moment(reminderDate).isValid()
                ? <DateDisplay>{reminderDate}</DateDisplay>
                : <span className="px-2">-</span>}
        </StyledCell>
        <StyledCell width={15} className="text-truncate">
            {moment(completedDate).isValid()
                ? <DateDisplay>{completedDate}</DateDisplay>
                : <span className="px-2">-</span>}
        </StyledCell>
        <StyledCell width={15} className="text-truncate">
            {completedBy}
        </StyledCell>
        <StyledCell width={7.5} align="center" >
            <FormCheck
                isChecked={completedDate !== null}
                onClick={onCompleteReminderClick}
                groupClassName={"mx-0"}
                disableAnimations={false}
            />
        </StyledCell>
    </tr>
};

export default RemindersGridRow;