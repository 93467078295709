import _ from "lodash";
import React from "react";
import classNames from "classnames";
import { useAdviceGridData } from "../hooks/AdviceGridDataContext";
import { StandardTableSortableHeader } from "../../../components/tables/StandardTable";

export type AdviceGridHeaderProps = {
    children: React.ReactNode,
    className?: any,
    property: string,
};

export const AdviceGridHeader = ({ children, className, property } : Readonly<AdviceGridHeaderProps>) => {
    const { sort, sortTable } = useAdviceGridData();
    const sortProperties = sort.split(',');
    const isHeaderSorted = _.some(sortProperties, i => i.includes(property));
    const headerDirection = isHeaderSorted && _.some(sortProperties, i => i.toLowerCase().includes('+desc')) ? "desc" : null;

    const handleClick = () : void => sortTable(property);

    return (
        <StandardTableSortableHeader
            className={classNames(className)}
            active={isHeaderSorted}
            direction={headerDirection}
            onClick={handleClick}
            isSticky={true}
        >
            {children}
        </StandardTableSortableHeader>
    );
};

export default AdviceGridHeader;