import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBeforeUnload, useBlocker } from "react-router-dom";
import MessageBox from "./MessageBox";
import styled, { useTheme } from "styled-components";
import { AttachmentsToggle, ThemedSpan } from "../../../components/utilities";
import { FormInput, FormSelect, FormTextArea } from "../../../components/forms";
import { IconButton } from "../../../components";
import { ConfirmModal, FileUploadModal } from "../../../components/modals";
import SendMessageConfirmationModal from "./SendMessageConfirmationModal";
import { useClientMessaging } from "../hooks/ClientMessagingContext";
import useMessageUsers from "../hooks/useMessageUsers";
import useMessage from "../hooks/useMessage";
import toast from "react-hot-toast";

const RecipientType = {
    Client: 0,
    Staff: 1
};

const MessageHeader = styled.div`
    grid-column-gap: 20px;
`;

const DraftMessage = React.forwardRef(({ message, onDeleteCallbackFn }, ref) => {
    const theme = useTheme();

    const {
        threadId,
        messageId,
        attachments,
        recipients,
        threadSubject,
        deadlineDate,
        messagePriority,
        projectId,
        isActionRequired,
        message: messageText
    } = message || {};

    const { threadId: pathThreadId } = useClientMessaging();
    const { results: messageUsers } = useMessageUsers();
    const [currentMessage, setCurrentMessage] = useState(messageText);
    const [shouldSend, setShouldSend] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showFileUpload, setShowFileUpload] = useState(false);
    const canEditSubject = pathThreadId === null;

    const {
        deleteDraftResult,
        isEditingMessage,
        uploadAttachmentResult: { isLoading: isUploadingAttachment },
        deleteDraft,
        sendMessage,
        updateMessage,
        updateMessageRecipients,
        updateMessageThread,
        uploadMessageAttachment
    } = useMessage(messageId);

    const recipientOptions = useMemo(() => {
        // check we got some data
        if (!messageUsers || !Array.isArray(messageUsers)) {
            return [];
        }

        // now, lets setup the grouping
        return [
            {
                label: 'Master Account',
                options: messageUsers.filter((el) => el.userType === 0)
            },
            {
                label: 'System Users',
                options: messageUsers.filter((el) => el.userType === 1)
            }
        ]
    }, [messageUsers]);

    const handleAttachmentUpload = (files, info) => toast.promise(uploadMessageAttachment(files, null, info), {
        loading: "Uploading attachment...",
        error: "Unable to upload attachment",
        success: "Attachment uploaded."
    });

    useEffect(() => {
        if (!(isEditingMessage === false && shouldSend === true)) return;

        sendMessage();
    }, [isEditingMessage, shouldSend]);

    // if the thread no longer has any messages in it following a delete, we should redirect the user back to the main threads page
    useEffect(() => {
        const { isSuccess } = deleteDraftResult;

        if (isSuccess && typeof (onDeleteCallbackFn) === "function") {
            onDeleteCallbackFn();
        }

    }, [deleteDraftResult]);

    // For preventing navigation within the Application
    const shouldBlock = useCallback(({ currentLocation, nextLocation, historyAction } = {}) => {
        // Ignore if using back button:
        // We can't block it here anyway, and trying to causes the beforeunload event to fire, which *will* block for us
        // But also show the browser's confirmation dialog, so the user will be asked if they want to leave twice (which is wrong)
        if (historyAction === 'POP')
            return false;

        // Duck out early if we're simply changing the view type and the overall path is unchanged
        if (currentLocation && nextLocation && currentLocation.pathname === nextLocation.pathname)
            return false;

        // Otherwise, return true if we have unsent message data (any of the fields are not empty)
        const messageDetails = [recipients, attachments, currentMessage];

        if (canEditSubject)
            messageDetails.push(threadSubject);

        return _.some(messageDetails, (x) => !_.isEmpty(x));
    }, [attachments, canEditSubject, currentMessage, recipients, threadSubject]);

    const blocker = useBlocker(shouldBlock);

    // For preventing navigation to other sites/closing the browser tab
    const onBeforeUnload = useCallback((e) => {
        if (shouldBlock())
            e.preventDefault();
    }, [shouldBlock])

    useBeforeUnload(onBeforeUnload);

    return <React.Fragment>
        <ConfirmModal
            size="md"
            theme={theme}
            title="Unsent Message"
            confirmButtonLabel="Leave"
            cancelButtonLabel="Continue Message"
            confirmButtonVariant="danger"
            handleConfirm={() => blocker.proceed()}
            handleClose={() => blocker.reset()}
            show={blocker.state === 'blocked'}
            message={<>You have entered some message details but not sent the message.<br />Are you sure you want to leave this page?</>}
        />
        <MessageBox ref={ref} className="rounded">
            <div className="row mb-4">
                <MessageHeader className="row d-flex">
                    <div className="col d-flex flex-row align-items-center">
                        <div className="col-auto">
                            <ThemedSpan variant="" className={"me-3"}>
                                to:
                            </ThemedSpan>
                        </div>

                        <div className="col">
                            <FormSelect
                                isMulti
                                onBlur={(values) => updateMessageRecipients(values)}
                                defaultValue={recipients.map(({ userId }) => userId)}
                                options={recipientOptions}
                                isLoadingOptions={!recipientOptions}
                                menuPosition="relative"
                                menuShouldScrollIntoView={false}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        {canEditSubject && <FormInput
                            placeholder="Subject"
                            value={threadSubject}
                            onBlur={(value) => updateMessageThread(pathThreadId ?? threadId, "subject", value)}
                        />}
                    </div>
                    <div className="col-auto p-0">
                        {
                            <IconButton
                                variant="danger"
                                icon={"fa-trash"}
                                onClick={deleteDraft}
                            />
                        }
                    </div>
                    <div className="col-auto p-0">
                        <AttachmentsToggle isCreatable menuDirection="start" attachments={attachments} onUploadFn={() => setShowFileUpload(true)} />
                    </div>
                    <div className="col-auto p-0">
                        <IconButton
                            onClick={() => setShowModal(true)}
                            icon={"fa-paper-plane"}
                            disabled={recipients.length === 0 || currentMessage === ""}
                        />
                    </div>
                </MessageHeader>

            </div>
            <div className="row">
                <FormTextArea
                    placeholder="New message..."
                    minRows={3}
                    value={messageText}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    onBlur={(value) => updateMessage("message", value)}
                />
            </div>
        </MessageBox>
        <SendMessageConfirmationModal
            deadlineDate={deadlineDate}
            priority={messagePriority}
            projectId={projectId}
            requiresAction={isActionRequired}
            show={showModal}
            onClose={() => setShowModal(false)}
            onSend={() => setShouldSend(true)}
            updateMessage={updateMessage}
            updateThread={(property, newValue) => updateMessageThread(pathThreadId ?? threadId, property, newValue)}
            hasClientRecipient={recipients.some(x => x.recipientType === RecipientType.Client)}
        />
        <FileUploadModal
            show={showFileUpload}
            handleClose={() => setShowFileUpload(false)}
            title="Upload Attachments"
            handleConfirm={handleAttachmentUpload}
            isUploadingFile={isUploadingAttachment}
        />
    </React.Fragment>;

});

export default DraftMessage;