import React, { useState } from "react";
import styled from "styled-components";
import { useFilterContext } from "../../hooks/FilterContext";
import { HeadOfficeFeeManagementModal, FeeManagementFiltersModalButton } from "./components/HeadOfficeFeeManagementFilters";
import { AgGridMain, FeeManagementPageSubHeader, Main, ThemedIcon, ThemedParagraph } from "../../components/utilities";
import PageHeader, { PageHeaderTop, PageHeaderBottom } from "../../components/layout/PageHeader";
import FeeGroupingTable from "./components/FeeGroupingTable";
import CreateFeeGroupModal from "./components/CreateFeeGroupingModal";
import { Button } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchFeesProvider from "./hooks/useSearchFees";
import { useAdministrationFeesProvider } from "../../hooks/AdministrationFeesContext"

const FeeManagementFiltersAppliedWarningMessage = styled(ThemedParagraph)`
    font-size: 12px !important;
`;

const HeadOfficeFeeManagement = () => {
    const [isFiltersShown, setIsFiltersShown] = useState(false);
    const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
    const { clearFilter, isFiltersApplied, filter } = useFilterContext();
    const {currentSearchType, setFilters} = useAdministrationFeesProvider();

    React.useEffect(() => {
        setFilters(_ => filter)
    },[filter]);

    return <React.Fragment>
        <HeadOfficeFeeManagementModal
            handleClose={() => setIsFiltersShown(false)}
            currentSearchType = {currentSearchType}
            show={isFiltersShown}
        />
        <CreateFeeGroupModal
            show={showCreateGroupModal}
            handleClose={() => setShowCreateGroupModal(false)}
        />
        <Main className="d-flex flex-column h-100">
            <FeeManagementPageSubHeader>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        {isFiltersApplied && (
                            <React.Fragment>
                                <ThemedIcon icon="fa-triangle-exclamation" size="2x" variant="warning" />
                                <FeeManagementFiltersAppliedWarningMessage className="ms-2 text-muted">
                                    Warning - Filters are currently being applied to your results.
                                    <br />
                                    <a href="#" onClick={clearFilter}>Click here</a> to clear your filters.
                                </FeeManagementFiltersAppliedWarningMessage>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="d-flex">
                        <Button
                            variant="success"
                            className="me-3"
                            onClick={() => setShowCreateGroupModal(true)}
                        >
                            <FontAwesomeIcon className="me-1" icon="plus" />Create New Fee
                        </Button>
                        <Button onClick={() => setIsFiltersShown(true)}>Filter Fees</Button>
                    </div>
                </div>
            </FeeManagementPageSubHeader>
            <section className="px-4 my-3 h-100">
                <SearchFeesProvider searchType={"fees"}>
                    <FeeGroupingTable />
                </SearchFeesProvider>
            </section>
        </Main>
    </React.Fragment>
};

export default HeadOfficeFeeManagement;