import React, { useCallback, useMemo, useState } from "react";
import { useSearchFees } from "../hooks/useSearchFees";
import FeeGroupingTableRow from "./FeeGroupingTableRow";
import { ErrorMessage, ErrorMessageWrapper } from "../../../components/messages";
import { VirtualTable } from "../../../components/tables";
import FeeGroupingTableHeader from "./FeeGroupingTableHeader";
import FeeGroupingBreakdownModal from "./FeeGroupingBreakdownModal";

const FeeGroupingTable = () => {

    const { results, totalCount, loadMoreItems, isItemLoaded, error, isClearing, isError, retrySearchFees, updateSort, sort } = useSearchFees("fees");
    const [chosenGrouping, setChosenGrouping] = useState(null);
    const chosenGroupingData = useMemo(() => results?.find(x => x.groupId === chosenGrouping), [results, chosenGrouping]);
    const columns = useMemo(() => [
        { label: 'Fee Type' },
        { label: 'Provider' },
        { label: 'Client' },
        { label: 'Date' },
        { label: 'Fee (£)' },
        { label: 'VAT (£)' },
        { label: 'Adviser' },
        { label: 'Description' },
        { label: "" }
    ], [])

    //console.log('feeGroupingData', chosenGroupingData);

    if (isError) {
        return <ErrorMessageWrapper>
            <ErrorMessage error={error} retryCallback={retrySearchFees} />
        </ErrorMessageWrapper>;
    }

    return (
        <React.Fragment>
            <VirtualTable
                itemCount={totalCount === results.length ? totalCount :  results.length + 25}
                itemSize={42}
                itemData={{
                    isClearing,
                    results,
                    setChosenGrouping
                }}
                row={FeeGroupingTableRow}
                header={<FeeGroupingTableHeader sort={sort} setSort={updateSort} />}
                isItemLoaded={isItemLoaded}
                loadMoreItems={loadMoreItems}
                columns={[
                    { label: 'Fee Type' },
                    { label: 'Provider' },
                    { label: 'Client' },
                    { label: 'Date' },
                    { label: 'Fee (£)' },
                    { label: 'VAT (£)' },
                    { label: 'Adviser' },
                    { label: 'Description' },
                    { label: "" }
                ]}
            />
            <FeeGroupingBreakdownModal
                show={chosenGrouping !== null}
                onHide={() => setChosenGrouping(null)}
                groupId={chosenGrouping}
                data={chosenGroupingData}
            />
        </React.Fragment>
    );
};

export default FeeGroupingTable;