import { DateDisplay } from "../../../components";
import { DateInput, FormInput, FormLabel, FormTextArea } from "../../../components/forms";
import { CenterFormColumnComponent } from "../../../components/utilities";
import DesignationSelect from "./DesignationSelect";
import { HeadOfficeApplicationStatusSelect, HeadOfficeWrappersSelect } from "../../../components/forms/selects";
import useApplicationPolicy from "../hooks/useApplicationPolicy";

const ApplicationPolicySummary = ({ application = {}, disableProperties, patchApplication }) => {

    const {
        applicationId,
        applicationPolicyId,
        status,
        applicationStatusDate,
        comment,
        statusUpdatedBy,
        statusUpdatedDate,
        productDescription,
        designationId,
        masterAccountId,
        wrapperId,
        wrapperName,
        notes
    } = application;

    const { patchApplicationPolicy } = useApplicationPolicy(applicationPolicyId, applicationId);

    const onApplicationFieldChanged = (property, newValue) => patchApplication(property, newValue);

    const onPolicyFieldChanged = (property, newValue) => patchApplicationPolicy(property, newValue);

    return (
        <div class="row mb-2">
            <div class="col">
                <div class="row mb-4">
                    <div className="col-3">
                        <HeadOfficeApplicationStatusSelect
                            label="Status"
                            isMulti={false}
                            defaultValue={status}
                            onBlur={(value) =>
                                onApplicationFieldChanged("status", value.value)
                            }
                        />
                    </div>
                    <div className="col-2">
                        <DateInput
                            label="Status Date"
                            value={applicationStatusDate}
                            onBlur={(value) =>
                                onApplicationFieldChanged(
                                    "applicationStatusDate",
                                    value
                                )
                            }
                            isClearable
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            id="comment"
                            label="Comment"
                            value={comment}
                            placeholder="Comment"
                            onBlur={(value) =>
                                onApplicationFieldChanged("comment", value)
                            }
                        />
                    </div>
                    {statusUpdatedBy != null &&
                        statusUpdatedDate != null && (
                            <CenterFormColumnComponent className="col-md-2">
                                <FormLabel>
                                    {statusUpdatedBy}
                                    <DateDisplay
                                        className="ps-1"
                                        format="DD/MM/YYYY h:mm a"
                                    >
                                        {statusUpdatedDate}
                                    </DateDisplay>
                                </FormLabel>
                            </CenterFormColumnComponent>
                        )}
                </div>
                <div class="row mb-4">
                    <div className="col">
                        <HeadOfficeWrappersSelect
                            label="Wrapper"
                            defaultValue={wrapperId}
                            isDisabled={disableProperties}
                            onBlur={({ value }) => onPolicyFieldChanged("wrapperId", value)}
                        />
                    </div>
                    <div className="col">
                        <FormInput
                            label="Product"
                            value={productDescription}
                            isDisabled={disableProperties}
                            onBlur={(value) => onPolicyFieldChanged("productDescription", value)}
                        />
                    </div>
                    <div className="col">
                        <DesignationSelect
                            label="Designation"
                            isDisabled={disableProperties}
                            masterAccountId={masterAccountId}
                            defaultValue={designationId}
                            onBlur={(value) =>
                                onApplicationFieldChanged("designationId", value.value)
                            }
                        />
                    </div>
                </div>
                <div class="row mb-4">
                    <div className="col">
                        <FormTextArea
                            value={notes}
                            minRows={3}
                            maxRows={3}
                            onBlur={(value) =>
                                onApplicationFieldChanged("notes", value)
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationPolicySummary;